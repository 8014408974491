import React, { useState, useEffect, useCallback } from "react";
//import MultiDropdown from "../../components/multi-dropdown/MultiDropdown";
import Tiles from "../../components/Tiles/Tiles2";
import ImageService from "../../services/image.service";
import ListService from "../../services/list.service";
import ProductService from "../../services/product.service";
import {
  Link,
  useNavigate,
  //createSearchParams,
  //useSearchParams,
} from "react-router-dom";
import Searchbar from "../../components/searchbar/searchbar";
import {
  useAuth,
  isTokenExpired,
  EnsureToken,
} from "../../services/auth/use-auth";
import UserService from "../../services/user.service";
import { determineAgenticTasks } from "../../utils/utilsTextFuncs";
import filterProfanity from "../../services/text.service";
import useGoogleOneTap from "../../hooks/useGoogleOneTap";
import { GoogleAuthProvider } from "firebase/auth";
import "./Home.css";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  Select,
  Stack,
  useMediaQuery,
  CardMedia,
  Card,
  CardContent,
} from "@mui/material";

import {
  browserName,
  browserVersion,
  osName,
  osVersion,
  deviceType,
  mobileVendor,
  mobileModel,
  getUA,
  isDesktop,
  isMobile,
  isTablet,
} from "react-device-detect";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { favorites } from "@fortawesome/free-solid-svg-icons";
import { FiEdit } from "react-icons/fi";
import { Check } from "@mui/icons-material";
import { LogSomething, TraceSomething } from "../../utils/utilsStringFuncs";
//import { useSnackbar } from 'notistack';
import axios from "axios";

export default function Home() {
  const auth = useAuth();
  //const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [featuredText, setFeaturedText] = useState([]);
  const [page, setPage] = useState(0);
  //const [filter, setFilter] = useState("recent");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const navigate = useNavigate();
  //const [searchParams, setSearchParams] = useSearchParams();
  //const [searchTerm, setSearchTerm] = useState(searchParams.get("q") || "");
  const [authLoading, setAuthLoading] = useState(false);
  const [trendingSearches, setTrendingSearches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [favorites, setFavorites] = useState([]); // [image_id, ...
  const [products, setProducts] = useState([]);
  const [showMore, setShowMore] = useState(true);
  const [showQuickActions, setShowQuickActions] = useState(true);
  const [featureCategory, setFeatureCategory] = useState("Portraits");
  const [subscription, setSubscription] = useState(null);
  const [credits, setCredits] = useState(null);
  //const { enqueueSnackbar } = useSnackbar();

  const DEFAULT_PAGE_SIZE = 24;
  const MAX_NUM_PAGED = isMobile ? 3 : 5;
  const MAX_NUM_FEATURED = 5;
  const MAX_NUM_FEATURED_TEXT = 5;

  const [props, setProps] = useState({
    noPerRow: 4,
    gap: 10,
    showQuickActions: showQuickActions,
  });
  /*
  const [props2, setProps2] = useState({
    noPerRow: 4,
    gap: 0,
  });
  */

  let isRefreshing = false;
  let failedQueue = [];


  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    failedQueue = [];
  };

  const setupInterceptors = () => {
    axios.interceptors.request.use(
      async (config) => {
        const user = auth.user.auth.currentUser;
        if (user) {
          const token = await user.getIdToken();

          TraceSomething(
            "axios.interceptors.request.use() - refresh token: " + token
          );

          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
          if (isRefreshing) {
            return new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            }).then((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return axios(originalRequest);
            });
          }

          isRefreshing = true;
          originalRequest._retry = true;

          try {
            const user = auth.user.auth.currentUser;
            const newToken = await user.getIdToken(true);

            TraceSomething(
              "axios.interceptors.response.use - user: " + JSON.stringify(user)
            );
            TraceSomething(
              "axios.interceptors.response.use - new token: " + newToken
            );

            processQueue(null, newToken);
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            return axios(originalRequest);
          } catch (refreshError) {
            processQueue(refreshError, null);
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        }
        return Promise.reject(error);
      }
    );
  };

  // Add to Home.jsx
  /*
  useEffect(() => {
    setupInterceptors();
  }, []);
*/

  useEffect(() => {
    setProps({ ...props, showQuickActions: showQuickActions });
  }, [props, showQuickActions]);

  /* USE EFFECT =============== GET SUBSCRIPTION/CREDITS INFO & USER INFO  */
  useEffect(() => {

    const getSubscription = async () => {
      if (auth.user) {
        //setLoading(true);
        const response = await UserService.GetUserCredits(
          auth.user.uid,
          await EnsureToken(auth.user.auth.currentUser)
        );
        // console.log(
        //   "Logged in, subscription info for auth.user.id:",
        //   auth.user.uid,
        //   "\n",
        //   response
        // );
        console.log(response);
        setCredits(response.creditProfile);
        // console.log("CreditProfile: ", JSON.stringify(response.creditProfile));
      } else {
        var credit = {
          creditProfile: {
            numSubscriptionCreditsRemaining: -1,
            numWalletCreditsRemaining: -1,
            numPendingCredits: -1,
          },
        };
        setSubscription(credit);
      }

      //(false);
    };

    getSubscription();

  }, [auth.user]);

  /* USE EFFECT =============== GET FEATURED  */
  useEffect(() => {

    let mounted = true;

    if (mounted) {
      const getFeatured = async () => {
        if (auth.user) {
          
          //setLoading(true);
          try {
            ImageService.GetRandomImagesByCategory(
              auth.user.uid,
              featureCategory,
              0,
              10,
              10,
              await EnsureToken(auth.user.auth.currentUser)
            ).then((response) => {
              //console.log("useEffect: GetFavorites call: ", response);
              // get 5 random images from the response
              let randomized = [];
              let randomizedText = [];
              for (let i = 0; i < 5; i++) {
                //let index = Math.floor(Math.random() * response.length);
                if (response[i] === undefined) break;
                if (response[i].category === undefined) break;
                randomizedText.push(
                  determineAgenticTasks(response[i].category)
                );
                randomized.push(response[i]);
              }

              //TraceSomething("useEffect() - FEATURES: " + JSON.stringify(randomized));
              setFeaturedText(randomizedText);
              setFeatured(randomized);
            });

          } catch (error) {
            LogSomething("useEffect() - FEATURES - ERROR - : ", error);
          }
        } else {
          LogSomething("useEffect() - FEATURES - no auth.user");
        }

        //setLoading(false);
      };

      getFeatured();
    }

    return () => {
      mounted = false;
    };
  }, [auth.user, featureCategory]);

  /* USE EFFECT =============== GET PRODUCTS & CATEGORIES INFO  */
  useEffect(() => {
    let mounted = true;
  
    /*
    TraceSomething(
      "useEffect() - PRODUCTS & CATEGORIES",
      "auth.user: ",
      JSON.stringify(auth.user)
    );
    */

    if (mounted) {
      const getProductsAndCategories = async () => {
        if (auth.user) {
          //setLoading(true);
          //console.log("getFeatured: input category(",JSON.stringify(inputCategory),") feature category(",JSON.stringify(featureCategory),")");
          try {
            ProductService.GetProductList(
              auth.user.uid,
              await EnsureToken(auth.user.auth.currentUser)
            ).then((response) => {
              TraceSomething(
                "useEffect() - PRODUCTS: " + JSON.stringify(response)
              );
              setProducts(response);
            });

            let category = "Portraits";
            //console.log("useEffect: randomCategory: ", randomCategory);
            ListService.GetCategories().then((response) => {
              // console.log("fetched categories: ", response);
              setCategories(["All", "Uploads", ...response.sort()]);

              // chose random category
              category = response[Math.floor(Math.random() * response.length)];
              TraceSomething(
                "useEffect() - CATEGORIES: " + JSON.stringify(response)
              );

              setFeatureCategory(category);
            });
          } catch (error) {
            LogSomething(
              "useEffect() - PRODUCTS & CATEGORIES - ERROR - : ",
              error
            );
          }
        } else {
          LogSomething("useEffect() - PRODUCTS & CATEGORIES - no auth.user");
        }

        //setLoading(false);
      };

      getProductsAndCategories();

    }

    return () => {
      mounted = false;
    };
  }, [auth.user]);

  /* USE EFFECT ================================= GET IMAGES  */
  useEffect(() => {
    let mounted = true;

    /*
    LogSomething(
      "useEffect() - IMAGES - auth.user: " + JSON.stringify(auth.user)
    );
    */

    if (mounted) {
      const loadMore = async (startIndex, stopIndex, currentItems) => {
        setLoading(true);
        setLoadingMore(true);
        TraceSomething("loading next page: " + page);
        LogSomething("retrieved ALL images: " + selectedCategory);
            
        if (selectedCategory === "All") {
          try {
           const response = await ImageService.GetImages(
              auth.user.uid,
              page,
              DEFAULT_PAGE_SIZE,
              await EnsureToken(auth.user.auth.currentUser)
            );
/*
            if (!response.ok) { // Check for HTTP errors (e.g., 400, 500)
              const errorText = await response.text(); // Get error message from server
              throw new Error(`API Error: ${response.status} - ${errorText}`);
            }
            LogSomething(response.length + " retrieved images");
            */
            LogSomething("retrieved images: " + response);
            
            //setPage((prevPage) => prevPage + 1);
            setData((prevData) => [...prevData, ...response]);
          } catch (error) {
            console.log("error loading more images: " + error);
          }
        } else if (selectedCategory === "Uploads") {
            ImageService.GetImageUploadsByUser(
              auth.user.uid,
              page,
              DEFAULT_PAGE_SIZE,
              await EnsureToken(auth.user.auth.currentUser)
            ).then((response) => {
              // console.log("filterData() - UPLOADS - DATA:", response);
              if (response.length === 0) {
                setShowMore(false);
              }
              setData([...response]);
              setLoading(false);
            });
          } else {
            //setSearchParams(createSearchParams({ q: selectedCategory }));
            // console.log("filterData() - CATEGORY:", value);
            ImageService.GetImagesByCategory(
              auth.user.uid,
              selectedCategory,
              page,
              DEFAULT_PAGE_SIZE,
              await EnsureToken(auth.user.auth.currentUser)
            ).then((response) => {
              // console.log("filterData() - CATEGORY:", value, "DATA:", response);
              if (page !== 0) {
                // in a load more scenario
                setData((currentData) => [...currentData, ...response]);
              } else {
                // in a new filter scenario
                setData([...response]);
              }
              if (response.length === 0) {
                setShowMore(false);
              }
              setLoading(false);
            });
          }
        setLoading(false);
        setLoadingMore(false);      
      } // end loadmore

      loadMore();

    }
    return () => {
      mounted = false;
    };
  }, [auth.user, selectedCategory, page]);





  /*
  const handleView = useCallback((item) => {
    navigate(`/image?id=${item.id}`);
  }, []);

  const handleDownload = useCallback(async (item) => {
    try {
      await ImageService.downloadImage(item.id);
      //enqueueSnackbar('Image downloaded successfully', { variant: 'success' });
    } catch (error) {
      //enqueueSnackbar('Failed to download image', { variant: 'error' });
    }
  }, []);

  const handleFavorite = useCallback(async (item) => {
    try {
      await ImageService.toggleFavorite(item.id);
      //enqueueSnackbar('Added to favorites', { variant: 'success' });
    } catch (error) {
      //enqueueSnackbar('Failed to add favorite', { variant: 'error' });
    }
  }, []);

  const handleShare = useCallback((item) => {
    // Share implementation
  }, []);
*/
  const handleGoogleOneTapResponse = async (response) => {
    setAuthLoading(true);
    try {
      // // console.log(
      //   "SUCCESS: Signin with Google One Tap Success: ",
      //   response.credential
      // );
      // console.log("loading: ", loading);
      const credential = response.credential;
      // console.log("google one tap response: ", response);
      const googleAuthCredential = GoogleAuthProvider.credential(credential);
      // console.log("googleAuthCredential: ", googleAuthCredential);
      var result = await auth.signInWithCredentialFlow(googleAuthCredential);
      // console.log("result: ", result);
      var userDetails = auth.getAdditionalUserInfoFlow(result);
      // console.log("userDetails: ", userDetails);
      var currentUser = result.user;
      // console.log("Successfully created account using Google");
      const isNewUser = userDetails.isNewUser;
      if (isNewUser) {
        // console.log("Creating New user");
        const newUser = {
          userId: currentUser.uid,
          email: currentUser.email,
          password: null,
          displayName: currentUser.displayName,
          photoURL: currentUser.photoURL,
        };
        var createNewUserRes = await UserService.CreateNewUserAccount(newUser);
        // console.log(createNewUserRes);
        if (createNewUserRes?.userId) {
          navigate("/");
        } else {
          alert(
            "Failed to create new user - please contact support team or try another method."
          );
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      // console.log("ERROR: Signin with Google One Tap Error: ", error);
    }
    setAuthLoading(false);
    // console.log("loading: ", loading);
  };

  const handleGoogleButtonClick = (isShowing) => {
    if (isShowing) {
      setAuthLoading(true);
      // console.log("Google OneTap Button Clicked");
      // console.log("event: ", isShowing);
    } else {
      setAuthLoading(false);
    }
  };

  useGoogleOneTap({
    // onError: (error) => // console.log("Google OneTap Error: ", error),
    // onComplete: (response) => // console.log("Google OneTap Success: ", response),
    onDisplay: (isShowing) => handleGoogleButtonClick(isShowing),
    showPrompt: true,
    disabled: auth.user ? true : false,
    disableCancelOnUnmount: false,
    googleConfig: {
      auto_select: auth.user ? true : false,
      cancel_on_tap_outside: false,
      callback: handleGoogleOneTapResponse,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    },
  });

  const download = async (url) => {
    try {
      var urlDownload = url ? url : data.public_url_reduced;
      console.log("downloadImage: url(" + url + ")");
      console.log("downloadImage: urlDownload(" + urlDownload + ")");
      const blob = await fetch(urlDownload).then((r) => r.blob());
      const urlBlob = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = urlBlob;
      link.target = "_blank";
      link.download =
        "SmartAI_Studio_" +
        urlDownload.substr(urlDownload.lastIndexOf("/") + 1);
      link.click();
    } catch (error) {
      console.log("downloadImage: error(" + error + ")");
    }
  };

  const getSubscriptionCreditsRemaining = () => {
    if (credits) {
      return credits.numSubscriptionCreditsRemaining;
    }
    return 0;
  };

  const getWalletCreditsRemaining = () => {
    if (credits) {
      return credits.numWalletCreditsRemaining;
    }
    return 0;
  };

  const getTotalCreditsAvailable = () => {
    return getSubscriptionCreditsRemaining() + getWalletCreditsRemaining();
  };

  const handleQuickDownload = async (imageId, featuredText) => {
    TraceSomething(
      "handleQuickDownload() - entered",
      imageId,
      " user: ",
      JSON.stringify(auth.user)
    );
    // get the product
    const selectedProduct = products.find((product) => product.productId === 1);
    setLoading(true);

    //if (auth.user && acceptTerms && auth.user.id && identifier !== "") {
    if (
      auth.user &&
      imageId !== "" &&
      selectedProduct &&
      selectedProduct.productId > 0
    ) {
      TraceSomething(
        "handleQuickDownload() - Passed preconditions",
        imageId,
        ", product #",
        selectedProduct.productId,
        " user: ",
        JSON.stringify(auth.user)
      );
      var userId = auth.user.uid;
      var token = await EnsureToken(auth.user.auth.currentUser);
      const response = await ProductService.PurchaseImageUsingCredits(
        userId,
        token,
        selectedProduct.productId,
        imageId
      );

      if (response.success === false) {
        alert("Download was not successful: " + response.message);
        // console.log("purchaseImage: " + response);
      } else if (response.success === true) {
        // console.log("downloadImage: " + response.product.downloadUrl);
        // console.log("downloadImage: " + response.product.assetUrl);
        if (!browserName.includes("Safari")) {
          download(response.product.downloadUrl);
        }

        setLoading(false);
        if (featuredText !== undefined && featuredText !== null) {
          navigate("/studio?id=" + imageId + "&task=" + featuredText);
        } else navigate("/studio?id=" + imageId);
      }
    } else {
      console.log(
        "purchaseImage() - Failed check: id #",
        imageId,
        ", product #",
        selectedProduct
      );
    }

    setLoading(false);
  };

  const handleCategoryChange = (e) => {
    if (e.target.value !== selectedCategory) {
      TraceSomething(
        "handleCategoryChange newCategory(" +
          e.target.value +
          ") selectedCategory(" +
          selectedCategory +
          ")"
      );
      setPage(0);
      setData([]);
      setFeatured([]);
      setFeaturedText([]);
      setFeatureCategory(e.target.value);
      //getFeatured();
    }
    setShowMore(true);
    setSelectedCategory(e.target.value);
  };

  /*
  const filterData = async (value, pageParam = 0) => {

    setLoading(true);
    setSelectedCategory(value);
    setPage(pageParam);
  };
*/
  /*
  const filterData = async (value, pageParam = 0) => {
    // console.log("filterData() - entered: ", value);
    setLoading(true);

    if (value === "All") {
      ImageService.GetCleanImages(
        auth.user.uid,
        pageParam,
        DEFAULT_PAGE_SIZE,
         await EnsureToken(auth.user.auth.currentUser)
      )
        .then((response) => {
          // console.log("filterData() - ALL - DATA:", response);
          if (response.length === 0) {
            setShowMore(false);
          } else {
            setData([...response]);
            setShowMore(true);
          }
          if (searchParams.has("q")) {
            searchParams.delete("q");
            setSearchParams(searchParams);
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("error: ", error);
          setLoading(false);
        });
    } else if (value === "Uploads") {
      ImageService.GetImageUploadsByUser(
        auth.user.uid,
        pageParam,
        DEFAULT_PAGE_SIZE,
         await EnsureToken(auth.user.auth.currentUser)
      ).then((response) => {
        // console.log("filterData() - UPLOADS - DATA:", response);
        if (response.length === 0) {
          setShowMore(false);
        }
        setData([...response]);
        setLoading(false);
      });
    } else {
      setSearchParams(createSearchParams({ q: value }));
      // console.log("filterData() - CATEGORY:", value);
      ImageService.GetImagesByCategory(
        auth.user.uid,
        value,
        pageParam,
        DEFAULT_PAGE_SIZE,
         await EnsureToken(auth.user.auth.currentUser)
      ).then((response) => {
        // console.log("filterData() - CATEGORY:", value, "DATA:", response);
        if (pageParam !== 0) {
          // in a load more scenario
          setData((currentData) => [...currentData, ...response]);
        } else {
          // in a new filter scenario
          setData([...response]);
        }
        if (response.length === 0) {
          setShowMore(false);
        }
        setLoading(false);
      });
    }
  };
*/

  /*
  const getFavoriteForUser = async () => {
    let favorites = [];
    if (auth.user) {
      //console.log("useEffect: auth.uid: ", JSON.stringify(auth.user.uid));
      ImageService.getFavoriteImagesForUser(
        auth.user.uid,
         await EnsureToken(auth.user.auth.currentUser)
      ).then((response) => {
        //console.log("useEffect: GetFavorites call: ", response);
        setFavorites(response);
        favorites = response;
        // console.log("Tiles Favorites: ", favorites);
      });

      // set favorite flag for each image
      //let currentData = data;
      for (let i = 0; i < data.length; i++) {
        data[i].is_favorited = favorites.some((f) => {
          return f.image_id === data[i].image_id;
        });

        //if (data[i].is_favorited)
        // console.log("Tiles: IS favorited: ", data[i].is_favorited);
        //else
        //console.log("Tiles: NOT favorited: ", data[i].is_favorited);
      }
    }
    return favorites;
  };
*/

  /*
  const getProductList = async () => {
    if (!auth.user) { return; }
    
    ProductService.GetProductList(auth.user.uid,  await EnsureToken(auth.user.auth.currentUser)).then((response) => {
      setProducts(response);
    });
  };
*/
  /*
// Replace/update the problematic useEffect
useEffect(() => {
  let mounted = true;

  const fetchData = async () => {
      if (!auth.user) return;
      
      try {
          setLoading(true);
          
          // Fetch data in parallel
          const [featuredResponse, productResponse] = await Promise.all([
              ListService.GetFeatured(),
              ProductService.GetProductList(
                auth.user.uid, 
                 await EnsureToken(auth.user.auth.currentUser))
          ]);

          if (mounted) {
              setFeatured(featuredResponse);
              setProducts(productResponse);
              // Set any other dependent states here
          }
      } catch (error) {
          console.error("Error fetching data:", error);
      } finally {
          if (mounted) {
              setLoading(false);
          }
      }
  };

  fetchData();

  // Cleanup function
  return () => {
      mounted = false;
  };
}, [auth.user, featureCategory]); // Only depend on auth.user
*/

  /*
  useEffect(() => {
    //let mounted = true;

    //if (mounted) {

      if (!auth.user) { return; }
      setLoading(true);
      if (searchParams.has("q")) {
        setSelectedCategory(searchParams.get("q"));
      } else {
        setSelectedCategory("All");
      }
      ListService.GetCategories().then((response) => {
        // console.log("fetched categories: ", response);
        setCategories(["All", "Uploads", ...response.sort()]);
      });

      const query = searchParams.get("q");
      // console.log("query", query);

      if (query) {
        ImageService.GetImagesByCategory(auth.user.uid, query, page, DEFAULT_PAGE_SIZE,  await EnsureToken(auth.user.auth.currentUser)).then((response) => {
          setData([...response]);
          setLoading(false);
        });

        getFeatured(query);

        // document.getElementById("filter1").value = query;
      } else {
        ImageService.GetCleanImages(auth.user.uid,page, DEFAULT_PAGE_SIZE,  EnsureToken(auth.user.auth.currentUser)).then((response) => {
          setData([...response]);
          setLoading(false);
        });

        getFeatured();
      }

      getProductList();
      // get favorites for user
      setFavorites(getFavoriteForUser());
      // trendingSearchesList();
    //}
   // return () => {
    //  mounted = false;
   // };
  }, [searchParams, auth.user]);
*/
  /*
  const handleFilterChange = (e) => {
    // console.log("handleFilterChange() - entered");
    // console.log("handleFilterChange() - e.target.value:", e.target.value);
    //setFilter(e.target.value);
    filterData(e.target.value);
  };
  */
  function getFriendlyName(name) {
    //console.log("getFriendlyName() - name: ", name);
    let clean = "";

    if (name === "SearchByCategory") {
      clean = "Category";
    } else if (name === "SearchBySearchTerm") {
      clean = "Search";
    } else if (name === "SearchByGenericTag") {
      clean = "Tag";
    } else if (name === "SearchByTopic") {
      clean = "Topic";
    } else if (name === "SearchByColor") {
      clean = "Color";
    }

    //console.log("getFriendlyName() - -name: ", name, "clean: ", clean);
    return clean;
  }

  /*
  const renderTrendingSearches = () => {
    // now render results
    return (
      <Box className="search__result" sx={{ marginTop: "50px" }}>
        <Typography
          sx={{
            margin: 1,
            fontFamily: "Raleway",
            fontSize: 22,
            fontWeight: "bold",
            marginTop: "10px",
          }}
        >
          Trending Searches
        </Typography>
        <div className="searchbar search__result__container">
          {trendingSearches.map((item, index) => (
            <Button
              key={index}
              label={getFriendlyName(item.interaction)}
              title={
                getFriendlyName(item.interaction) +
                " - " +
                filterProfanity(item.meta1)
              }
              style={{
                // make button look like cool chip using primary color
                backgroundColor: "#e0e0e0",

                borderRadius: "20px",
                color: "#000000",
                padding: "5px 10px",
                margin: "5px",
                fontSize: "14px",
                fontWeight: "bold",
                textTransform: "none",
              }}
            >
              <Link
                className="text-decoration-none"
                to={`/search?q=${item.meta1}`}
                state={{ category: item.meta1 }}
              >
                <Typography
                  // center content and apply padding
                  sx={{
                    color: "var(--primary-color)",
                    margin: 1,
                    padding: "2px",
                    fontFamily: "Raleway",
                    fontSize: 14,
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  <i
                    style={{
                      marginRight: "15px",
                      fontSize: "16px",
                      color: "var(--secondary-color)",
                    }}
                    className="fa-solid fa-magnifying-glass"
                  ></i>
                  {getFriendlyName(item.interaction) +
                    " - " +
                    filterProfanity(item.meta1)}
                </Typography>
              </Link>
            </Button>
          ))}
        </div>
      </Box>
    );
  };
*/
  const ShowCategoriesCombo = () => {
    return (
      <FormControl
        id="categoryInput"
        variant="outlined"
        sx={{
          minWidth: 280,
          margin: "auto",
          marginBottom: "10px",
        }}
      >
        <InputLabel>Categories</InputLabel>
        <Select
          id="categorySelect"
          value={categories.length > 0 ? selectedCategory : ""}
          data={categories}
          label="Categories"
          renderValue={(value) => value}
          onChange={(e) => handleCategoryChange(e)}
        >
          {categories.map((category) => (
            <MenuItem
              key={category}
              value={category}
              selected={category === selectedCategory}
            >
              {selectedCategory === category ? (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              ) : null}
              <ListItemText inset={selectedCategory !== category}>
                {category}
              </ListItemText>
            </MenuItem>
          ))}
        </Select>
        <FormHelperText
          sx={{
            fontFamily: "Raleway",
            fontSize: 12,
            fontStyle: "italic",
            fontWeight: "300",
            color: "#000000",
            padding: "5px",
          }}
        >
          Select a category to browse images
        </FormHelperText>
      </FormControl>
    );
  };

  /*
  const renderFeatureCard = (item, index) => {
    return (
      <div
              key={`related__image__${index}`}
              style={{ 
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                borderRadius: "10px",
                border: "1px solid #8d8d8d",                
              }}
            >
               <Link
                  to={`/image?id=${item.image_id}`}
                  className="text-decoration-none"
                >
              <img
                style={{
                  width: "90%",
                  borderRadius: "10px",
                  border: "1px solid #8d8d8d",
                  objectFit: "fill",
                  padding: "5px",
                  marginTop: "5px",
                  maxWidth: `${(data.width / 4)}px`,      
                }}
                src={item.public_url_thumbnail}
                alt={item.title}
              />
              </Link>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  borderRadius: "10px",
                  border: "1px solid #8d8d8d",
                }}
              >
                <Typography
                  variant="h6"
                  className="mb-0"
                  sx={{
                    fontFamily: "Raleway",
                    fontSize: 16,
                    fontWeight: "400",
                    color: "#000000",
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="h6"
                  className="mb-0"
                  sx={{
                    fontFamily: "Raleway",
                    fontSize: 14,
                    fontStyle: "italic",
                    fontWeight: "300",
                    color: "#000000",
                  }}
                >
                  {item.category}
                </Typography>
                <Button
                  variant="none"
                  color="primary"
                  className="rounded-pill"
                  onClick={() => handleQuickDownload(item.image_id)}
                >
                  { featuredText[index] 
                  //"Quick Download"
                  }

                </Button>
              </div>
            </div>
    );
  };
*/
  const renderFeatured = () => {
    // check responsive design
    //console.log("onMobile: ", onMobile);
    //console.log("onXSMMobile: ", onXSMMobile);

    // if desktop show 5 images
    // if tablet show 3 images
    // if mobile then show only 2 images
    // if extra small mobile show only 1 image

    let filteredFeatures = featured;
    if (isMobile) {
      filteredFeatures = featured.slice(0, 2);
    } else if (isTablet) {
      filteredFeatures = featured.slice(0, 3);
    } else if (isDesktop) {
      filteredFeatures = featured.slice(0, 5);
    }

    return (
      <Box
        className="bg-banner-info2 px-4 text-center"
        title="Featured Images"
        label="Featured Images"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "6px",
          minWidth: "400px",
          width: "80%",
          backgroundColor: "#8BA0A4",
        }}
      >
        <Box className="d-flex align-items-center justify-content-center mx-auto py-2">
          <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center"
            padding="10px"
            minWidth="400px"
            width="100%"
          >
            <Typography
              variant="h5"
              className="mb-0"
              sx={{
                fontFamily: "Raleway",
                fontSize: 22,
                fontWeight: "400",
                color: "#ffffff",
              }}
            >
              {selectedCategory} Studio Shortcuts powered by SmartAI Agents✨
            </Typography>

            <Typography
              variant="h5"
              className="mb-0"
              sx={{
                fontFamily: "Raleway",
                fontSize: 12,
                fontStyle: "italic",
                fontWeight: "300",
                color: "#ffffff",
              }}
            >
              Click Image ==&gt; Navigates to Image Detail screen.
              <br />
              Click Button ==&gt; Navigates to Studio✨ screen, auto-downloads
              image (Chrome only)
              <br />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://app.termly.io/document/terms-of-use-for-website/376fff7a-0bd7-4e6b-a466-732926a0cbce"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                terms &amp; conditions
              </a>
            </Typography>
          </Stack>
        </Box>
        <Box
          className="row-cols-md-5"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            minWidth: "400px",
            width: "100%",
          }}
        >
          {filteredFeatures.map((image, index) => {
            let product = products.find(
              (p) =>
                p.name === `${image.width}x${image.height}` ||
                p.name === `${image.height}x${image.width}`
            );
            let credits = product ? product.creditsPrice : 0;
            let titleReduced =
              image.title.length < 37
                ? image.title
                : image.title.substr(0, 37) + "...";
            return (
              <Card
                className="col"
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  //height: "300px",
                  margin: "4px",
                }}
              >
                <Link
                  to={`/image?id=${image.image_id}`}
                  className="text-decoration-none"
                >
                  <img
                    component="img"
                    className="img-fluid"
                    alt={image.title}
                    height="160"
                    src={image.public_url_thumbnail}
                    title={titleReduced}
                    sx={{
                      width: "100%",
                      maxHeight: "200px",
                      borderRadius: "10px",
                      border: "1px solid #8d8d8d",
                      objectFit: "fill",
                      padding: "2px",
                      marginTop: "2px",
                    }}
                  />
                </Link>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                    borderRadius: "2px",
                    border: "1px solid #8d8d8d",
                  }}
                >
                  <Typography
                    className="mb-0"
                    sx={{
                      fontFamily: "Raleway",
                      fontSize: 12,
                      fontWeight: "400",
                      color: "#000000",
                    }}
                  >
                    {titleReduced} <br />
                    {credits} credits to download
                  </Typography>
                </CardContent>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={getTotalCreditsAvailable() < credits}
                  onClick={() =>
                    handleQuickDownload(image.image_id, featuredText[index])
                  }
                  sx={{
                    borderRadius: "12px",
                    padding: "5px",
                    margin: "5px",
                    fontSize: "14px",
                    fontWeight: "400",
                    width: "100%",
                    textTransform: "none",
                  }}
                >
                  {getTotalCreditsAvailable() < credits
                    ? "Buy Credits"
                    : featuredText[index]}
                </Button>
              </Card>
            );
          })}
        </Box>
      </Box>
    );
  };

  const HeaderBanner = () => {
    return (
      <Box className="bg-hero px-4 py-5 text-center">
        <Typography
          variant="h3"
          className="display-5"
          color="lightyellow"
          sx={{
            fontFamily: "Raleway",
            fontWeight: "700",
          }}
        >
          Original AI-Generated stock images
        </Typography>
        {
          // do not show in mobile
          !isMobile && (
            <div className="mx-auto">
              <Typography
                color="lightyellow"
                className="mb-4"
                sx={{
                  fontFamily: "Raleway",
                  fontSize: 20,
                  paddingTop: "12px",
                  fontWeight: "400",
                }}
              >
                Beautifully prompted, expertly curated, royalty-free images for
                all your digital projects
              </Typography>
              <Typography
                color="lightyellow"
                className="mb-4"
                sx={{
                  fontFamily: "Raleway",
                  fontStyle: "italic",
                  fontSize: 16,
                  fontWeight: "400",
                }}
              >
                smartai.studio - create. enhance. inspire.
              </Typography>
            </div>
          )
        }
        {auth.user ? <Searchbar /> : <></>}
      </Box>
    );
  };

  const NotAuthenticatedBanner = () => {
    return (
      <div
        className="bg-banner px-4 text-center"
        title="Register to access AI Studio features"
        label="Register to access AI Studio features"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minWidth: "400px",
          width: "100%",
        }}
      >
        <div className="d-flex align-items-center justify-content-center mx-auto py-2">
          <p className="mb-0 me-3 register-text">
            Register to access AI Studio features:
          </p>

          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/free-signup")}
            sx={{
              borderRadius: "12px",
              padding: "5px",
              margin: "5px",
              fontSize: "14px",
              fontWeight: "400",
              width: "200px",
              textTransform: "none",
            }}
          >
            Join For Free
          </Button>
        </div>
      </div>
    );
  };

  const ShowCategories = () => {
    // remove Uploads from the list
    let filteredCategories = categories.filter((item) => item !== "Uploads");
    return (
      <Box
        sx={{
          margin: "auto",
          width: "50%",
          mt: 3,
          mb: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="row text-center row-cols-2 row-cols-md-3 gy-5">
          {filteredCategories ? (
            filteredCategories.map((category, index) => {
              return (
                <div className="col" key={index}>
                  <Link
                    to={`/search?q=${category}`}
                    state={{ category }}
                    className="text-decoration-none m-auto"
                  >
                    {category}
                  </Link>
                </div>
              );
            })
          ) : (
            // <Box
            //   sx={{
            //     display: "flex",
            //     justifyContent: "center",
            //     alignItems: "center",
            //     height: "10vh",
            //   }}
            // >
            //   <CircularProgress color="inherit" />
            // </Box>
            <></>
          )}
        </div>
      </Box>
    );
  };

  const loadMoreHandler = () => {
    // console.log("loadMoreHandler() - entered");
    setPage(page + 1);
    setLoadingMore(true);
  };

  const UploadImageButton = () => {
    return (
      <LoadingButton
        loadingIndicator="Describing..."
        variant="contained"
        color="primary"
        disabled={!auth.user}
        onClick={() => { navigate("/upload"); }}
        sx={{
          borderRadius: "12px",
          padding: "5px",
          margin: "5px",
          fontSize: "16px",
          fontWeight: "500",
          width: "200px",
          height: "50px",
          textTransform: "none",
        }}
      >
        Upload Image
      </LoadingButton>
    );
  };

  const ShowImageTiles = () => {
    return (
      <Box
        sx={{
          alignContent: "center",
        }}
      >
       
        <Box
          sx={{
            margin: "auto",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            paddingTop: "20px",
          }}
        >         
          {ShowCategoriesCombo()}
        </Box>
        {/* add a checkbox above the Tiles to show quick actions on hover
            !onMobile &&
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                minWidth: "400px",
                width: "100%",
              }}
            >
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showQuickActions}
                      onChange={() => { setShowQuickActions(!showQuickActions); } }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Show Quick Actions on Hover"
                />
              </FormControl>
            </Box>
            */}

        {showLegend}

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "10vh",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <></>
        )}

        <Tiles
          props={props}
          data={data}
          faves={favorites}
          isUploads={selectedCategory === "Uploads"}
          loadMore={loadMoreHandler}
          handleQuickDownload={handleQuickDownload}
          //isLoading={loading}
        ></Tiles>

        {/*
        <Tiles
          items={data}
          onView={handleView}
          onDownload={handleDownload}
          onFavorite={handleFavorite}
          onShare={handleShare}
          loading={loading}
          isUploads={selectedCategory === "Uploads"}
        ></Tiles>
*/}
     
      </Box>
    );
  };

  const ShowSpinner = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "10vh",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  };

  const renderBrowseCategories = () => {
    return (
      <div
        className="bg-banner-info px-4 text-center"
        title="Browse All Categories"
        label="Browse All Categories"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          minWidth: "400px",
          width: "100%",
        }}
      >
        <div className="d-flex align-items-center justify-content-center mx-auto py-2">
          <p className="mb-0 me-3">Browse All Categories</p>
        </div>
      </div>
    );
  };

  const ShowNonAuthenticatedView = () => {
    return (
      <div
        className="px-4 text-center"
        title="Register to access AI Studio features"
        label="Register to access AI Studio features"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minWidth: "400px",
          width: "100%",
        }}
      >
        <Box
          className="d-flex align-items-center justify-content-center mx-auto py-2"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            minWidth: "400px",
            width: "100%",
            minHeight: "400px",
            height: "400px",
          }}
        >
          <Typography
            variant="h1"
            className="mb-0"
            sx={{
              fontFamily: "Raleway",
              color: "#000000",
            }}
          >
            smartai.studio
          </Typography>
        </Box>
      </div>
    );
  };

  const ShowLoadMore = () => {
    return (
      <div className="px-4 my-5 text-center">
        <div className="d-flex align-items-center justify-content-center mx-auto py-2">
          <h5 className="fw-bold color-primary">
            <Button
              disabled={page >= MAX_NUM_PAGED - 1}
              className="rounded-pill"
              variant="contained"
              onClick={() => {
                loadMoreHandler();
              }}
            >
              {page >= MAX_NUM_PAGED - 1
                ? "Maximum paging results - please use Search"
                : "Load More Images - PAGE " +
                  (page + 1) +
                  " of " +
                  MAX_NUM_PAGED}
            </Button>
          </h5>
        </div>
      </div>
    );
  };

  const showLegend = (
    <Stack    
      direction="row"
      spacing={4}
      justifyContent="center"
      alignItems="center"
      textAlign={"center"}
      padding="20px"
      minWidth={isMobile ? 400 : 800}
      width="100%"
      //margin={isMobile ? 4 : 10}
      sx={{
        backgroundColor: "#f0f0f0",
        borderRadius: "10px",
        border: "1px solid #8d8d8d",
      }}
    >
      <Typography
        variant="body2"
        className="col"
        sx={{
          paddingLeft: "20px",
        }}
      >
        <FontAwesomeIcon size="lg" color="#666" icon={solid("download")} /> -
        download (Studio✨)
      </Typography>

      <Typography variant="body2" className="col">
        <FontAwesomeIcon size="lg" color="#666" icon={solid("info-circle")} /> - image
        details
      </Typography>
    
     
          {UploadImageButton()}
       
      {/*
        <Typography variant="body2" color="#AAA" className="col">
        <FontAwesomeIcon size="lg" color="#AAA" icon={solid("heart")} /> -
        favorite
      </Typography>
      <Typography variant="body2" color="#AAA" className="col">
        <FontAwesomeIcon size="lg" color="#AAA" icon={solid("share")} /> - share
      </Typography> */ }
    </Stack>
  );

  return (
    <div
      className="home"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        minWidth: "400px",
        width: "100%",
        minHeight: "800px",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={authLoading}
      >
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
      {HeaderBanner()}
      {!auth.user ? (
        <Box
          flex
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            minWidth: "400px",
          }}
        >
          {ShowNonAuthenticatedView()}
          {NotAuthenticatedBanner()}
        </Box>
      ) : (
        <Box
          flex
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            minWidth: "400px",
          }}
        >
          {
            //renderFeatured()
          }
          {loading && !loadingMore ? ShowSpinner() : ShowImageTiles()}
          {showMore ? ShowLoadMore() : <></>}
          {renderBrowseCategories()}
          <p />
          {ShowCategories()}
        </Box>
      )}
    </div>
  );
}
