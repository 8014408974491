import React, { useState, useRef, useEffect, useMemo } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import ImageService from "../../services/image.service";
import ProductService from "../../services/product.service";
import TextGenerationService from "../../services/textgeneration.service";
import {
  EnsureToken,
  isTokenExpired,
  useAuth,
} from "../../services/auth/use-auth";
import CropComponent from "../../components/StudioComponent/crop/CropComponent";
import TimeAgo from "react-timeago";
import Typography from "@mui/material/Typography";
// import "./ImageDetailBeta.css";
import "./ImageDetail.css";
import externalLinkIconBlack from "../../assets/img/externalLinkIconBlack.svg";
import { useTheme } from "@mui/material/styles";
import TimerComponent from "../../components/timer/Timer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { LogSomething, TraceSomething } from "../../utils/utilsStringFuncs";
import { PrepareDisplayString } from "../../utils/utilsStringFuncs";
import { getStoryTypesForCategory } from "../../utils/utilsTextFuncs";
import { DetermineImageCreditPrice, DetermineTextCreditPrice } from "../../utils/utilsProduct";


import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Paper,
  Grid,
  Divider,
  Avatar,
  IconButton,
  Box,
  CircularProgress,
  LinearProgress,
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  ToggleButtonGroup,
  ToggleButton,
  Checkbox,
  Link,
  Chip,
  Slider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  Stack,
  InputBase,
  useMediaQuery,
  createTheme,
  ThemeProvider,
  Backdrop,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import UserService from "../../services/user.service";
import { LoadingButton } from "@mui/lab";
import StoryIcon2 from "../../icons/story";
import SocialShare from "../../components/social-share/SocialShare";
import ImageFilter from "react-image-filter";
import { SingleColorPicker } from "../../utils/utilsColorPickers";
import { EffectPercentPicker } from "../../utils/utilsColorPickers";
//import downloadSvgImage from "../../utils/utilsImageFuncs";
import CanvasFilters from "../../components/Graphics/CanvasFilters";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { getMoods } from "../../utils/utilsTextFuncs";
import { getGenres } from "../../utils/utilsTextFuncs";
import { getAtmospheric } from "../../utils/utilsTextFuncs";
import { getPostTypes } from "../../utils/utilsTextFuncs";
import { getThemes } from "../../utils/utilsTextFuncs";
import { LogUserDetails } from "../../utils/utilsUser";

import "react-image-crop/dist/ReactCrop.css";
import varyIcon from "../../assets/img/discover.png";
import filtersIcon from "../../assets/img/smartai-studio-filters-logo.png";
import cropsIcon from "../../assets/img/smartai-studio-crops-logo.png";
import storyIcon from "../../assets/img/smartai-studio-story-logo.png";
import agentIcon from "../../assets/img/agents-icon-two-120px.png";
import agentIconWriter from "../../assets/img/agents-icon-storywriter.png";
import agentIconFashion from "../../assets/img/smartai-studio-agent-fashion-design1.png";
import agentIconInteriorDesigner from "../../assets/img/smartai-studio-agent-fashion-design1.png";

import agentIconScifi from "../../assets/img/agents-icon-scifi1.png";
import agentIconChef from "../../assets/img/agents-icon-chef1.png";
import agentIconCinema from "../../assets/img/agents-icon-cinema1.png";
import agentIconGuru from "../../assets/img/smartai-studio-sage-logo1.png";
import framesIcon from "../../assets/img/smartai-studio-frames-logo.png";
import textIcon from "../../assets/img/smartai-studio-text-logo.png";
import duotonesIcon from "../../assets/img/smartai-studio-duotones-logo-burgundy-cyan.png";

import StackedEffectCanvas from "./StackedEffects";
import html2canvas from "html2canvas";
import GetFriendlyUsername from "../../utils/utilsStringFuncs";
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
  deviceType,
  mobileVendor,
  mobileModel,
  getUA,
  isDesktop,
  isMobile,
  isTablet,
} from "react-device-detect";

//import { icon } from "@fortawesome/fontawesome-svg-core";
//import { image } from "html2canvas/dist/types/css/types/image";

async function urlToBlob(url, setUrl) {
  try {
    // Fetch the data from the URL
    const blober = await fetch(url, { mode: "cors" }).then((r) => r.blob());

    // fetch url in a CORS compliant way and convert to blob
    //const response = await fetch(url, { mode: "cors" });

    const urlBlob = URL.createObjectURL(blober);
    LogSomething("BLOB: " + JSON.stringify(urlBlob));
    return setUrl(urlBlob);
  } catch (error) {
    console.error(error);
    // Handle the error appropriately
    return null;
  }
}

export default function ImageStudio() {
  const auth = useAuth();
  const [data, setData] = useState(null);
  const [localImageUrl, setLocalImageUrl] = useState(null);
  const [currentStage, setCurrentStage] = useState(null);
  const [story, setStory] = useState("");
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(null);
  const [credits, setCredits] = useState(null);
  const [hashtags, setHashtags] = useState([]); // ["#smartai", "#stockimages"
  const [shareUrl, setShareUrl] = useState("");
  const [resolutionWidth, setResolutionWidth] = useState(0);
  const [resolutionHeight, setResolutionHeight] = useState(0);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [includeTagsGen, setIncludeTagsGen] = useState(false);
  const [productsTextGen, setProductsTextGen] = useState([]);
  const [productsImageGen, setProductsImageGen] = useState([]);
  const [productsImageGenPrice, setProductsImageGenPrice] = useState(0);
  const [productIdImageGen, setProductIdImageGen] = useState(0);
  const [productIdTextGen, setProductIdTextGen] = useState(0);
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [imageGenProduct, setImageGenProduct] = useState([]);
  const [seed, setSeed] = useState(0);

  //const [product, setSelectedProduct] = useState(null);
  const [capabilities, setCapabilities] = useState(null);
  const [genImages, setgenImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [storyLoading, setStoryLoading] = useState(false);
  const [imageGenLoading, setImageGenLoading] = useState(false);
  const [imageGenSimilar, setImageGenSimilar] = useState(false);
  const [generatedType, setGeneratedType] = useState("");
  const [imageGenStyle, setImageGenStyle] = useState("Realistic");
  const [imageGenStyle2, setImageGenStyle2] = useState("");
  const [agentMood1, setAgentMood1] = useState("Excited");

  const [agentStoryType, setAgentStoryType] = useState("");
  const [agentMood2, setAgentMood2] = useState("");
  const [agentMood3, setAgentMood3] = useState("");
  const [agentAtmosphere1, setAgentAtmosphere1] = useState("");
  const [agentAtmosphere2, setAgentAtmosphere2] = useState("");
  const [agentPov1, setAgentPov1] = useState("First Person");
  const [agentPov2, setAgentPov2] = useState("");
  const [agentTone1, setAgentTone1] = useState("Dramatic");
  const [agentTone2, setAgentTone2] = useState("");
  const [agentGenre, setAgentGenre] = useState("");


  const [agentPostType, setAgentPostType] = useState("");
  const [imageMood, setImageMood] = useState("Vibrant");
  const [imageGenOptionAtmosphere, setImageGenOptionAtmosphere] =
    useState("Vibrant");
  const [imageGenOptionAtmosphere2, setImageGenOptionAtmosphere2] =
    useState("");

  const [imageCulturalOption, setImageCulturalOption] = useState("");

  const [imageAspect, setImageAspect] = useState("");
  const [imageModel, setImageModel] = useState("FLUX (single)");
  const [textModel, setTextModel] = useState("ChatGPT mini");

  // agent category specific options
  const [agentCulinaryCuisine, setagentCulinaryCuisine] = useState("");
  const [agentCulinary2, setAgentCulinary2] = useState("");
  const [agentCinemaGenre, setagentCinemaGenre] = useState("");
  const [agentCinema2, setAgentCinema2] = useState("");
  const [agentScifiGenre, setAgentScifiGenre] = useState("");
  const [agentScifiWorld, setAgentScifiWorld] = useState("");
  const [agentScifiTech, setAgentScifiTech] = useState("");
  const [agentScifiAlien, setAgentScifiAlien] = useState("");

  const [agentFantasy1, setAgentFantasy1] = useState("");
  const [agentFantasy2, setAgentFantasy2] = useState("");
  const [agentAnimeSubgenre, setagentAnimeSubgenre] = useState("");
  const [agentAnime2, setAgentAnime2] = useState("");
  const [agentCharacter1, setAgentCharacter1] = useState("");
  const [agentCharacter2, setAgentCharacter2] = useState("");
  const [agentPortrait1, setAgentPortrait1] = useState("");
  const [agentPortrait2, setAgentPortrait2] = useState("");

  const [agentMaterials, setAgentMaterials] = useState("");
  const [agentColorPalette, setAgentColorPalette] = useState("");
  const [agentTextures, setAgentTextures] = useState("");
  const [agentFashionStyle, setAgentFashionStyle] = useState("");
  const [agentInteriorDesignStyle, setAgentInteriorDesignStyle] = useState("");

  const [agentNatureHabitat, setAgentNatureHabitat] = useState("");
  const [agentNatureSpecies, setAgentNatureSpecies] = useState("");
  const [agentNatureLocation, setAgentNatureLocation] = useState("");

  const [agentVoice, setAgentVoice] = useState("");
  const [agentMarketingGoals, setAgentMarketingGoals] = useState("");
  const [agentAudience, setAgentAudience] = useState("");
  const [agentMarketingCTA, setAgentMarketingCTA] = useState("");

  const [agentDifficulty, setAgentDifficulty] = useState("");
  const [agentDietaryRestrictions, setAgentDietaryRestrictions] = useState("");

  const [progressGenImage, setProgressGenImage] = useState(0);
  const [progressGenText, setProgressGenText] = useState(0);
  const [varyStrength, setVaryStrength] = useState("Medium");
  const [seedOption, setSeedOption] = useState("Original");
  const [storyExpanded, setStoryExpanded] = useState(false);
  const [priorStoriesExpanded, setPriorStoriesExpanded] = useState(false);
  const [tagsExpanded, setTagsExpanded] = useState(false);
  const [topicsExpanded, setTopicsExpanded] = useState(false);
  const [imageGenOptionsExpanded, setImageGenOptionsExpanded] = useState(false);
  const [agentOptionsStoryExpanded, setAgentOptionsStoryExpanded] =
    useState(true);
  const [agentOptionsMarketingExpanded, setAgentOptionsMarketingExpanded] =
    useState(false);
  const [agentOptionsGuruExpanded, setAgentOptionsGuruExpanded] =
    useState(true);
  const [agentOptionsExpanded, setAgentOptionsExpanded] = useState(false);
  const [customExpanded, setCustomExpanded] = useState(false);
  const [poemExpanded, setPoemExpanded] = useState(false);

  const [selectedTheme1, setSelectedTheme1] = useState("");
  const [selectedTheme2, setSelectedTheme2] = useState("");
  const [selectedTheme3, setSelectedTheme3] = useState("");
  const [textGens, setTextGens] = useState([]);

  const [currentTab, setCurrentTab] = useState(0);

  //const [selectedColors, setSelectedColors] = useState([]);
  //const [selectedFilterColorsDuoTone, setFilterColorsDuoTone] = useState([[0, 0, 0],[0, 0, 0]]);
  const [color1R, setColor1R] = React.useState(255);
  const [color1G, setColor1G] = React.useState(191);
  const [color1B, setColor1B] = React.useState(0);
  const [color2R, setColor2R] = React.useState(75);
  const [color2G, setColor2G] = React.useState(0);
  const [color2B, setColor2B] = React.useState(130);
  //  const [color1, setColor1] = React.useState([color1R, color1G, color1B]);
  //  const [color2, setColor2] = React.useState([color2R, color2G, color2B]);

  const [genEffect, setGenEffect] = React.useState(0);
  const [inFocus, setInfocus] = React.useState(null);
  const [effectBlur, setEffectBlur] = React.useState({
    bool: false,
    value: 0,
    inFocus: "blur",
  });
  const [effectSepia, setEffectSepia] = React.useState({
    bool: false,
    value: 0,
    inFocus: "sepia",
  });
  const [effectGrayscale, setEffectGrayscale] = React.useState({
    bool: false,
    value: 0,
    inFocus: "grayscale",
  });
  /*
  const [effectEdgeDetect, setEffectEdgeDetect] = React.useState({
    bool: false,
    value: 0,
    inFocus: "edge-detect",
  });
  */
  const [effectSharpen, setEffectSharpen] = React.useState({
    bool: false,
    value: 0,
    inFocus: "sharpen",
  });
  const [effectOpacity, setEffectOpacity] = React.useState({
    bool: false,
    value: 100,
    inFocus: "opacity",
  });
  const [effectHueRotate, setEffectHueRotate] = React.useState({
    bool: false,
    value: 0,
    inFocus: "hue-rotate",
  });
  const [effectInvert, setEffectInvert] = React.useState({
    bool: false,
    value: 0,
    inFocus: "invert",
  });
  const [effectSaturate, setEffectSaturate] = React.useState({
    bool: true,
    value: 55,
    inFocus: "saturate",
  });

  const [effectContrast, setEffectContrast] = React.useState({
    bool: true,
    value: 55,
    inFocus: "contrast",
  });

  const [effectBrightness, setEffectBrightness] = React.useState({
    bool: true,
    value: 45,
    inFocus: "brightness",
  });
  const [effectDropShadow, setEffectDropShadow] = React.useState({
    bool: false,
    value: 0,
    inFocus: "drop-shadow",
  });

  //const [selectedTags, setSelectedTags] = useState([]);
  const [selectedFrame, setSelectedFrame] = useState(0);
  //const [quad, setQuad] = useState([]);
  const [userTags, setUserTags] = useState([]);
  const [showArrowOverlay, setShowArrowOverlay] = useState(false);
  const [showArrowOverlayGenText, setShowArrowOverlayGenText] = useState(false);
  const [showArrowOverlayGenImage, setShowArrowOverlayGenImage] =
    useState(false);

  const [searchParam] = useSearchParams();
  const identifier = searchParam.get("id");
  const SMARTAI_CATEGORY_TEXTGEN_ID = 3;
  const SMARTAI_CATEGORY_GENIMAGE_ID = 4;
  const SMARTAI_DEFAULT_PRODUCT_ID_IMAGEGEN = 37;
  const SMARTAI_PRODUCT_CAPABILITY_TEXTGEN_ID = 15;
  const SMARTAI_PRODUCT_TEXTGEN_256 = 21;
  const SMARTAI_PRODUCT_TEXTGEN_512 = 23;
  const SMARTAI_PRODUCT_TEXTGEN_768 = 26;
  const SMARTAI_PRODUCT_TEXTGEN_1024 = 27;
  const SMARTAI_PRODUCT_TEXTGEN_1536 = 28;
  const SMARTAI_PRODUCT_TEXTGEN_2048 = 29;

  /*
  // Memoize the ShowGeneratedTextSection component
const MemoizedShowGeneratedTextSection = useMemo(() => {
  return (
    <ShowGeneratedTextSection
      textGens={textGens}
      // other props
    />
  );
}, [textGens]); // Only re-render when textGens changes
*/

  useEffect(() => {
    LogSomething("ImageStudio ID: ", JSON.stringify(identifier));
    LogSomething("ImageStudio user: ", JSON.stringify(auth.user));
    getSubscription();
    fetchData();
    getProducts();
    // getTextGenPrice();
    resetFilters();
    LogSomething("browserDetails: " + browserDetailsLog());
    LogSomething("data: " + JSON.stringify(data));
  }, [identifier, auth.user]);

  useEffect(() => {
        //getTextGenerations(identifier);
    //setTextGens([]);
      if (data === null) {
        return;
      }
  
      var height = resolutionHeight;
      var width = resolutionWidth;
      
      LogSomething("getImageGenPrice - H:" + height + " W:" + width + " M:" + imageModel);
      //LogSomething("getImageGenPrice(width): " + width);
  
      if (height === 0 || width === 0) {
        return;
      }
  
      if (imageModel !== null && imageModel !== undefined) {
      let modelTier = (imageModel.includes("MED") || imageModel.includes("FLUX")) ? "low" : "low";
      let price = DetermineImageCreditPrice(height, width, modelTier);
      setProductsImageGenPrice(price);
      //return price;
    }
  }, [imageModel]);



  // Add useEffect for first visit check
  useEffect(() => {
    const hasVisitedTerms = localStorage.getItem("hasVisitedTerms");
    const hasVisitedGenText = localStorage.getItem("hasVisitedGeneratedText");
    const hasVisitedGenImage = localStorage.getItem("hasVisitedGeneratedImage");

    if (!hasVisitedTerms) {
      LogSomething("useEffect() - Overlay Checks - T&C");
      setShowArrowOverlay(true);
      localStorage.setItem("hasVisitedTerms", "true");

      // Hide overlay after 10 seconds
      setTimeout(() => setShowArrowOverlay(false), 10000);
    }
    if (!hasVisitedGenText) {
      LogSomething(
        "useEffect() - Overlay Checks - First visit to Text Generation"
      );
      setShowArrowOverlayGenText(true);
      localStorage.setItem("hasVisitedGeneratedText", "true");

      // Hide overlay after 10 seconds
      setTimeout(() => setShowArrowOverlayGenText(false), 10000);
    }
    if (!hasVisitedGenImage) {
      LogSomething(
        "useEffect() - Overlay Checks - First visit to Text Generation"
      );
      setShowArrowOverlayGenImage(true);
      localStorage.setItem("hasVisitedGeneratedImage", "true");

      // Hide overlay after 10 seconds
      setTimeout(() => setShowArrowOverlayGenImage(false), 10000);
    }
  }, []);

  // setup useEffect for story generation state vars including storyLoading, storyExpanded, and setStory
  useEffect(() => {
    if (storyLoading) {
      LogSomething("useEffect() - Story Loading - Story is loading...");
    } else {
      LogSomething("useEffect() - Story Loading - Story has finished loading");
    }

    if (storyExpanded) {
      LogSomething("useEffect() - Story Expanded - Story is expanded");
    }
  }, [storyLoading, storyExpanded]);

  const studioTheme = createTheme({
    components: {
      // set the default color for all MuiToggleButtons
      MuiToggleButton: {
        styleOverrides: {
          root: {
            color: "black",
            "&:hover": {
              backgroundColor: "#d74a4a",
            },
            "&.Mui-selected": {
              color: "primary",
              backgroundColor: "#8BA0A4",
            },
          },
        },
      },
    },
  });

  // make a general url to render images everywhere
  useEffect(() => {
    if (data !== null) {
      urlToBlob(data.public_url, setLocalImageUrl);
    }
  }, [data]);

  useEffect(() => {
    LogSomething("LOCAL IMAGE URL:" + localImageUrl);
  }, [localImageUrl]);

  const handleGeneralSliderChange = (event, newValue) => {
    setGenEffect(newValue);
  };

  useEffect(() => {
    if (inFocus === "blur") {
      setEffectBlur({
        bool: effectBlur.bool,
        value: genEffect,
        inFocus: effectBlur.inFocus,
      });
    } else if (inFocus === "sepia") {
      setEffectSepia({
        bool: effectSepia.bool,
        value: genEffect,
        inFocus: effectSepia.inFocus,
      });
    } else if (inFocus === "grayscale") {
      setEffectGrayscale({
        bool: effectGrayscale.bool,
        value: genEffect,
        inFocus: effectGrayscale.inFocus,
      });
    } else if (inFocus === "brightness") {
      setEffectBrightness({
        bool: effectBrightness.bool,
        value: genEffect,
        inFocus: effectBrightness.inFocus,
      });
    } else if (inFocus === "sharpen") {
      setEffectSharpen({
        bool: effectSharpen.bool,
        value: genEffect,
        inFocus: effectSharpen.inFocus,
      });
    } else if (inFocus === "opacity") {
      setEffectOpacity({
        bool: effectOpacity.bool,
        value: genEffect,
        inFocus: effectOpacity.inFocus,
      });
    } else if (inFocus === "hue-rotate") {
      setEffectHueRotate({
        bool: effectHueRotate.bool,
        value: genEffect,
        inFocus: effectHueRotate.inFocus,
      });
    } else if (inFocus === "invert") {
      setEffectInvert({
        bool: effectInvert.bool,
        value: genEffect,
        inFocus: effectInvert.inFocus,
      });
    } else if (inFocus === "saturate") {
      setEffectSaturate({
        bool: effectSaturate.bool,
        value: genEffect,
        inFocus: effectSaturate.inFocus,
      });
    } else if (inFocus === "contrast") {
      setEffectContrast({
        bool: effectContrast.bool,
        value: genEffect,
        inFocus: effectContrast.inFocus,
      });
    }
  }, [genEffect]);

  function randomizeSeed() {
    var _seed = Math.floor(Math.random() * 100000);
    LogSomething("randomizeSeed: " + _seed);
    setSeed(_seed);
    return _seed;
  }

  useEffect(() => {
    let mounted = true;

    if (!auth.user.uid) {
      return;
    }
    if (mounted) {
      if (identifier !== null && identifier !== undefined) {
        const getTextGenerations = async (id) => {
          TraceSomething("getTextGenerations: " + id);
          if (auth.user === null || id === null) {
            return;
          }
          if (auth.user === undefined || id === undefined) {
            return;
          }

          var textGensCurrent =
            await TextGenerationService.GetUserTextGenerationsByImageId(
              auth.user.uid,
              id,
              0,
              48,
              await EnsureToken(auth.user.auth.currentUser)
            );

            TraceSomething("getTextGenerations: " + JSON.stringify(textGensCurrent)
          );
          setTextGens(textGensCurrent);
        };

        TraceSomething("getTextGenerations: " + identifier);
        getTextGenerations(identifier);
      }
    }

    return () => {
      mounted = false;
    };
  }, [identifier, auth.user, story]);

  useEffect(() => {
    if (!identifier) {
      return;
    }

    if (!auth.user) {
      return;
    }

    let mounted = true;

    const getImageDetails = async () => {
      TraceSomething("getImageDetails - auth.user: " + auth.user);

      // get image from public
      //const response = await ImageService.GetImageByIdentifier(auth.user.uid,identifier, auth.user.auth.currentUser.accessToken);
      //setResolutionHeight(response.height);
      //setResolutionWidth(response.width);
      //var url = response.public_url_protected;
      //LogSomething("Normal URL: ", response.public_url_protected);

      // get image from user
      if (auth.user) {
        TraceSomething("getImageDetails - auth.user: " + auth.user);
        const userImageResponse = await ImageService.GetUserImage(
          auth.user.uid,
          identifier,
          auth.user.auth.currentUser.accessToken
        );
        if (userImageResponse) {
          TraceSomething(
            "getImageDetails - userImageResponse: " +
              JSON.stringify(userImageResponse)
          );
          TraceSomething(
            "getImageDetails - USER URL: " + userImageResponse.public_url
          );
          setData(userImageResponse);
          setResolutionHeight(userImageResponse.height);
          setResolutionWidth(userImageResponse.width);
          setUserTags(
            userImageResponse.tags.filter((tag) => {
              return tag.split(":")[0] === "color" && tag.includes(":");
            })
          );

          setSeed(userImageResponse.seed);
          var hashtags = userImageResponse.tags
            .filter((tag) => {
              return (
                tag.split(":")[0] === "topic" ||
                tag.split(":")[0] === "category"
              );
            })
            .map((tag) => {
              return tag.split(":")[1];
            });

          setHashtags(hashtags);
        }
      }

      return data;
    };

    if (mounted) {
      getImageDetails();
    }

    return () => {
      mounted = false;
    };
  }, [identifier, auth.user]);

  const getSubscription = async () => {
    if (auth.user === null || auth.user === undefined) {
      return;
    }

    if (auth.user) {
      const response = await UserService.GetUserCredits(
        auth.user.uid,
        await EnsureToken(auth.user.auth.currentUser)
      );

      LogSomething(
        "Logged in, subscription info for auth.user.id:",
        auth.user.uid,
        "\n",
        JSON.stringify(response)
      );

      setCredits(response.creditProfile);
      //// LogSomething("CreditProfile: ", JSON.stringify(response.creditProfile));
    } else {
      // LogSomething("CreditProfile: No user logged in");
      /*
      var credit = {
        creditProfile: {
          numSubscriptionCreditsRemaining: -1,
          numWalletCreditsRemaining: -1,
          numPendingCredits: -1,
        },
      };

      setSubscription(credit);
      */
    }
  };

  const getUserDetails = async () => {
    if (auth.user) {
      if (!user) {
        // console.log("get user: ", user);
        UserService.GetUserProfile(
          auth.user.uid,
          await EnsureToken(auth.user.auth.currentUser)
        )
          .then((res) => {
            LogSomething("getUserDetails response: ", res.data);
            const name = res.data.name.split(" ");
            //const user = { ...res.data };

            setUser(res.data);
            setFirstName(name[0]);
            setLastName(name.length > 1 ? name[1] : "");
          })
          .catch((err) => {
            LogSomething("Error retrieving user details: ", err);
          });
      }
    }
  };

  const fetchData = async () => {
    await getUserDetails();
    //var imageData = await getImageDetails();

    // set share url
    var shareUrl =
      "https://" + window.location.host + "/image?id=" + identifier;
    setShareUrl(shareUrl);

    //resetFilters();
  };

  /*
  const getTextGenPrice = async () => {
    return 5;
    let products = productsTextGen;
    if (products === null) {
      products = await ProductService.GetProductListByCategoryId(
        SMARTAI_CATEGORY_TEXTGEN_ID
      );
      setProductsTextGen(products);
    }

    if (products) {
      let _textGenProduct = products.find(
        (p) => p.name.includes("-textgen-") && p.name.includes("500")
      );

      if (_textGenProduct === null || _textGenProduct === undefined) {
        return 0;
      } else {
        setProductIdTextGen(_textGenProduct.productId);
        return _textGenProduct.creditsPrice;
      }
    }

    return 0;
  };
*/

  
    /*
    let products = productsImageGen;
    if (products === null) {
      products = await ProductService.GetProductListByCategoryId(
        auth.user.uid,
        SMARTAI_CATEGORY_GENIMAGE_ID,
        auth.user.currentUser.accessToken
      );
      setProductsImageGen(products);
    }

    LogSomething(
      "getImageGenPrice(productsImageGen): " + JSON.stringify(products)
    );

    if (products) {
      let res1 = `${height}x${width}`;
      let res2 = `${width}x${height}`;

      TraceSomething("getImageGenPrice(res1): " + res1);
      TraceSomething("getImageGenPrice(res2): " + res2);
      let _imageGenProduct = products.find(
        (p) =>
          p.name.includes("-imagegen-") &&
          (p.name.includes(res1) || p.name.includes(res2))
      );

      if (_imageGenProduct === null || _imageGenProduct === undefined) {
        TraceSomething("getImageGenPrice(products): product not found");
        return 0;
      } else {
        TraceSomething(
          "getImageGenPrice(_imageGenProduct): product found: " +
            JSON.stringify(_imageGenProduct)
        );

        if (
          _imageGenProduct.productId !== null ||
          _imageGenProduct.productId !== undefined
        ) {
          setProductIdImageGen(_imageGenProduct.productId);
          setImageGenProduct(_imageGenProduct);

          if (_imageGenProduct.creditsPrice > 0) {
            setProductsImageGenPrice(_imageGenProduct.creditsPrice);
            // LogSomething("getImageGenPrice(_imageGenProduct): " + JSON.stringify(_imageGenProduct) );
         
          }
        } else {
          // do resolution pricing

          LogSomething("getImageGenPrice(products): product not found");
          return 0;
        }

        return _imageGenProduct.creditsPrice;
      }
    } else {
      LogSomething("getImageGenPrice(products): product not found");
    }


    */

    //return 0;
 // };

  const genImage = async (
    data,
    tags,
    seedNew,
    includeTags,
    style,
    style2,
    atmosphere1,
    atmosphere2,
    culture,
    imageAspect,
    imageModel,
    useImage,
    varyStrength
  ) => {
    if (data === null) {
      return;
    }

    if (auth.user === null) {
      return;
    }

    if (imageGenLoading || imageGenSimilar) {
      return;
    }

    var varyValue = 0.5;
    // convert vary strength to number
    if (varyStrength === "Min") {
      varyValue = 0.65;
    } else if (varyStrength === "Low") {
      varyValue = 0.7;
    } else if (varyStrength === "Medium") {
      varyValue = 0.8;
    } else if (varyStrength === "High") {
      varyValue = 0.9;
    } else if (varyStrength === "Max") {
      varyValue = 0.95;
    }

    if (useImage)
      setImageGenLoading(true);
    else
      setImageGenSimilar(true);

    // add style to tags if already exists, replace
    if (tags.includes("style:")) {
      tags = tags.filter((tag) => !tag.startsWith("style:"));
    }
    tags.push("style:" + style);

    if (tags.includes("style2:")) {
      tags = tags.filter((tag) => !tag.startsWith("style2:"));
    }
    tags.push("style2:" + style2);

    if (tags.includes("atmosphere:")) {
      tags = tags.filter((tag) => !tag.startsWith("atmosphere:"));
    }
    tags.push("atmosphere:" + atmosphere1);

    if (tags.includes("atmosphere2:")) {
      tags = tags.filter((tag) => !tag.startsWith("atmosphere2:"));
    }
    tags.push("atmosphere2:" + atmosphere2);

    if (tags.includes("culture:")) {
      tags = tags.filter((tag) => !tag.startsWith("culture:"));
    }
    tags.push("culture:" + culture);

    /*
    if (tags.includes("mood:")) {
      tags = tags.filter((tag) => !tag.startsWith("mood:"));
    }
    tags.push("mood:" + imageMood);
*/

    if (tags.includes("aspect:")) {
      tags = tags.filter((tag) => !tag.startsWith("aspect:"));
    }
    tags.push("aspect:" + imageAspect);

    let productId = productIdImageGen;

    if (productId === 0) {
      productId = SMARTAI_DEFAULT_PRODUCT_ID_IMAGEGEN;
    }

    if (auth.user && acceptTerms && data.image_id !== "" && productId > 0) {
      var userId = auth.user.uid;
      var token = await EnsureToken(auth.user.auth.currentUser);

      // remove extra words from imageModel
      let cleaned = imageModel.replace(" (quad)", "");
      cleaned = cleaned.replace(" (single)", "");

      try {
        const response = await ProductService.VaryImageUsingCredits(
          userId,
          token,
          productId,
          data.image_id,
          cleaned,
          seedNew,
          useImage ? data.public_url : "",
          varyValue,
          tags,
          includeTags
        );

        if (response.success === false) {
          alert("Vary failed: " + response.message);
          LogSomething("genImage: " + JSON.stringify(response));
        } else {
          LogSomething("Vary Response: " + JSON.stringify(response));
          //LogSomething("Vary Response: " + JSON.stringify(response.imageUrls));
          //let imageUrls = genImages.push(response.imageUrls);

          //LogSomething("PRIOR IMAGE URLS " + JSON.stringify(genImages));
          //let imageUrls = genImages.push(response.imageUrls);
          //LogSomething("UPDATED IMAGE URLS " + JSON.stringify(imageUrls));
          if (response.imageUrls.length !== 4) {
            // prepend new images to arrary
            setgenImages([...genImages, response.imageUrls]);
          } else {
            setgenImages(response.imageUrls);
          }

          //LogSomething("Vary Response: " + response.imageUrls);
          // LogSomething("Vary Response: " + JSON.stringify(response.imageUrls));
        }
      } catch (error) {
        alert("Failed to generate image: ", error);
      }
    }
    setImageGenLoading(false);
    setImageGenSimilar(false);
    // update credits remaining
    getSubscription();
  };

  /*
    const formulatePromptFromImage = (imageData, postType, themes) => {
        LogSomething("formulatePromptFromImage: ", imageData, " themes: ", themes);
        var cat = themes[0].toLowerCase();
        var mood = themes[1];
        var atmosphere = themes[2];

        if (imageData) {
            if (cat === undefined || cat === null || cat === "") {
                cat = imageData.category;
            }

            if (mood === undefined || mood === null || mood === "") {
                // get random topic if undefined
                var topics = imageData.tags.filter((tag) => tag.startsWith("tag:"));
                if (topics.length > 0) {
                    mood = topics[Math.floor(Math.random() * topics.length)];
                } else {
                    mood = "happy";
                }
            }

            if (
                atmosphere === undefined ||
                atmosphere === null ||
                atmosphere === ""
            ) {
                // get random topic if undefined
                var topics = imageData.tags.filter((tag) => tag.startsWith("topic:"));
                if (topics.length > 0) {
                    atmosphere = topics[Math.floor(Math.random() * topics.length)];
                } else {
                    atmosphere = "happy";
                }
            }

            var colorTags = imageData.tags.filter((tag) => tag.startsWith("color:"));
            var materialsTags = imageData.tags.filter((tag) =>
                tag.startsWith("materials:")
            );
            var topicTags = imageData.tags.filter((tag) => tag.startsWith("topic:"));
            var tags = imageData.tags.filter((tag) => tag.startsWith("tag:"));
            var locations = imageData.tags.filter((tag) =>
                tag.startsWith("location:")
            );

            // TODO - Add tagsExtra to the prompt
            var promptPrefix =
                "Generate a " +
                cat +
                " styled " + postType + " with an " +
                atmosphere +
                " based atmosphere and an overaching mood of " +
                mood +
                " incorporate the following additional context: ";

            var tagsBuffer =
                colorTags.length > 0
                    ? " colors:" + colorTags.join(", ").replaceAll("color:", "")
                    : "" + (materialsTags.length > 0)
                        ? " and materials: " +
                        materialsTags.join(", ").replaceAll("materials:", "")
                        : "" + (topicTags.length > 0)
                            ? " and topics: " + topicTags.join(", ").replaceAll("topic:", "")
                            : "" + (tags.length > 0)
                                ? " and tags: " + tags.join(", ").replaceAll("tag:", "")
                                : "";

            //var suffix = (themes.length > 0) ? " and incorporate the following thematic elements: " + themes.join(", ") : "";

            var promptComplete =
                promptPrefix + imageData.description + tagsBuffer + " [/INST]";
            LogSomething("formulatePromptFromImage: prompt - ", promptComplete);
        }

        return promptComplete;
    };
*/

  const SetupGenerateOptionTags = () => {
    var options = [];

    options.push("model:" + textModel);
    options.push("pov:" + agentPov1);
    options.push("storyType:" + agentStoryType);
    options.push("tone:" + agentTone1);
    options.push("tone:" + agentTone2);

    options.push("mood:" + agentMood1);
    options.push("mood:" + agentMood2);
    options.push("mood:" + agentMood3);

    options.push("atmosphere:" + agentAtmosphere1);
    options.push("atmosphere:" + agentAtmosphere2);

    options.push("genre:" + agentGenre);
    options.push("subgenre:" + agentScifiGenre);
    options.push("world:" + agentScifiWorld);
    options.push("tech:" + agentScifiTech);
    options.push("alien:" + agentScifiAlien);

    options.push("culinary:" + agentCulinaryCuisine);
    options.push("culinary:" + agentCulinary2);
    options.push("cinema:" + agentCinemaGenre);
    options.push("cinema:" + agentCinema2);
    options.push("fantasy:" + agentFantasy1);
    options.push("fantasy:" + agentFantasy2);
    options.push("anime:" + agentAnimeSubgenre);
    options.push("anime:" + agentAnime2);
    options.push("character:" + agentCharacter1);
    options.push("character:" + agentCharacter2);
    options.push("portrait:" + agentPortrait1);
    options.push("portrait:" + agentPortrait2);

    options.push("habitat:" + agentNatureHabitat);
    options.push("species:" + agentNatureSpecies);
    options.push("location:" + agentNatureLocation);

    options.push("materials:" + agentMaterials);
    options.push("color palette:" + agentColorPalette);
    options.push("textures:" + agentTextures);
    options.push("design style:" + agentFashionStyle);
    options.push("interiordesignstyle:" + agentInteriorDesignStyle);

    options.push("difficulty:" + agentDifficulty);
    options.push("dietary restrictions:" + agentDietaryRestrictions);

    options.push("voice:" + agentVoice);
    options.push("goals:" + agentMarketingGoals);
    options.push("audience:" + agentAudience);
    options.push("marketing cta:" + agentMarketingCTA);
    //options.push("email type", agentEmailType);
    //options.push("webpage type", agentWebpageType);

    options.push("posttype:" + agentPostType);
    options.push("theme:" + selectedTheme1);
    options.push("theme:" + selectedTheme2);
    options.push("theme:" + selectedTheme3);

    // remove entries with empty values
    options = options.filter((opt) => opt.split(":")[1] !== "");

    LogSomething("SetupGenerateOptionTags: " + JSON.stringify(options));
    return options;
  };

  async function CheckUserSession() {
    //LogUserDetails(auth);

    // check if user is logged in
    if (auth.user === null) {
      TraceSomething("GenerateTextFromImage: User not logged in");
      return false;
    }

    // check if token is valid
    if (isTokenExpired(auth.user.auth.currentUser)) {
      TraceSomething("GenerateTextFromImage: User token expired.. refreshing");
      await EnsureToken(auth.user.auth.currentUser);
    }
    if (isTokenExpired(auth.user.auth.currentUser)) {
      TraceSomething(
        "GenerateTextFromImage: User token expired.. failed to refresh"
      );
      return false;
    } else {
      TraceSomething("GenerateTextFromImage: User token refreshed");
      return true;
    }
  }

  const CleanTextGen = (text) => {
    TraceSomething("CleanTextGen: (raw):", JSON.stringify(text));
    try {
      // Early return for null/undefined/empty
      if (!text) return "";

      // Ensure we're working with a string
      const inputText = String(text);

      // Chain replacements for better performance
      const clean = inputText
        .replace(/\[\/INST\]/g, "")
        .replace(/```json/g, "\n")
        .replace(/```/g, "")
        .replace(/[{}\[\]]/g, "") // Remove all brackets
        .trim(); // Remove leading/trailing whitespace

      TraceSomething("CleanTextGen (clean):" + JSON.stringify(text));
      return clean;
    } catch (error) {
      LogSomething("Error in CleanTextGen:" + error);
      return ""; // Return empty string on error
    }
  };

  const GenerateTextFromImage = async (
    productId,
    data,
    postType,
    numTokens,
    includeTags
  ) => {
    if (!CheckUserSession()) {
      return;
    }

    setGeneratedType(postType);
    startTextProgressTimer();

    var userId = auth.user.uid;
    var imageId = data.image_id;
    var userToken = auth.user.auth.currentUser.accessToken;
    var options = SetupGenerateOptionTags();

    try {
      setStoryLoading(true);

      //var response = await ProductService.GenerateTextForCredits(
      var response = await ProductService.InvokeAgenticActivityForCredits(
        userId,
        userToken, //auth.user.auth.currentUser.accessToken,
        imageId,
        productId,
        numTokens,
        postType,
        options,
        includeTags
      );
      LogSomething("GenerateStoryFromImage: ", response);

      if (prompt === undefined || prompt === null || prompt === "") {
        setStoryLoading(false);
        return;
      }

      // clean up story and set story vars
      //let storyRaw = response;
      let storyClean = CleanTextGen(response.generatedText);
      response.generatedText = storyClean;
      setStory(response);
      setStoryLoading(false);
      setStoryExpanded(true);
      setTextGens([]);
    } catch (error) {
      setStoryLoading(false);
      LogSomething("Error invoking agent: ", error);
      alert("Error invoking agent: ", error);
    }

    getSubscription();

    // scroll to story
    var element = document.getElementById("storyGen");

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSelectedFrame = (id) => {
    setSelectedFrame(id);
  };

  // download image from bucket
  const download = async () => {
    // LogSomething("downloadImage: " + JSON.stringify(data));
    // const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
    currentStage.toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      var urlDownload = url ? url : data.public_url_reduced;
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download =
        "SmartAI-StockImages_" +
        urlDownload.substr(urlDownload.lastIndexOf("/") + 1);
      link.click();
    });
    // const blob = await fetch(urlDownload).then((r) => r.blob());
    // const urlBlob = URL.createObjectURL(blob);
  };

  const resetFilters = () => {
    if (!genEffect) {
      return;
    }
    setEffectBlur({ bool: true, value: 0, inFocus: "blur" });
    handleFilterRender("blur", setEffectBlur);
    setEffectSepia({ bool: true, value: 0, inFocus: "sepia" });
    handleFilterRender("sepia", setEffectSepia);
    setEffectGrayscale({ bool: true, value: 0, inFocus: "grayscale" });
    handleFilterRender("grayscale", setEffectGrayscale);
    setEffectSharpen({ bool: true, value: 0, inFocus: "sharpen" });
    handleFilterRender("sharpen", setEffectSharpen);
    setEffectOpacity({ bool: true, value: 100, inFocus: "opacity" });
    handleFilterRender("opacity", setEffectOpacity);
    setEffectHueRotate({ bool: true, value: 0, inFocus: "hue-rotate" });
    handleFilterRender("hue-rotate", setEffectHueRotate);
    setEffectInvert({ bool: true, value: 0, inFocus: "invert" });
    handleFilterRender("invert", setEffectInvert);
    setEffectSaturate({ bool: true, value: 55, inFocus: "saturate" });
    handleFilterRender("saturate", setEffectSaturate);
    setEffectContrast({ bool: true, value: 55, inFocus: "contrast" });
    handleFilterRender("contrast", setEffectContrast);
    setEffectBrightness({ bool: true, value: 45, inFocus: "brightness" });
    handleFilterRender("brightness", setEffectBrightness);
    setEffectDropShadow({ bool: true, value: 0, inFocus: "drop-shadow" });
    handleFilterRender("drop-shadow", setEffectDropShadow);
    //setGenEffect(0);
  };

  /*
  const downloadImageFromUrl = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const urlBlob = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = urlBlob;
    link.target = "_blank";
    link.download = "SmartAI_Studio_VaryImage_" + identifier + ".png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadDuotoneImage = async (id) => {
    const imageElement = document.getElementById(id);
    imageElement.style.width = resolutionWidth > 0 ? resolutionWidth : "1024px";
    imageElement.style.height =
      resolutionHeight > 0 ? resolutionHeight : "1024px";

    const svgImageElement = imageElement.querySelector("svg image");
    const urlDownload = svgImageElement.getAttribute("xlink:href").toString();
    const downloadName = `SmartAI-StockImages_${urlDownload.substring(
      urlDownload.lastIndexOf("/") + 1
    )}_duotone`;

    const response = await fetch(svgImageElement.getAttribute("xlink:href"));
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      svgImageElement.setAttribute("xlink:href", base64data);
      html2canvas(imageElement).then((canvas) => {
        const a = document.createElement("a");
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob);
          a.href = url;
          a.download = `${downloadName}.png`;
          a.click();
        });
      });
    };
  };
*/

  const downloadSvgFilteredImage = async (id, width, height) => {
    // Get the filtered image element
    const imageElement = document.getElementById(id);
    imageElement.style.width = width > 0 ? width : "1024px";
    imageElement.style.height = height > 0 ? height : "1024px";
    LogSomething(imageElement);

    // Get the SVG image element
    const svgImageElement = imageElement.querySelector("svg image");
    //svgImageElement.style.width = "100%" //(width); // + (.05 * width);
    //svgImageElement.style.height = "100%" //(height); //  + (.05 * height);
    //svgImageElement.style.width = resolutionWidth > 0 ? resolutionWidth : "1024px";
    //svgImageElement.style.height = resolutionHeight > 0 ? resolutionHeight : "1024px";
    const urlDownload = svgImageElement.getAttribute("xlink:href").toString();
    //LogSomething(urlDownload.substring(urlDownload.lastIndexOf("/") + 1));
    const downloadName = `SmartAI_Studio_${urlDownload.substring(
      urlDownload.lastIndexOf("/") + 1
    )}_duotone`;

    // Convert blob URL to data URL
    const response = await fetch(svgImageElement.getAttribute("xlink:href"));
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;

      // Replace the blob URL with the data URL
      svgImageElement.setAttribute("xlink:href", base64data);

      // const cloneImageElement = imageElement.cloneNode(true);
      // cloneImageElement.style.width = '1024px';
      // cloneImageElement.style.height = '1024px';
      //LogSomething(cloneImageElement);

      // Convert the filtered image to a canvas
      html2canvas(imageElement).then((canvas) => {
        const a = document.createElement("a");

        // Convert the canvas to a Blob object
        canvas.toBlob((blob) => {
          // Create a URL for the Blob object
          const url = URL.createObjectURL(blob);

          // Set the href of the anchor to the Blob URL
          a.href = url;

          // Set the download attribute of the anchor to the desired file name
          a.download = `${downloadName}.png`;

          // Trigger a click event on the anchor
          a.click();
        });
      });
    };
  };

  /*
    const purchaseTextGeneration = async (id) => {
        LogSomething('purchaseTextGeneration(): ' + identifier);
        LogSomething('purchaseTextGeneration(): auth.user ' + auth.user);
        LogSomething('purchaseTextGeneration(): auth.user.id ' + auth.user.uid);
        LogSomething('purchaseTextGeneration(): acceptTerms ' + acceptTerms);
        LogSomething('purchaseTextGeneration(): product ' + product);
        LogSomething('purchaseTextGeneration(): productId ' + product.productId);
        LogSomething('purchaseTextGeneration(): # credits ' + product.creditsPrice);
    
        setLoading(true);

        if (
          auth.user &&
          acceptTerms &&
          identifier !== '' &&
          product &&
          product.productId > 0
        ) {
         
          var userId = auth.user.uid;
    
          const response = await ProductService.PurchaseTextGenerationUsingCredits(
            userId,
            product.productId,
            identifier,
          );
    
          if (response.success === false) {
            alert('Purchase failed: ' + response.message);
            // LogSomething("purchaseImage: " + response);
          } else {
            // LogSomething("downloadImage: " + response.product.downloadUrl);
            // LogSomething("downloadImage: " + response.product.assetUrl);
            //downloadImage(response.product.downloadUrl);
            download(response.product.downloadUrl);
            navigate('/studio?id=' + identifier);
          }
        }
        setLoading(false);
      };
*/

  const handleThemeChange1 = (event) => {
    setSelectedTheme1(event.target.value);
  };
  const handleThemeChange2 = (event) => {
    setSelectedTheme2(event.target.value);
  };
  const handleThemeChange3 = (event) => {
    setSelectedTheme3(event.target.value);
  };

  const getSubscriptionCreditsRemaining = () => {
    if (credits) {
      return credits.numSubscriptionCreditsRemaining;
    }
    return 0;
  };

  const getWalletCreditsRemaining = () => {
    if (credits) {
      return credits.numWalletCreditsRemaining;
    }
    return 0;
  };

  const getProducts = async () => {
    const productResponseText = await ProductService.GetProductListByCategoryId(
      auth.user.uid,
      SMARTAI_CATEGORY_TEXTGEN_ID,
      auth.user.auth.currentUser.accessToken
    );

    LogSomething("getProducts(Text): ", JSON.stringify(productResponseText));
    setProductsTextGen(productResponseText);

    const productResponseImage =
      await ProductService.GetProductListByCategoryId(
        auth.user.uid,
        SMARTAI_CATEGORY_GENIMAGE_ID,
        auth.user.auth.currentUser.accessToken
      );
    LogSomething("getProducts(Image): ", JSON.stringify(productResponseImage));
    setProductsImageGen(productResponseImage);
  };

  const renderFilteredImageDuoTone = (
    imageUrl,
    filterName,
    color1,
    color2,
    width,
    height
  ) => {
    // 'duotone'
    LogSomething(
      `renderImage(${filterName}): [` + color1 + `] [` + color2 + `]`
    );
    var id = filterName + "_" + color1 + "_" + color2;
    LogSomething("renderImage(" + id + ")");

    return (
      <>
        <ImageFilter
          id={id}
          image={imageUrl}
          filter={filterName}
          colorOne={color1}
          colorTwo={color2}
          width={width}
          height={height}
        />

        {/* this allows for resizing the duotone image for download without messing up the preview on the UI */}
        {
          <Box sx={{ position: "fixed", zIndex: "-1", top: "-10000px" }}>
            <ImageFilter
              id={"download_duotone_image"}
              image={imageUrl}
              filter={filterName}
              colorOne={color1}
              colorTwo={color2}
              width={width}
              height={height}
            />
          </Box>
        }
        <Stack direction="row" py="20px" gap="10px" justifyContent="center">
          <Button
            className="rounded-pill"
            variant="contained"
            sx={{
              textTransform: "none",
              fontSize: "1rem",
            }}
            disabled={loading}
            onClick={() =>
              downloadSvgFilteredImage("download_duotone_image", width, height)
            }
            //onClick={() => downloadDuotoneImage("download_duotone_image")}
          >
            Download Duotone Image
          </Button>
        </Stack>
        <Stack direction="row" py="20px" gap="10px" justifyContent="center">
          <Typography
            sx={{
              fontSize: "1rem",
              fontFamily: "Raleway",
              fontWeight: "bold",
              color: "black",
            }}
          >
            Featured Duotones
          </Typography>
          {renderFeaturedDuotones()}
        </Stack>
      </>
    );
  };

  /*
          const generateFilteredImages = async (identifier) => {
              LogSomething("generateFilteredImages(): " + identifier);
              LogSomething("generateFilteredImages(): auth.user " + auth.user);

              var filtered = utilsImageFuncs.applyFilter(data.public_url, "grayscale");
              setFilteredImages(filtered);
              LogSomething("generateFilteredImages(): " + filtered);
          }
      */
  const inputIncludeTags = acceptTerms && (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        padding: "10px",
      }}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeTagsGen}
              onChange={(e, c) => setIncludeTagsGen(c)}
            />
          }
          label={
            <Typography sx={{ fontSize: "13px" }}>include tags</Typography>
          }
        />
      </FormGroup>
    </Box>
  );
  // Add styled arrow component
  const ArrowOverlay = styled("div")({
    marginTop: "10px",
    position: "relative",
    left: "-60px",
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: "2rem",
    color: "#d74a4a",
    animation: "blink 1.5s infinite",
    "@keyframes blink": {
      "0%": { opacity: 0 },
      "50%": { opacity: 1 },
      "100%": { opacity: 0 },
    },
    zIndex: 1000,
  });

  const inputTermsPrivacy = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        padding: "20px",
      }}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptTerms}
              onChange={(e, c) => setAcceptTerms(c)}
            />
          }
          label={
            <Typography sx={{ fontSize: "13px" }}>
              I agree to the{" "}
              <Link
                target="_blank"
                href="https://app.termly.io/document/terms-of-use-for-website/376fff7a-0bd7-4e6b-a466-732926a0cbce"
              >
                terms &amp; conditions
              </Link>
            </Typography>
          }
        />
      </FormGroup>
      {showArrowOverlay && <ArrowOverlay>^^^^^^^</ArrowOverlay>}
    </Box>
  );
  const copyImageToClipboard = () => {
    return (
      <IconButton
        className="fas fa-image"
        title="copy image as png"
        aria-label="copy image as png"
        onClick={async () => {
          const img = document.querySelector("#studioImage");
          const canvas = document.createElement("canvas");
          canvas.width = img.naturalWidth; // or 'width' if you want a special size
          canvas.height = img.naturalHeight; // or 'height' if you want a special size
          canvas.getContext("2d").drawImage(img, 0, 0);
          const blob = await new Promise((resolve) =>
            canvas.toBlob(resolve, "image/png")
          );
          const clipboardItem = new ClipboardItem({ "image/png": blob });
          navigator.clipboard.write([clipboardItem]);
        }}
      />
    );
  };

  const RenderTopics = (data) => {
    return (
      <Accordion
        expanded={topicsExpanded}
        sx={{
          width: "100%",
          height: "100%",
          padding: "10px",
          marginTop: "10px",
          fontSize: "1.2rem",
          fontFamily: "Raleway",
          backgroundColor: !topicsExpanded ? "#f5f5f5" : "lightgray",
        }}
      >
        <AccordionSummary
          onClick={() => setTopicsExpanded(!topicsExpanded)}
          expandIcon={
            <ExpandMoreIcon
              onClick={() => setTopicsExpanded(!topicsExpanded)}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            width: "100%",
            height: "100%",

            fontSize: "1.2rem",
            fontFamily: "Raleway",
            backgroundColor: !topicsExpanded ? "#f5f5f5" : "lightgray",
          }}
        >
          <Typography
            variant="h5"
            color="text.secondary"
            style={{
              width: "100%",

              fontFamily: "Raleway",
              fontWeight: "600",
            }}
          >
            Topics
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: !topicsExpanded ? "#f5f5f5" : "#EAEAEA",
            border: "1px solid #ccc",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            fontFamily: "Raleway",
          }}
        >
          <div className="d-flex flex-row flex-wrap">
            {data.tags
              .filter((tag) => {
                return tag.split(":")[0] === "topic";
              })
              .map((tag, index) => {
                return (
                  <Chip
                    key={`tags__${index}`}
                    color="tertiary"
                    sx={{ margin: 0.2 }}
                    label={
                      <Typography
                        sx={{
                          margin: 1,
                          fontFamily: "Raleway",
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                      >
                        {tag.split(":")[1]}
                      </Typography>
                    }
                  />
                );
              })}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };
  const RenderCategory = (data) => {
    return (
      <Chip
        key={`tags__${data.category}`}
        color="secondary"
        sx={{ margin: 0.2 }}
        label={
          <Typography
            sx={{
              margin: 1,
              fontFamily: "Raleway",
              fontSize: "1.0 rem",
              fontWeight: "bold",
            }}
          >
            {data.category}
          </Typography>
        }
      />
    );
  };
  const RenderTags = (data) => {
    return (
      <Accordion
        expanded={tagsExpanded}
        sx={{
          width: "100%",
          height: "100%",
          padding: "10px",
          marginTop: "10px",
          fontSize: "1.2rem",
          fontFamily: "Raleway",
          backgroundColor: !tagsExpanded ? "#f5f5f5" : "lightgray",
        }}
      >
        <AccordionSummary
          onClick={() => setTagsExpanded(!tagsExpanded)}
          expandIcon={
            <ExpandMoreIcon onClick={() => setTagsExpanded(!tagsExpanded)} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            width: "100%",
            height: "100%",

            fontSize: "1.2rem",
            fontFamily: "Raleway",
            backgroundColor: !tagsExpanded ? "#f5f5f5" : "lightgray",
          }}
        >
          <Typography
            variant="h5"
            color="text.secondary"
            style={{
              width: "100%",

              fontFamily: "Raleway",
              fontWeight: "600",
            }}
          >
            Tags
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: !tagsExpanded ? "#f5f5f5" : "#EAEAEA",
            border: "1px solid #ccc",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            fontFamily: "Raleway",
          }}
        >
          <div className="d-flex flex-row flex-wrap">
            {data.tags
              .filter((tag) => {
                return (
                  tag.split(":")[0] !== "topic" &&
                  tag.split(":")[0] !== "category" &&
                  tag.includes(":")
                );
              })
              .map((tag, index) => {
                return (
                  <Chip
                    key={`selected__key__${index}`}
                    sx={{ margin: 0.2 }}
                    label={
                      <Typography
                        sx={{
                          margin: 1,
                          fontFamily: "Raleway",
                          fontSize: 12,
                        }}
                      >
                        {tag.split(":")[1]}
                      </Typography>
                    }
                  />
                  // <Card
                  //   variant="outlined"
                  //   sx={{ width: 180, height: 50 }}
                  // >
                  //   <CardContent className="text-center">
                  //     <Typography
                  //       sx={{ fontFamily: "Raleway", fontSize: 12 }}
                  //     >
                  //       {tag.split(":")[1]}
                  //     </Typography>
                  //   </CardContent>
                  // </Card>
                );
              })}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  const SocialMedia = (isStory) => {
    return (
      <SocialShare
        url={shareUrl}
        title={data.title}
        externalLinkIcon={externalLinkIconBlack}
        decription={isStory ? story : data.description}
        hashtags={hashtags}
      />
    );
  };

  const PreviousTextGenerations = () => {
    return textGens ? (
      textGens.map((textGen, index) => {
        return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "#eee",
                border: "1px solid #ccc",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                fontFamily: "Raleway",
              }}
            >
              {new Date(textGen.generated_timestamp).toLocaleString()}{" "}
              &nbsp;|&nbsp;
              {textGen.textgen_type} &nbsp; | &nbsp;
              {textGen.tags.length > 0 ? textGen.tags[0] : ""} &nbsp; | &nbsp;
              {textGen.tags.length > 1 ? textGen.tags[1] : ""} &nbsp; | &nbsp;
              {textGen.tags.length > 2 ? textGen.tags[2] : ""}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "#eee",
                border: "1px solid #ccc",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                fontFamily: "Raleway",
              }}
            >
              {textGen.text}
            </AccordionDetails>
          </Accordion>
        );
      })
    ) : (
      <Box></Box>
    );
  };

  const ShowGeneratedTextPriors = () => {
    //let isData = data ? true : false;
    TraceSomething("ShowGeneratedTextPriors: # priors (" + textGens.length + ")");
    //LogSomething("ShowGeneratedTextPriors: loading(" + storyLoading +")");
    //LogSomething("ShowGeneratedTextPriors: expanded(" + storyExpanded +")");
    //LogSomething("ShowGeneratedTextPriors: # textGens(" + textGens.length +")");

    return (
      <div className="storyGen">
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            border: "1px solid #ccc",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Accordion
            sx={{
              width: "100%",
              height: "100%",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              fontFamily: "Raleway",
              backgroundColor: "lightgray", //'#8BA0A4',    // tertiary color
              animation:
                story && !storyLoading ? "blinker 1s linear infinite" : "white",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  onClick={() => setPriorStoriesExpanded(!priorStoriesExpanded)}
                />
              }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                // if story loading then style differently
                width: "100%",
                height: "100%",
                border: "1px solid #ccc",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                fontFamily: "Raleway",
              }}
            >
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  width: "100%",
                  padding: "10px",
                  fontFamily: "Raleway",
                  color:
                    textGens !== null && textGens.length > 0
                      ? "primary"
                      : "gray",
                  fontWeight: story && !storyLoading ? "600" : "400",
                  // set blink effect if story is available
                  animation:
                    story && !storyLoading
                      ? "blinker 1s linear infinite"
                      : "none",
                }}
              >
                Prior Agent Generations
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "mintcream",
                border: "1px solid #ccc",
                alignContent: "left",
                alignItems: "left",
                justifyContent: "left",
                padding: "10px",
                fontFamily: "Raleway",
              }}
            >
              {PreviousTextGenerations()}
            </AccordionDetails>
          </Accordion>
        </Box>
      </div>
    );
  };

  const ShowGeneratedTextSection = () => {
    //let isData = data ? true : false;
    //LogSomething("ShowGeneratedTextSection: story(" + story + ")");
    //LogSomething("ShowGeneratedTextSection: loading(" + storyLoading +")");
    //LogSomething("ShowGeneratedTextSection: expanded(" + storyExpanded +")");
    //LogSomething("ShowGeneratedTextSection: # textGens(" + textGens.length +")");

    return (
      <div className="storyGen">
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            border: "1px solid #ccc",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Backdrop open={storyLoading}>
          <CircularProgress 
                variant="indeterminate" 
                style={{
                  width: "25%",
                  height: "25%",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
               
              </CircularProgress>
          </Backdrop>

          <Accordion
            sx={{
              width: "100%",
              height: "100%",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              fontFamily: "Raleway",
              backgroundColor: "lightgray", //'#8BA0A4',    // tertiary color
              animation:
                story && !storyLoading ? "blinker 1s linear infinite" : "white",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  onClick={() => setStoryExpanded(!storyExpanded)}
                />
              }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                // if story loading then style differently
                width: "100%",
                height: "100%",
                border: "1px solid #ccc",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                fontFamily: "Raleway",
              }}
            >
              <IconButton
                title="Copy Latest to Clipboard"
                className="fas fa-copy"
                onClick={() => {
                  navigator.clipboard.writeText(story.generatedText);
                }}
              />

              <Typography
                variant="h5"
                color="text.secondary"
                style={{
                  textAlign: "center",
                  width: "100%",
                  padding: "10px",
                  fontFamily: "Raleway",
                  color: story && !storyLoading ? "#D74A49" : "gray",
                  fontWeight: story && !storyLoading ? "600" : "400",
                  // set blink effect if story is available
                  animation:
                    story && !storyLoading
                      ? "blinker 1s linear infinite"
                      : "none",
                }}
              >
                {story
                  ? storyLoading
                    ? "Generating New..."
                    : (storyExpanded ? "Latest Generation" : "Latest Generation - click here")
                  : textGens.length === 0
                  ? storyLoading
                    ? "Generating New..."
                    : "No Generations Yet"
                  : "New Generations"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "mintcream",
                border: "1px solid #ccc",
                alignContent: "left",
                alignItems: "left",
                justifyContent: "left",
                padding: "10px",
                fontFamily: "Raleway",
              }}
            >
              <Typography
                variant="body"
                color="text.secondary"
                style={{
                  textAlign: "left",
                }}
              >
                {
                  // parse out the story into paragraphs and display
                  story.generatedText
                    ? story.generatedText.split("\n").map((i, key) => {
                        return (
                          <Typography
                            variant="body"
                            color="text.secondary"
                            style={{
                              textAlign: "left",
                              padding: "10px",
                            }}
                          >
                            {i}
                            <br />
                          </Typography>
                        );
                      })
                    : story.message
                }
              </Typography>

              <Typography
                variant="paragraph"
                color="text.secondary"
                style={{
                  textAlign: "center",
                  width: "100%",
                  padding: "10px",
                  fontFamily: "Raleway",
                }}
              >
                <br />
                <strong>NOTE:</strong> Please review and edit the generated text
                as needed. <br /> Please report any offensive or inappropriate
                content <br />
                (see our <Link href="/about">About Page</Link> for more
                information).
                <br />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </div>
    );
  };

  const RenderAdvancedImageOptions = (toggles) => {
    return (
      <Accordion
        expanded={imageGenOptionsExpanded}
        sx={{
          width: "100%",
          height: "100%",
          padding: "10px",
          marginTop: "10px",
          fontSize: "1.2rem",
          fontFamily: "Raleway",
          backgroundColor: !imageGenOptionsExpanded ? "#f5f5f5" : "#8BA0A4",
        }}
      >
        <AccordionSummary
          onClick={() => setImageGenOptionsExpanded(!imageGenOptionsExpanded)}
          expandIcon={
            <ExpandMoreIcon
              onClick={() =>
                setImageGenOptionsExpanded(!imageGenOptionsExpanded)
              }
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            width: "100%",
            height: "100%",

            fontSize: "1.2rem",
            fontFamily: "Raleway",
            backgroundColor: !imageGenOptionsExpanded ? "#f5f5f5" : "#8BA0A4",
          }}
        >
          <Typography
            variant="h5"
            color="text.secondary"
            style={{
              width: "100%",

              fontFamily: "Raleway",
              fontWeight: "600",
            }}
          >
            Advanced Options
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: !imageGenOptionsExpanded ? "#f5f5f5" : "#EAEAEA",
            border: "1px solid #ccc",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            fontFamily: "Raleway",
          }}
        >
          <div className="d-flex flex-row flex-wrap">
            {AdvancedImageOptions()}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  const ImageCard = data ? (
    <Card
      className=""
      sx={{
        boxShadow: "none",
        borderRadius: "10px",
        border: "1px solid #e0e0e0",

        backgroundColor: "white",
        alignContent: "center",
        justifyContent: "center",
        padding: "10px",

        width: "100%",
        maxWidth: "600px",
        minWidth: "400px !important",
        // pin to top
        position: "sticky",
        top: "0",
        zIndex: 10,
      }}
    >
      <CardHeader
        sx={{
          borderRadius: "10px 10px 0 0",
        }}
        title={
          <div className="">
            <div className="card__header__top">
              <Chip
                sx={{
                  margin: 0.2,
                  padding: "10px",
                }}
                color="secondary"
                label={<Typography>{" " + data.category + " "}</Typography>}
              />

              <Button
                variant="contained"
                onClick={() => {
                  download(data.public_url);
                }}
              >
                <FontAwesomeIcon
                  size="lg"
                  color="#666"
                  icon={solid("download")}
                />{" "}
                &nbsp; Download Image
              </Button>

              {/*
              <div className="icons">
                <div className="icon">
                  <SocialShare
                    url={shareUrl}
                    title={data.title}
                    externalLinkIcon={externalLinkIconBlack}
                    decription={data.description}
                    hashtags={hashtags}
                  />
                </div>
              </div>
            */}
            </div>
            <Typography variant="h6" color="text.primary">
              {PrepareDisplayString(data.title)}
            </Typography>
          </div>
        }
        subheader={
          <Typography variant="body2" color="text.secondary">
            Uploaded by{" "}
            {data.generated_by === undefined || data.generated_by === auth.user.auth.currentUser.uid ? (
              <span>
                {firstName}
                {lastName}{" "}
              </span>
            ) : (
              GetFriendlyUsername(data.generated_by)
            )}{" "}
            &nbsp;
            <TimeAgo date={ data.generated_timestamp} />
          </Typography>
        }
        action={<IconButton aria-label="settings"></IconButton>}
      />

      {localImageUrl !== null ? (
        <Stack
          width="100%"
          justifyContent="center"
          alignItems="center"
          sx={{
            maxWidth: "600px",
            maxHeight: data.height ? `${data.height}px !important` : "600px",
            //minHeight: "600px",
            minWidth: "400px",
            width: "100%",
          }}
        >
          <StackedEffectCanvas
            imageUrl={localImageUrl}
            blur={effectBlur.bool ? effectBlur.value : 0}
            sepia={effectSepia.bool ? effectSepia.value : 0}
            grayscale={effectGrayscale.bool ? effectGrayscale.value : 0}
            opacity={effectOpacity.bool ? effectOpacity.value : 100}
            hueRotate={effectHueRotate.bool ? effectHueRotate.value * 3.6 : 0}
            invert={effectInvert.bool ? effectInvert.value : 0}
            saturate={effectSaturate.bool ? effectSaturate.value : 0}
            contrast={effectContrast.bool ? effectContrast.value : 0}
            brightness={effectBrightness.bool ? effectBrightness.value : 0}
            handleCurrentStage={(stage) => setCurrentStage(stage)}
            data={data}
          />
        </Stack>
      ) : (
        <Stack
          width="100%"
          height="600px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress 
                variant="indeterminate" 
                style={{
                  width: "25%",
                  height: "25%",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
               
              </CircularProgress>
        </Stack>
      )}
      {/*
          auth.user.uid === data.user_id && data.public_url !== undefined && (
          <img
            src={data.public_url}
            alt={data.title}
            style={{
              maxWidth: data.width ? `${data.width}px !important` : "600px",
              maxHeight: data.height
                ? `${data.height}px !important`
                : "600px",
              minHeight: "600px",
              minWidth: "400px",
              width: "100%",
            }}
          />
        )
      */}

      <CardContent
        sx={
          {
            // borderRadius: "0 0 10px 10px",
          }
        }
      >
        <Typography
          variant="h6"
          color="text.secondary"
          className="fw-bold mb-1"
        >
          Resolution
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            fontFamily: "Raleway",
            fontSize: "18px",
            paddingBottom: "10px",
          }}
        >
          {data.width}w x {data.height}h
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          className="fw-bold mb-1"
          sx={{
            fontFamily: "Raleway",
            fontSize: "18px",
          }}
        >
          Description
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          className="my-2"
          sx={{
            fontFamily: "Raleway",
            fontSize: "16px",
            paddingBottom: "10px",
          }}
        >
          {PrepareDisplayString(data.description)}
        </Typography>         
           <Typography variant="body2" color="text.secondary">
           <span className="fw-bold mb-1">generated by:&nbsp;</span>          
            {data.generated_by === undefined || data.generated_by === auth.user.auth.currentUser.uid ? (
              <span>
                {firstName}
                {lastName}{" "}
              </span>
            ) : (
              GetFriendlyUsername(data.generated_by)
            )}{" "}
          </Typography>
          <Typography variant="body2" color="text.secondary">
           <span className="fw-bold mb-1">prompted by:&nbsp;</span>          
            {data.prompted_by === auth.user.auth.currentUser.uid ? (
              <span>
                {firstName}
                {lastName}{" "}
              </span>
            ) : (
              GetFriendlyUsername(data.prompted_by)
            )}{" "}    
            {data.prompted_by === null || data.prompted_by === undefined ? (
              <span>
                N/A
              </span>
            ) : (
              <>  </>
            )}        
          </Typography>

        <div className="col-12">
          <div className="mt-3 mb-5">
            {RenderTopics(data)}
            {RenderTags(data)}
            {ShowGeneratedTextPriors()}
          </div>
        </div>

        <div className="flex__button">
          {
            //inputTermsPrivacy
          }
        </div>
      </CardContent>
    </Card>
  ) : (
    <div></div>
  );

  const createColorSlider = (handler) => {
    return (
      <Slider
        defaultValue={0}
        aria-label="Small"
        valueLabelDisplay="auto"
        step={1}
        marks
        min={0}
        max={255}
        onChange={handler}
      />
    );
  };

  const createColorSliderRGB = (name, handler) => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          color: "white",
          fontWeight: "bold",
          fontSize: "20px",
        }}
      >
        {createColorSlider(handler)}
        {createColorSlider(handler)}
        {createColorSlider(handler)}
      </Box>
    );
  };

  const createDuotonePreset = (name, color1, color2) => {
    return (
      <Button
        variant="contained"
        onClick={() => {
          setColor1R(color1[0]);
          setColor1G(color1[1]);
          setColor1B(color1[2]);
          setColor2R(color2[0]);
          setColor2G(color2[1]);
          setColor2B(color2[2]);
        }}
        style={{
          backgroundColor: `rgb(${color1[0]}, ${color1[1]}, ${color1[2]})`,
          color: `rgb(${color2[0]}, ${color2[1]}, ${color2[2]})`,
          width: "140px",
          objectFit: "cover",
          height: "24px",
          margin: "2px",
          padding: "4px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          fontSize: "12px",
          fontSmooth: "auto",
          fontOpticalSizing: "auto",
          fontStretch: "normal",
        }}
      >
        {name}
      </Button>
    );
  };

  const getDuoToneFilters = (data) => {
    let filters = [
      ["Burgundy Cyan", [128, 0, 32], [0, 255, 255]],
      ["Violet Gold", [138, 43, 226], [255, 215, 0]],
      ["ForestGreen Beige", [34, 139, 34], [245, 245, 220]],
      ["Navy Peach", [0, 0, 128], [255, 218, 185]],
      ["Raspberry Lemon", [227, 11, 93], [255, 247, 0]],
      ["Emerald Magenta", [0, 128, 0], [255, 0, 255]],
      ["Teal Orange", [0, 128, 128], [255, 165, 0]],
      ["Blue Tangerine", [65, 105, 225], [255, 140, 0]],
      ["HotPink Lime", [255, 105, 180], [0, 255, 0]],
      ["DeepPurple Mint", [75, 0, 130], [189, 252, 201]],
      ["Crimson Aqua", [220, 20, 60], [127, 255, 212]],
      ["SlateBlue Coral", [106, 90, 205], [255, 127, 80]],
      ["Indigo Chartreuse", [75, 0, 130], [127, 255, 0]],
      ["Electric Blue Rose", [0, 191, 255], [255, 0, 128]],
      ["Fuchsia Emerald", [255, 0, 255], [0, 128, 0]],
      ["Sunset Purple", [255, 99, 71], [148, 0, 211]],
      ["Midnight Gold", [25, 25, 112], [255, 215, 0]],
      ["Neon Green Coral", [57, 255, 20], [255, 127, 80]],
      ["Aqua Crimson", [0, 255, 255], [220, 20, 60]],
      ["Cobalt Yellow", [0, 71, 171], [255, 255, 0]],
      ["Magenta Cyan", [255, 0, 255], [0, 255, 255]],
      ["Lavender Gold", [230, 230, 250], [255, 215, 0]],
      ["Cherry Aqua", [222, 49, 99], [0, 255, 255]],
      ["Turquoise Lavender", [64, 224, 208], [230, 230, 250]],
      ["Amber Teal", [255, 191, 0], [0, 128, 128]],
      ["Slate Gray Apricot", [112, 128, 144], [251, 206, 177]],
      ["Crimson Lime", [220, 20, 60], [0, 255, 0]],
      ["Coral Aqua", [255, 127, 80], [0, 255, 255]],
      ["Magenta Lime", [255, 0, 255], [0, 255, 0]],
      ["Emerald Peach", [34, 139, 34], [255, 218, 185]],
      ["MidnightBlue Coral", [25, 25, 112], [255, 127, 80]],
      ["Plum Silver", [142, 69, 133], [192, 192, 192]],
      ["Teal RoseGold", [0, 128, 128], [183, 110, 121]],
      ["Amber Indigo", [255, 191, 0], [75, 0, 130]],
      ["Sunset Lavender", [255, 140, 0], [230, 230, 250]],
      ["Sapphire Cream", [15, 82, 186], [255, 253, 208]],
      ["Olive Blush", [128, 128, 0], [255, 182, 193]],
      ["Charcoal Mint", [54, 69, 79], [152, 255, 152]],
      ["Cobalt Coral", [0, 71, 171], [255, 127, 80]],
      ["Crimson Gold", [220, 20, 60], [255, 215, 0]],
      ["Lime Coral", [0, 255, 0], [255, 127, 80]],
      ["Magenta Gold", [255, 0, 255], [255, 215, 0]],
      ["Mint Coral", [152, 255, 152], [255, 127, 80]],
      ["Navy Gold", [0, 0, 128], [255, 215, 0]],
      ["Olive Coral", [128, 128, 0], [255, 127, 80]],
      ["Sapphire Coral", [15, 82, 186], [255, 127, 80]],
      ["Sapphire Gold", [15, 82, 186], [255, 215, 0]],
      ["Sapphire Peach", [15, 82, 186], [255, 218, 185]],
      ["Sapphire Silver", [15, 82, 186], [192, 192, 192]],
      ["Sapphire Teal", [15, 82, 186], [0, 128, 128]],
      ["Sapphire Violet", [15, 82, 186], [138, 43, 226]],
      ["Silver Coral", [192, 192, 192], [255, 127, 80]],
      ["Silver Gold", [192, 192, 192], [255, 215, 0]],
      ["Silver Peach", [192, 192, 192], [255, 218, 185]],
      ["Silver Teal", [192, 192, 192], [0, 128, 128]],
      ["Silver Violet", [192, 192, 192], [138, 43, 226]],
      ["Teal Coral", [0, 128, 128], [255, 127, 80]],
      ["Indigo Gold", [75, 0, 130], [255, 215, 0]],
      ["Aqua Magenta", [0, 255, 255], [255, 0, 255]],
      ["Ruby Cyan", [224, 17, 95], [0, 255, 255]],
      ["Moss Lime", [173, 223, 173], [50, 205, 50]],
      ["Electric Lime", [173, 255, 47], [50, 205, 50]],
      ["Fuchsia Blue", [255, 20, 147], [0, 0, 255]],
      ["Sunset Blue", [255, 69, 0], [0, 0, 255]],
      ["Cobalt Orange", [0, 71, 171], [255, 165, 0]],
      ["Neon Pink", [255, 105, 180], [255, 20, 147]],
      ["Turquoise Pink", [64, 224, 208], [255, 20, 147]],
      ["Mint Rose", [189, 252, 201], [255, 105, 180]],
      ["Copper Teal", [184, 115, 51], [0, 128, 128]],
      ["Rose Gold", [255, 192, 203], [255, 215, 0]],
      ["Cherry Lime", [222, 49, 99], [50, 205, 50]],
      ["Jade Lemon", [0, 168, 107], [255, 247, 0]],
      ["Amethyst Lime", [153, 102, 204], [50, 205, 50]],
      ["Graphite Orange", [54, 69, 79], [255, 165, 0]],
      ["Lime Violet", [50, 205, 50], [138, 43, 226]],
      ["Peacock Orange", [0, 204, 204], [255, 140, 0]],
      ["Aqua Violet", [0, 255, 255], [138, 43, 226]],
      ["Coral Violet", [255, 127, 80], [138, 43, 226]],
      ["Crimson Violet", [220, 20, 60], [138, 43, 226]],
      ["Emerald Violet", [0, 128, 0], [138, 43, 226]],
      ["Gold Violet", [255, 215, 0], [138, 43, 226]],
      ["Lime Violet", [50, 205, 50], [138, 43, 226]],
      ["Magenta Violet", [255, 0, 255], [138, 43, 226]],
      ["Mint Violet", [152, 255, 152], [138, 43, 226]],
      ["Orange Violet", [255, 165, 0], [138, 43, 226]],
      ["Rose Violet", [255, 105, 180], [138, 43, 226]],
      ["Teal Violet", [0, 128, 128], [138, 43, 226]],
      ["Violet Violet", [138, 43, 226], [138, 43, 226]],
      ["Aqua Gold", [0, 255, 255], [255, 215, 0]],
      ["Coral Gold", [255, 127, 80], [255, 215, 0]],
      ["Crimson Gold", [220, 20, 60], [255, 215, 0]],
      ["Emerald Gold", [0, 128, 0], [255, 215, 0]],
      ["Gold Gold", [255, 215, 0], [255, 215, 0]],
      ["Lime Gold", [50, 205, 50], [255, 215, 0]],
      ["Magenta Gold", [255, 0, 255], [255, 215, 0]],
      ["Mint Gold", [152, 255, 152], [255, 215, 0]],
      ["Orange Gold", [255, 165, 0], [255, 215, 0]],
      ["Rose Gold", [255, 105, 180], [255, 215, 0]],
      ["Teal Gold", [0, 128, 128], [255, 215, 0]],
      ["Violet Gold", [138, 43, 226], [255, 215, 0]],
      ["Aqua Peach", [0, 255, 255], [255, 218, 185]],
      ["Coral Peach", [255, 127, 80], [255, 218, 185]],
      ["Crimson Peach", [220, 20, 60], [255, 218, 185]],
      ["Emerald Peach", [0, 128, 0], [255, 218, 185]],
      ["Lime Peach", [50, 205, 50], [255, 218, 185]],
      ["Magenta Peach", [255, 0, 255], [255, 218, 185]],
      ["Mint Peach", [152, 255, 152], [255, 218, 185]],
      ["Orange Peach", [255, 165, 0], [255, 218, 185]],
      ["Rose Peach", [255, 105, 180], [255, 218, 185]],
      ["Teal Peach", [0, 128, 128], [255, 218, 185]],
      ["Violet Peach", [138, 43, 226], [255, 218, 185]],
      ["Cherry Midnight", [222, 49, 99], [25, 25, 112]],
      ["Sunset Aqua", [255, 69, 0], [0, 255, 255]],
      ["Emerald Violet", [0, 128, 0], [138, 43, 226]],
      ["Electric Purple", [0, 191, 255], [75, 0, 130]],
      ["Peach Emerald", [255, 218, 185], [0, 128, 0]],
      ["Coral Mint", [255, 127, 80], [152, 255, 152]],
      ["Ruby Mint", [224, 17, 95], [152, 255, 152]],
      ["Amethyst Gold", [153, 102, 204], [255, 215, 0]],
      ["Aqua Rose", [0, 255, 255], [255, 105, 180]],
      ["Midnight Teal", [25, 25, 112], [0, 128, 128]],
      ["Lime Magenta", [50, 205, 50], [255, 0, 255]],
      ["Sapphire Lime", [15, 82, 186], [50, 205, 50]],
      ["Crimson Turquoise", [220, 20, 60], [64, 224, 208]],
      ["Peach Plum", [255, 218, 185], [142, 69, 133]],
      ["Gold Mint", [255, 215, 0], [152, 255, 152]],
      ["Teal Lavender", [0, 128, 128], [230, 230, 250]],
      ["Ruby Indigo", [224, 17, 95], [75, 0, 130]],
      ["Fuchsia Navy", [255, 0, 255], [0, 0, 128]],
      ["Gold Sapphire", [255, 215, 0], [15, 82, 186]],
      ["Cyan Plum", [0, 255, 255], [142, 69, 133]],
      // now super creative and high contrast duotones
    ];

    // sort by contrast
    filters.sort((a, b) => {
      return a[1][0] - b[1][0];
    });

    return filters.map((filter, index) => {
      return createDuotonePreset(filter[0], filter[1], filter[2]);
    });
  };

  const renderSingleToneFilters = () => {
    return (
      <div className="row">
        <Box text="center">
          {createDuotonePreset("Black & White", [255, 255, 255], [0, 0, 0])}
          {createDuotonePreset("Red", [255, 255, 255], [255, 0, 0])}
          {createDuotonePreset("Green", [255, 255, 255], [0, 255, 0])}
          {createDuotonePreset("Blue", [255, 255, 255], [0, 0, 255])}
          {createDuotonePreset("Yellow", [255, 255, 255], [255, 255, 0])}
          {createDuotonePreset("Pink", [255, 255, 255], [255, 0, 255])}
          {createDuotonePreset("Cyan", [255, 255, 255], [0, 255, 255])}
          {createDuotonePreset("Orange", [255, 255, 255], [255, 128, 0])}
          {createDuotonePreset("Magenta", [255, 255, 255], [255, 0, 128])}
        </Box>
      </div>
    );
  };

  const renderFeaturedDuotones = () => {
    return (
      <div>
        <Box textAlign="center">
          {createDuotonePreset("Burgundy Cyan", [128, 0, 32], [0, 255, 255])}
          {createDuotonePreset("Violet Gold", [138, 43, 226], [255, 215, 0])}
          {createDuotonePreset(
            "ForestGreen Beige",
            [34, 139, 34],
            [245, 245, 220]
          )}
          {createDuotonePreset("Navy Peach", [0, 0, 128], [255, 218, 185])}
          {createDuotonePreset("Raspberry Lemon", [227, 11, 93], [255, 247, 0])}
          {createDuotonePreset("Emerald Magenta", [0, 128, 0], [255, 0, 255])}
          {createDuotonePreset("Teal Orange", [0, 128, 128], [255, 165, 0])}
          {createDuotonePreset("Blue Tangerine", [65, 105, 225], [255, 140, 0])}
          {createDuotonePreset("HotPink Lime", [255, 105, 180], [0, 255, 0])}
          {createDuotonePreset(
            "DeepPurple Mint",
            [75, 0, 130],
            [189, 252, 201]
          )}
          {createDuotonePreset("Crimson Aqua", [220, 20, 60], [127, 255, 212])}
          {createDuotonePreset(
            "SlateBlue Coral",
            [106, 90, 205],
            [255, 127, 80]
          )}
          {createDuotonePreset(
            "Indigo Chartreuse",
            [75, 0, 130],
            [127, 255, 0]
          )}
          {createDuotonePreset(
            "Electric Blue Rose",
            [0, 191, 255],
            [255, 0, 128]
          )}
          {createDuotonePreset("Fuchsia Emerald", [255, 0, 255], [0, 128, 0])}
          {createDuotonePreset("Sunset Purple", [255, 99, 71], [148, 0, 211])}
          {createDuotonePreset("Midnight Gold", [25, 25, 112], [255, 215, 0])}
          {createDuotonePreset(
            "Neon Green Coral",
            [57, 255, 20],
            [255, 127, 80]
          )}
          {createDuotonePreset("Aqua Crimson", [0, 255, 255], [220, 20, 60])}
          {createDuotonePreset("Cobalt Yellow", [0, 71, 171], [255, 255, 0])}
        </Box>
      </div>
    );
  };

  const DuoToneFilters = (data) => {
    return (
      <div>
        <Box textAlign="center">
          <Box
            onClick={() => handleSelectedFrame(1)}
            className={`frame ${selectedFrame === 1 ? "selected" : ""}`}
          >
            {renderFilteredImageDuoTone(
              localImageUrl ?? data.public_url,
              "duotone",
              [color1R, color1G, color1B],
              [color2R, color2G, color2B],
              data.width,
              data.height
            )}

            <Accordion
              expanded={selectedFrame === 1}
              sx={{
                "&.MuiAccordion-root": {
                  boxShadow: "none!important",
                  paddingTop: "10px",
                  marginBottom: "20px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  fontWeight="500"
                  fontSize="18px"
                  color="text.secondary"
                >
                  Custom DuoTone Filter
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  "&.MuiAccordionDetails-root": {
                    padding: "0 0 8px 0",
                  },
                }}
              >
                <div className="row">
                  <Box text="center">
                    <Stack
                      direction="row"
                      justifyContent="center"
                      gap="24px"
                      flexWrap="nowrap"
                    >
                      <Box
                        sx={{
                          width: "50%",
                          height: "fit-content",
                          padding: "20px",
                          boxSizing: "border-box",
                          border: "3px dashed #ccc",
                        }}
                      >
                        <Typography textAlign="center" pb="10px">
                          Color 1
                        </Typography>
                        <SingleColorPicker
                          value={color1R}
                          onChange={setColor1R}
                        />
                        <SingleColorPicker
                          value={color1G}
                          onChange={setColor1G}
                        />
                        <SingleColorPicker
                          value={color1B}
                          onChange={setColor1B}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "50%",
                          height: "fit-content",
                          padding: "20px",
                          boxSizing: "border-box",
                          border: "3px dashed #ccc",
                        }}
                      >
                        <Typography textAlign="center" pb="10px">
                          Color 2
                        </Typography>
                        <SingleColorPicker
                          value={color2R}
                          onChange={setColor2R}
                        />
                        <SingleColorPicker
                          value={color2G}
                          onChange={setColor2G}
                        />
                        <SingleColorPicker
                          value={color2B}
                          onChange={setColor2B}
                        />
                      </Box>
                    </Stack>
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              sx={{
                "&.MuiAccordion-root": {
                  boxShadow: "none!important",
                  paddingTop: "10px",
                  marginBottom: "20px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  fontWeight="500"
                  fontSize="18px"
                  color="text.secondary"
                >
                  Additional DuoTone Filters
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  "&.MuiAccordionDetails-root": {
                    padding: "0 0 8px 0",
                  },
                }}
              >
                <div className="row">
                  <Box
                    textAlign="center"
                    style={{
                      width: "100%",
                    }}
                  >
                    {getDuoToneFilters(data)}
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                "&.MuiAccordion-root": {
                  boxShadow: "none!important",
                  paddingTop: "10px",
                  marginBottom: "20px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  fontWeight="500"
                  fontSize="18px"
                  color="text.secondary"
                >
                  Single Tone Filters
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  "&.MuiAccordionDetails-root": {
                    padding: "0 0 8px 0",
                  },
                }}
              >
                {renderSingleToneFilters()}
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </div>
    );
  };

  const generateIcon = (icon, altText, title) => {
    return (
      <div className="icon">
        <img
          alt={altText}
          src={icon}
          title={title}
          style={{
            width: "40px",
            height: "40px",
            marginTop: "10px",
            marginBottom: "10px",
            padding: "2px",
            fontSize: "10px",
          }}
        />

        <Typography
          variant="body"
          color="text.secondary"
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: "Raleway",
          }}
        >
          {title}
        </Typography>
      </div>
    );
  };

  const RenderTabsSafari = () => {
    return (
      <div className="body">
        <Box>
        <div
            className={`tab ${currentTab === 0 ? "active" : ""}`}
            onClick={() => {
              setShowArrowOverlay(true);
              setCurrentTab(0);
            }}
          >
            {generateIcon(framesIcon, "Generate Image", "✨Image")}
          </div>
          <div
            className={`tab ${currentTab === 1 ? "active" : ""}`}
            onClick={() => {
              setShowArrowOverlay(true);
              setCurrentTab(1);
            }}
          >
            {generateIcon(agentIcon, "✨Perform an Agentic Task", "✨Agents")}
          </div>
          <div
            className={`tab ${currentTab === 2 ? "active" : ""}`}
            onClick={() => {
              setShowArrowOverlay(true);
              setCurrentTab(2);
            }}
          >
            {generateIcon(
              agentIconGuru,
              "✨Perform a Category Specific Task",
              "✨Gurus"
            )}
          </div>
         
          <div
            className={`tab ${currentTab === 4 ? "active" : ""}`}
            onClick={() => setCurrentTab(4)}
          >
            {generateIcon(duotonesIcon, "duotone", "Duotones")}
          </div>

          {/*
          <div
            className={`tab ${currentTab === 0 ? "active" : ""}`}
            onClick={() => setCurrentTab(0)}
          >
            {generateIcon(filtersIcon, "filters", "Filters")}
          </div>
          */}

          {/*
          <div
            className={`tab ${currentTab === 2 ? "active" : ""}`}
            onClick={() => setCurrentTab(2)}
          >
            {generateIcon(cropsIcon, "Crops & Cuts", "Crop")}
          </div>
          */}

          {/*
          <div
            className={`tab ${currentTab === 5 ? "active" : ""}`}
            onClick={() => setCurrentTab(5)}
          >
            {generateIcon(framesIcon, "✨Variations", "✨✨Variations")}
          </div>
        */}
        </Box>
      </div>
    );
  };
  const RenderTabs = () => {
    let safeCategory =
      data !== null && data.category !== undefined && data.category !== null
        ? data.category
        : "Gurus";

    return (
      <div className="body">
        <div className="tabs">
          <div
            className={`tab ${currentTab === 0 ? "active" : ""}`}
            onClick={() => setCurrentTab(0)}
          >
            {generateIcon(framesIcon, "Generate Image", "✨Image")}
          </div>
          <div
            className={`tab ${currentTab === 1 ? "active" : ""}`}
            onClick={() => setCurrentTab(1)}
          >
            {generateIcon(agentIcon, "✨Perform an Agentic Task", "✨Agents")}
          </div>
          <div
            className={`tab ${currentTab === 2 ? "active" : ""}`}
            onClick={() => setCurrentTab(2)}
          >
            {generateIcon(
              agentIconGuru,
              "✨Perform a Category Specific Task",
              `✨${safeCategory}`
            )}
          </div>
          <div
            className={`tab ${currentTab === 3 ? "active" : ""}`}
            onClick={() => setCurrentTab(3)}
          >
            {generateIcon(filtersIcon, "filters", "Filters")}
          </div>
          <div
            className={`tab ${currentTab === 4 ? "active" : ""}`}
            onClick={() => setCurrentTab(4)}
          >
            {generateIcon(duotonesIcon, "duotone", "Duotones")}
          </div>

          <div
            className={`tab ${currentTab === 5 ? "active" : ""}`}
            onClick={() => setCurrentTab(5)}
          >
            {generateIcon(cropsIcon, "Crops & Cuts", "Crop")}
          </div>

          {/*
          <div
            className={`tab ${currentTab === 5 ? "active" : ""}`}
            onClick={() => setCurrentTab(5)}
          >
            {generateIcon(framesIcon, "✨Variations", "✨✨Variations")}
          </div>
        */}
        </div>
      </div>
    );
  };

  const filterClickHandler = (e) => {
    // LogSomething("filterClickHandler: ", e);
    alert("filterClickHandler: " + e);
  };

  const handleFilterRender = (filter, filterHandler) => {
    if (filter.bool === false) {
      filterHandler({
        ...filter,
        bool: true,
      });
      setGenEffect(filter.value);
      setInfocus(filter.inFocus);
    } else {
      if (filter.inFocus !== inFocus) {
        setGenEffect(filter.value);
        setInfocus(filter.inFocus);
      } else {
        filterHandler({
          ...filter,
          bool: false,
        });
        setGenEffect(0);
        setInfocus(null);
      }
    }
  };

  const SetupEffectBox = (data, renderValue, renderedHandler) => {
    return data && renderValue && renderValue.inFocus ? (
      <Box
        sx={{
          // allow box to be responsive -- grow/shrink padding as needed
          width: "100%",
          maxWidth: "320px",
          backgroundColor: "white",
          border: `${renderValue.inFocus === inFocus ? "4px" : "2px"} solid ${
            renderValue.bool ? "#ac3b3a" : "#ccc"
          }`,
          borderRadius: "5px",
        }}
        onClick={() => handleFilterRender(renderValue, renderedHandler)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CanvasFilters
            baseImageUrl={data.public_url_thumbnail}
            filterType={renderValue.inFocus}
            filterAmount={renderValue.value}
            width={data.width}
            height={data.height}
          />
          <Typography
            style={{
              fontWeight: "500",
              fontSize: "14px",
              textAlign: "center",
              fontFamily: "Raleway",
              marginTop: "10px",
              color: renderValue.bool ? "#ac3b3a" : "#ccc",
            }}
          >
            {renderValue.inFocus.replaceAll("-", " ")}&nbsp;({renderValue.value}
            )
          </Typography>
        </div>
      </Box>
    ) : (
      <div></div>
    );
  };

  const SetupQuadBox = (title, url, index) => {
    //LogSomething("SetupQuadBox: ", title, url);
    return url ? (
      <CardMedia
        sx={{
          opacity: url ? "1" : "0.5",
        }}
        id={`quadImage-` + index}
        component="img"
        image={url ? url : data.public_url_thumbnail}
        title={
          url === data.public_url_thumbnail
            ? "Agree to terms to generate image"
            : title
        }
        alt={
          url === data.public_url_thumbnail
            ? "Agree to terms to generate image"
            : title
        }
        onClick={
          url !== data.public_url_thumbnail
            ? () => {
                window.open(url);
              }
            : null
        }
      />
    ) : (
      <CardMedia
        sx={{
          opacity: url ? "1" : "0.5",
        }}
        id={`quadImage-` + index}
        component="img"
        image={url ? url : data.public_url_thumbnail}
        title={
          url === data.public_url_thumbnail
            ? "Agree to terms to generate image"
            : title
        }
        alt={
          url === data.public_url_thumbnail
            ? "Agree to terms to generate image"
            : title
        }
        /*
      onClick={
        url !== data.public_url_thumbnail
          ? () => {
              window.open(url);
            }
          : null
      }*/
      />
    );
  };

  const SetupStoryBox = (title, url) => {
    // LogSomething("SetupStoryBox: ", title, url);
    return url ? (
      <Box
        sx={{
          display: "grid",
          gridGap: 0.2,
          padding: 1,
          opacity: url ? "1" : "0.5",
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "5px",
          marginBottom: "10px",
          width: "35%",
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          Title:
        </Typography>
        <Typography variant="body2">{data.title}</Typography>
        <br />
        <img
          style={{
            width: "100%",
            padding: "2px",
            border: "1px solid primary",
            borderRadius: "5px",
            opacity: url ? "1" : "0.5",
          }}
          border="1px solid primary"
          borderRadius="5px"
          id="storyImage"
          src={url ? url : data.public_url_thumbnail}
          title={url ? title : "No Image"}
          alt={url ? title : "Agree to terms to generate image"}
          onClick={() => {
            window.open(url);
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            padding: 1,
          }}
        >
          <br />
          <div className="row">{RenderCategory(data)}</div>
        </Box>
      </Box>
    ) : (
      <Box
        style={{
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "5px",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          style={{
            textAlign: "center",
            fontFamily: "Raleway",
            fontWeight: "300",
            padding: "8px",
          }}
        >
          {title}
        </Typography>
      </Box>
    );
  };
  const RenderAgentStoryteller = (category) => {
    return RenderAgentBlock(agentIconWriter, "Storyteller Agents", [
      /*
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Short Story"
          : "Generate - agree to terms to proceed",
        "short story"
      ),
      */
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1024,
        1024,
        acceptTerms ? "Gen a story" : "Generate - agree to terms to proceed",
        "Story"
      ),
      /*
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_2048,
        2048,
        acceptTerms ? "Gen Long Story" : "Generate - agree to terms to proceed",
        "long story"
      ),
      */
    ]);
  };

  const RenderAgentsMarketing = (category) => {
    return RenderAgentBlock(agentIconWriter, "Marketing Agents", [
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_256,
        256,
        acceptTerms ? "Gen Short Post" : "Generate - agree to terms to proceed",
        "social media"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms ? "Gen Post" : "Generate - agree to terms to proceed",
        "social media"
      ),
      /*
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1024,
        1024,
        acceptTerms ? "Gen Long Post" : "Generate - agree to terms to proceed",
        "social media"
      ),
      */
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_768,
        768,
        acceptTerms
          ? "Gen Press Release"
          : "Generate - agree to terms to proceed",
        "press release"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1024,
        1024,
        acceptTerms ? "Gen Newsletter" : "Generate - agree to terms to proceed",
        "newsletter"
      ),
      /*
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Website Copy"
          : "Generate - agree to terms to proceed",
        "website copy"
      ),
      */
    ]);
  };

  const RenderAgentFantasyScifi = () => {
    return RenderAgentBlock(agentIconScifi, "Scifi/Fantasy Guru", [
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1536,
        1536,
        acceptTerms ? "Gen a World" : "Generate - agree to terms to proceed",
        "World Building"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen a Character"
          : "Generate - agree to terms to proceed",
        "Characters"
      ),
      /*
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1024,
        1024,
        acceptTerms
          ? "Gen Alien Species"
          : "Generate - agree to terms to proceed",
        "Aliens"
      ),*/
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms ? "Gen a Plot" : "Generate - agree to terms to proceed",
        "Plots"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1024,
        1024,
        acceptTerms
          ? "Gen a Battle Scene"
          : "Generate - agree to terms to proceed",
        "Battle Scene"
      ),
      /*
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen an Encounter"
          : "Generate - agree to terms to proceed",
        "Encounter"
      ),
      */
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms ? "Gen a Quest" : "Generate - agree to terms to proceed",
        "Quest"
      ),
    ]);
  };

  const RenderAgentFloral = () => {
    return RenderAgentBlock(agentIconScifi, "Florist Gurus", [
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1024,
        1024,
        acceptTerms
          ? "Gen an Arrangement"
          : "Generate - agree to terms to proceed",
        "Floral Arrangement"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1024,
        1024,
        acceptTerms
          ? "Gen a Garden Layout"
          : "Generate - agree to terms to proceed",
        "Garden"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1024,
        1024,
        acceptTerms
          ? "Gen a Hospitality Venue"
          : "Generate - agree to terms to proceed",
        "venue"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1024,
        1024,
        acceptTerms
          ? "Gen an Experience"
          : "Generate - agree to terms to proceed",
        "experience"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen a Research Profile"
          : "Generate - agree to terms to proceed",
        "research"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1024,
        1024,
        acceptTerms ? "Gen a Poem" : "Generate - agree to terms to proceed",
        "floral poem"
      ),
    ]);
  };

  const RenderAgentEnvironment = () => {
    /*Explanation
Parameters Added:

_environmentType: Specifies the type of environment (e.g., desert, rainforest, alien planet).
_geographicalFeatures: Highlights specific geographical features (e.g., mountains, rivers).
_floraAndFauna: Describes plant and animal life present.
_climate: Indicates the climate conditions.
_culturalSignificance: Incorporates cultural or historical significance.
_scientificFocus: Emphasizes a scientific aspect (e.g., geology, ecology).
_mood: Guides the emotional tone of the description.
_intendedAudience: Tailors the content to a specific audience (e.g., scientists, general public).
Detailed Objectives:

Cover essential aspects such as visual description, environmental elements, flora and fauna, climate, cultural significance, scientific insights, and emotional impact.
System Prompt:

Constructs a detailed prompt incorporating parameters and providing comprehensive guidelines.
Parsing Method:

The ParseSpecializedOutput method can be implemented as needed to process the agent's response.
2. Specialized Derived Classes
Overview
Here are several derived classes representing different areas within environmental and alien landscapes:

EnvironmentalLandscapeAgent
AlienLandscapeAgent
ExoplanetDescriptionAgent
GeologicalFormationAgent
EcologicalHabitatAgent
PlanetaryExplorationAgent
CulturalLandscapeAgent */

    return RenderAgentBlock(agentIconChef, "Environment Gurus", [
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Alien Landscape"
          : "Generate - agree to terms to proceed",
        "Alien Landscape"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Environmental Landscape"
          : "Generate - agree to terms to proceed",
        "Environmental Landscape"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Exoplanet Profile"
          : "Generate - agree to terms to proceed",
        "Exoplanet Profile"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Geological Profile"
          : "Generate - agree to terms to proceed",
        "Geological Profile"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms ? "Gen Habitat" : "Generate - agree to terms to proceed",
        "Habitat"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Planetary Exploration"
          : "Generate - agree to terms to proceed",
        "Planetary Exploration"
      ),
      /*,
        GenerateStoryButtonOptions(
          SMARTAI_PRODUCT_TEXTGEN_512,
          512,
          acceptTerms ? "Gen Cultural Landscape" : "Generate - agree to terms to proceed",
          "Cultural Landscape"
        ) */
    ]);
  };

  const RenderAgentBlock = (agentIcon, title, agentButtons) => {
    return (
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          padding: 1,
        }}
      >
        {
          // display agentIcon
          <img
            style={{
              width: "30%",
              padding: "2px",
              border: "1px solid primary",
              borderRadius: "5px",
              opacity: 1,
            }}
            border="1px solid primary"
            borderRadius="5px"
            id="agentImage"
            src={agentIcon}
            onClick={() => {
              window.open(agentIcon);
            }}
            title={title}
            alt={title}
          />
        }
        <Typography
          variant="h6"
          color="text.secondary"
          style={{
            textAlign: "center",
            fontFamily: "Raleway",
            fontWeight: "600",
          }}
        >
          {title}
        </Typography>
        {agentButtons}
      </Paper>
    );
  };

  const RenderAgentCharacters = () => {
    return RenderAgentBlock(agentIconCinema, "Character Gurus", [
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Char. Profile"
          : "Generate - agree to terms to proceed",
        "Profile"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms ? "Gen Backstory" : "Generate - agree to terms to proceed",
        "Backstory"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Character Arc"
          : "Generate - agree to terms to proceed",
        "character arc"
      ),
    ]);
  };

  const RenderAgentComics = () => {
    return RenderAgentBlock(agentIconScifi, "Comic Book Guru", [
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_768,
        768,
        acceptTerms
          ? "Gen Comic Script"
          : "Generate - agree to terms to proceed",
        "Comic Script"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms ? "Gen Comic Plot" : "Generate - agree to terms to proceed",
        "Comic Plot"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1024,
        1024,
        acceptTerms ? "Gen Comic Page" : "Generate - agree to terms to proceed",
        "Comic Page"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1024,
        1024,
        acceptTerms
          ? "Gen Comic Dialogue"
          : "Generate - agree to terms to proceed",
        "Dialogue"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_2048,
        2048,
        acceptTerms ? "Gen Comic Book" : "Generate - agree to terms to proceed",
        "Full Comic Book"
      ),
    ]);
  };

  const RenderAgentCulinary = () => {
    return RenderAgentBlock(agentIconScifi, "Culinary Guru", [
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms ? "Gen Recipe" : "Generate - agree to terms to proceed",
        "Recipe"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms ? "Gen a Pastry" : "Generate - agree to terms to proceed",
        "Pastry"
      ),
    ]);
  };

  const RenderAgentInteriorDesign = () => {
    /* Explanation
Parameters Added:

_designStyle: Specifies the design style (e.g., modern, rustic, minimalist).
_targetAudience: Defines the intended audience (e.g., families, young professionals).
_spaceType: Indicates the type of space (e.g., living room, office, restaurant).
_colorPalette: Provides the colors to incorporate.
_materials: Specifies materials to use (e.g., wood, metal, fabrics).
_culturalInfluences: Includes cultural inspirations.
_sustainabilityFocus: Emphasizes eco-friendly aspects.
_brandIdentity: Aligns designs with a specific brand.
Detailed Objectives:

Focus on creative concept development, spatial planning, audience alignment, aesthetic harmony, functionality, cultural sensitivity, sustainability, brand consistency, technical accuracy, and presentation quality.
System Prompt:

Constructs a detailed prompt incorporating parameters and providing comprehensive guidelines.
Parsing Method:

The ParseSpecializedOutput method can be implemented as needed to process the agent's response.
2. Specialized Derived Classes
Overview
Here are several derived classes representing different areas within interior design:

ResidentialDesignAgent
CommercialDesignAgent
HospitalityDesignAgent
SustainableDesignAgent
InteriorStylingAgent
FurnitureDesignAgent
LightingDesignAgent
*/

    return RenderAgentBlock(
      agentIconInteriorDesigner,
      "Interior Design Gurus",
      [
        GenerateStoryButtonOptions(
          SMARTAI_PRODUCT_TEXTGEN_512,
          512,
          acceptTerms
            ? "Gen Residential Design"
            : "Generate - agree to terms to proceed",
          "Residential Design"
        ),
        GenerateStoryButtonOptions(
          SMARTAI_PRODUCT_TEXTGEN_512,
          512,
          acceptTerms
            ? "Gen Commercial Design"
            : "Generate - agree to terms to proceed",
          "Commercial Design"
        ),
        GenerateStoryButtonOptions(
          SMARTAI_PRODUCT_TEXTGEN_512,
          512,
          acceptTerms
            ? "Gen Hospitality Design"
            : "Generate - agree to terms to proceed",
          "Hospitality Design"
        ),
        GenerateStoryButtonOptions(
          SMARTAI_PRODUCT_TEXTGEN_512,
          512,
          acceptTerms
            ? "Gen Sustainable Design"
            : "Generate - agree to terms to proceed",
          "Sustainable Design"
        ),
        GenerateStoryButtonOptions(
          SMARTAI_PRODUCT_TEXTGEN_512,
          512,
          acceptTerms
            ? "Gen Interior Styling"
            : "Generate - agree to terms to proceed",
          "Interior Styling"
        ),
        GenerateStoryButtonOptions(
          SMARTAI_PRODUCT_TEXTGEN_512,
          512,
          acceptTerms
            ? "Gen Furniture Design"
            : "Generate - agree to terms to proceed",
          "Furniture Design"
        ),
        GenerateStoryButtonOptions(
          SMARTAI_PRODUCT_TEXTGEN_512,
          512,
          acceptTerms
            ? "Gen Lighting Design"
            : "Generate - agree to terms to proceed",
          "Lighting Design"
        ),
      ]
    );
  };

  const RenderAgentFashionDesign = () => {
    /* Parameters Added:

_designStyle: Specifies the design style (e.g., avant-garde, minimalist, bohemian).
_targetAudience: Defines the intended audience (e.g., women aged 25-35, unisex).
_season: Indicates the season (e.g., Spring/Summer, Fall/Winter).
_colorPalette: Provides the colors to incorporate.
_materials: Specifies materials or fabrics to use.
_culturalInfluences: Includes cultural inspirations.
_sustainabilityFocus: Emphasizes eco-friendly aspects.
_brandIdentity: Aligns designs with a specific brand.
Detailed Objectives:

Focus on creative concept development, trend awareness, audience alignment, aesthetic harmony, functionality, cultural sensitivity, sustainability, brand consistency, technical accuracy, and presentation quality.
System Prompt:

Constructs a detailed prompt incorporating parameters and providing comprehensive guidelines.
Parsing Method:

The ParseSpecializedOutput method can be implemented as needed to process the agent's response.
2. Specialized Derived Classes
Overview
Here are several derived classes representing different areas within fashion design:

ApparelDesignAgent
AccessoryDesignAgent
FashionTrendAnalysisAgent
FashionMarketingAgent
TextilePatternAgent
FashionIllustrationAgent
SustainableFashionAgent
*/

    return RenderAgentBlock(agentIconFashion, "Fashion Guru", [
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms ? "Gen Design" : "Generate - agree to terms to proceed",
        "Apparel Design"
      ),
      /*
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Accessory Design"
          : "Generate - agree to terms to proceed",
        "Accessory Design"
      ),
      */
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Fashion Trend"
          : "Generate - agree to terms to proceed",
        "Fashion Trend"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms ? "Gen Fashion Ad" : "Generate - agree to terms to proceed",
        "Fashion Marketing"
      ),
      /*
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Textile Pattern"
          : "Generate - agree to terms to proceed",
        "Textile Pattern"
      ),
      */
      /*
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Fashion Illustration"
          : "Generate - agree to terms to proceed",
        "Fashion Illustration"
      ),*/
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Sustainable Option"
          : "Generate - agree to terms to proceed",
        "Sustainable Fashion"
      ),
    ]);
  };

  const RenderAgentMaterials = () => {
    return RenderAgentBlock(agentIconScifi, "Materials Guru", [
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms ? "Gen Swatch" : "Generate - agree to terms to proceed",
        "Swatch"
      ),
    ]);
  };

  const RenderAgentTextures = () => {
    return RenderAgentBlock(agentIconScifi, "Textures Guru", [
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms ? "Gen Swatch" : "Generate - agree to terms to proceed",
        "Swatch"
      ),
    ]);
  };

  const RenderAgentHorror = () => {
    return RenderAgentBlock(agentIconScifi, "Horror Guru", [
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1024,
        1024,
        acceptTerms ? "Gen Horror Story" : "Generate - agree to terms to proceed",
        "Horror Story"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms ? "Gen Horror Scene" : "Generate - agree to terms to proceed",
        "Horror Scene"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms ? "Gen Horror Backstory" : "Generate - agree to terms to proceed",
        "Backstory"
      ),
    ]);
  };

  const RenderAgentNature = () => {
    return RenderAgentBlock(agentIconScifi, "Nature Guru", [
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1024,
        1024,
        acceptTerms
          ? "Gen Documentary"
          : "Generate - agree to terms to proceed",
        "Documentary"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Evolutionary History"
          : "Generate - agree to terms to proceed",
        "Evolution"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1536,
        1536,
        acceptTerms ? "Gen Research" : "Generate - agree to terms to proceed",
        "Research"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen ENV Profile"
          : "Generate - agree to terms to proceed",
        "Environment"
      ),
    ]);
  };

  const RenderAgentCinema = () => {
    return RenderAgentBlock(agentIconScifi, "Theater Guru", [
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_1024,
        1024,
        acceptTerms
          ? "Gen Movie Treatment"
          : "Generate - agree to terms to proceed",
        "Treatment"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Movie Trailer"
          : "Generate - agree to terms to proceed",
        "Movie Trailer"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms
          ? "Gen Cinema Scene"
          : "Generate - agree to terms to proceed",
        "Movie Scene"
      ),
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_2048,
        2048,
        acceptTerms
          ? "Gen Short Script"
          : "Generate - agree to terms to proceed",
        "Movie Script"
      ),
      /*
      GenerateStoryButtonOptions(
        SMARTAI_PRODUCT_TEXTGEN_512,
        512,
        acceptTerms ? "Gen Play" : "Generate - agree to terms to proceed",
        "Play"
      ),*/
    ]);
  };

  const RenderAgentCategory = (category) => {
    return category ? (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          //display: "grid", // setup a two column grid
          //gridTemplateColumns: "repeat(2, 1fr)",
          //gridGap: 0.2,
          padding: 1,
        }}
      >
        {category.toLowerCase() === "fantasy" ? RenderAgentFantasyScifi() : ""}
        {category.toLowerCase() === "science fiction"
          ? RenderAgentFantasyScifi()
          : ""}
        {category.toLowerCase() === "robots" ? RenderAgentFantasyScifi() : ""}
        {category.toLowerCase() === "cinema" ? RenderAgentCinema() : ""}
        {category.toLowerCase() === "culinary" ? RenderAgentCulinary() : ""}
        {category.toLowerCase() === "animals" ? RenderAgentNature() : ""}
        {category.toLowerCase() === "nature" ? RenderAgentNature() : ""}
        {category.toLowerCase() === "science" ? RenderAgentNature() : ""}
        {category.toLowerCase() === "environments"
          ? RenderAgentEnvironment()
          : ""}
        {category.toLowerCase() === "fashion" ? RenderAgentFashionDesign() : ""}
        {category.toLowerCase() === "interior design"
          ? RenderAgentInteriorDesign()
          : ""}
        {category.toLowerCase() === "materials" ? RenderAgentMaterials() : ""}
        {category.toLowerCase() === "textures" ? RenderAgentTextures() : ""}
        {category.toLowerCase() === "horror" ? RenderAgentHorror() : ""}
        
        {
          //category.toLowerCase() === "urban" ? RenderAgentUrban() : ""
        }
        {category.toLowerCase() === "floral arrangements"
          ? RenderAgentFloral()
          : ""}
        {category.toLowerCase() === "portraits" ? RenderAgentCharacters() : ""}
        {category.toLowerCase() === "characters" ? RenderAgentCharacters() : ""}
        {
          //category.toLowerCase() === "comics" ? SetupComicsBox() : ""
        }

        {category.toLowerCase() === "anime" ? RenderAgentCharacters() : ""}

        {
          //category.toLowerCase() === "architecture" ? RenderAgentArchitecture() : ""
        }
        {
          //category.toLowerCase() === "transportation" ? RenderAgentVehicles() : ""
        }
      </Box>
    ) : (
      <div></div>
    );
  };

  const RenderAgentCategory2 = (category) => {
    return category ? (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          //display: "grid", // setup a two column grid
          //gridTemplateColumns: "repeat(2, 1fr)",
          //gridGap: 0.2,
          padding: 1,
        }}
      >
        {category.toLowerCase() !== "cinema" ? RenderAgentCinema() : ""}
      </Box>
    ) : (
      <div></div>
    );
  };

  const SetupGurusBox = (category) => {
    //LogSomething("SetupGurusBox: ", category);
    return category ? (
      <Box
        sx={{
          display: "grid", // setup a two column grid
          gridTemplateColumns: "repeat(2, 1fr)",

          gridGap: 0.2,
          padding: 1,
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "5px",
          marginBottom: "10px",
          width: "100%",
        }}
      >
        {category.toLowerCase() !== "comics" ? (
          RenderAgentCategory(category)
        ) : (
          <Box />
        )}
        {RenderAgentCategory2(category)}
      </Box>
    ) : (
      <Box
        style={{
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "5px",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          style={{
            textAlign: "center",
            fontFamily: "Raleway",
            fontWeight: "300",
            padding: "8px",
          }}
        >
          {category}
        </Typography>
      </Box>
    );
  };
  const SetupAgentBox = (category) => {
    //LogSomething("SetupAgentBox: ", category);
    return category ? (
      <Box
        sx={{
          display: "grid", // setup a two column grid
          gridTemplateColumns: "repeat(2, 1fr)",

          gridGap: 0.2,
          padding: 1,
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "5px",
          marginBottom: "10px",
          width: "100%",
        }}
      >
        {RenderAgentStoryteller(category)}
        {RenderAgentsMarketing(category)}
      </Box>
    ) : (
      <Box
        style={{
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "5px",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          style={{
            textAlign: "center",
            fontFamily: "Raleway",
            fontWeight: "300",
            padding: "8px",
          }}
        >
          {category}
        </Typography>
      </Box>
    );
  };
  const SetupComicsBox = () => {
    LogSomething("SetupComicsBox: ENTERED");
    return (
      <Box
        sx={{
          display: "grid", // setup a two column grid
          gridTemplateColumns: "repeat(2, 1fr)",

          gridGap: 0.2,
          padding: 1,
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "5px",
          marginBottom: "10px",
          width: "100%",
        }}
      >
        {RenderAgentCharacters()}
        {RenderAgentComics()}
      </Box>
    );
  };

  const RenderImageQuad = (quad, originalUrl) => {
    //LogSomething("RenderImageQuad: ", originalUrl, "-", quad);
    /*
    if (quad) {
      for (var i = 0; i < 4; i++) 
        {
          quad.push(originalUrl);
        }
    }
    */
    //LogSomething("RenderImageQuad: ", quad);
    return quad ? (
      // create a canvas for each frame
      // frames are square, circular or other shapes
      // frames can be transparent or have a color
      // LogSomething("ImageCanvasFrames: ", data),
      // setup a two column grid
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridGap: 1,
          padding: 1,
          opacity: quad.length === 0 ? "0.5" : "1",
          backgroundColor: "white",
          border: "1px solid #ccc",
          borderRadius: "5px",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {
          //LogSomething("CURRENT IMAGE URLS " + JSON.stringify(genImages))
        }
        {
          //LogSomething("CURRENT QUAD " + JSON.stringify(quad))
        }

        {SetupQuadBox(data.title, quad.length > 0 ? quad[quad.length - 1] : "")}
        {SetupQuadBox(data.title, quad.length > 1 ? quad[quad.length - 2] : "")}
        {SetupQuadBox(data.title, quad.length > 2 ? quad[quad.length - 3] : "")}
        {SetupQuadBox(data.title, quad.length > 3 ? quad[quad.length - 4] : "")}
      </Box>
    ) : (
      <div></div>
    );
  };

  const ImageCanvasFrames = (data) => {
    return data ? (
      // create a canvas for each frame
      // frames are square, circular or other shapes
      // frames can be transparent or have a color
      (LogSomething("ImageCanvasFrames: ", data),
      (
        // setup a two column grid
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridGap: 10,
            padding: 3,
            paddingTop: 0,
            backgroundColor: "white",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {SetupEffectBox(data, effectBrightness, setEffectBrightness)}
          {SetupEffectBox(data, effectContrast, setEffectContrast)}
          {SetupEffectBox(data, effectSaturate, setEffectSaturate)}
          {SetupEffectBox(data, effectBlur, setEffectBlur)}
          {SetupEffectBox(data, effectGrayscale, setEffectGrayscale)}
          {SetupEffectBox(data, effectHueRotate, setEffectHueRotate)}
          {SetupEffectBox(data, effectSepia, setEffectSepia)}
          {SetupEffectBox(data, effectInvert, setEffectInvert)}
        </Box>
      ))
    ) : (
      <div></div>
    );
  };

  const EffectFilters = (data) => {
    return !browserName.includes("Safari") ? (
      <div>
        {/*
        <Typography
          variant="h6"
          color="text.secondary"
          style={{
            textAlign: "center",
            fontFamily: "Raleway",
            fontWeight: "bold",
            padding: "10px",
          }}
        >
          Image Effects
        </Typography>
        */}

        <Stack
          direction="row"
          justifyContent="center"
          gap="10px"
          sx={{
            padding: "10px",
          }}
        >
          <Button
            className="rounded-pill"
            variant="contained"
            sx={{
              textTransform: "none",
              fontSize: "1rem",
            }}
            disabled={currentStage === null}
            onClick={download}
          >
            Download Filtered Image
          </Button>
          <Button
            className="rounded-pill"
            variant="contained"
            sx={{
              textTransform: "none",
              fontSize: "1rem",
            }}
            disabled={currentStage === null}
            onClick={resetFilters}
          >
            Reset Filters
          </Button>
        </Stack>
        <Box
          sx={{
            padding: "24px 48px",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" color="text.secondary">
              Filter Intensity ({inFocus === null ? "none" : inFocus}){" "}
            </Typography>
            <InputBase
              type="number"
              sx={{
                border: "1px solid black",
                outline: "none",
                input: {
                  color: "black",
                  padding: "20px",
                  boxSizing: "border-box",
                  fontSize: "16px",
                  marginRight: "-20px",
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none!important",
                      margin: 0,
                    },
                  "&[type=number]": {
                    MozAppearance: "textfield!important",
                  },
                },
              }}
              value={genEffect}
              onChange={(event) => {
                const value = parseInt(event.target.value, 10);
                if (value > 100) {
                  setGenEffect(100);
                } else if (value < 0) {
                  setGenEffect(0);
                } else {
                  setGenEffect(value);
                }
              }}
              inputProps={{ min: 0, max: 100 }}
            />
          </Stack>
          <Slider
            value={genEffect}
            onChange={handleGeneralSliderChange}
            min={0}
            max={100}
            defaultValue={1}
            step={1}
            aria-labelledby="effect-slider"
            valueLabelDisplay="auto"
          />
          <Typography
            variant="body2"
            color="text.secondary"
            style={{
              textAlign: "center",
              fontFamily: "Raleway",
              fontWeight: "300",
              fontStyle: "italic",
              padding: "10px",
            }}
          >
            NOTE: the filters are applied together as a composite filter. <br />
            Adjust the intensity of a single filter by selecting it and moving
            the slider.
          </Typography>
        </Box>
        {ImageCanvasFrames(data)}
      </div>
    ) : (
      <div>
        {renderWarningMessage(
          "Image filters not currently supported on Safari browsers - try our Duotone Filters!",
          true
        )}
      </div>
    );
  };

  const RenderMaterials = (data) => {
    return (
      <Box>
        <div className="d-flex flex-row flex-wrap">
          {data.tags
            .filter((tag) => {
              return tag.split(":")[0] === "materials" && tag.includes(":");
            })
            .map((tag, index) => {
              return (
                <Chip
                  key={`selected__key__${index}`}
                  sx={{ margin: 0.2 }}
                  label={
                    <Typography
                      sx={{
                        margin: 1,
                        fontFamily: "Raleway",
                        fontSize: 12,
                      }}
                    >
                      {tag.split(":")[1]}
                    </Typography>
                  }
                />
              );
            })}
        </div>
      </Box>
    );
  };
  const RenderOtherTags = (data) => {
    return (
      <Box>
        <div className="d-flex flex-row flex-wrap">
          {data.tags
            .filter((tag) => {
              return (
                tag.split(":")[0] !== "color" &&
                tag.split(":")[0] !== "topic" &&
                tag.split(":")[0] !== "category" &&
                tag.split(":")[0] !== "materials" &&
                tag.split(":")[0] !== "resolution" &&
                tag.split(":")[0] !== "width" &&
                tag.split(":")[0] !== "height" &&
                tag.includes(":")
              );
            })
            .map((tag, index) => {
              return (
                <Chip
                  key={`selected__key__${index}`}
                  sx={{ margin: 0.2 }}
                  label={
                    <Typography
                      sx={{
                        margin: 1,
                        fontFamily: "Raleway",
                        fontSize: 12,
                      }}
                    >
                      {tag.split(":")[1]}
                    </Typography>
                  }
                />
              );
            })}
        </div>
      </Box>
    );
  };

  const RenderColors = (data) => {
    return (
      <Box>
        <div className="d-flex flex-row flex-wrap">
          {userTags
            .filter((tag) => {
              return tag.split(":")[0] === "color" && tag.includes(":");
            })
            .map((tag, index) => {
              return (
                // show color chip with color name and color
                // also add remove icon on chip to remove color
                // also show color picker

                <Chip
                  key={`selected__key__${index}`}
                  sx={{ margin: 0.2 }}
                  icon={
                    <IconButton
                      onClick={() => {
                        LogSomething("Remove Color: ", tag);
                        var removeTags = userTags.filter((t) => {
                          return t !== tag;
                        });
                        setUserTags(removeTags);
                      }}
                      className="fas fa-times-circle"
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        margin: 1,
                        fontFamily: "Raleway",
                        fontSize: 12,
                      }}
                    >
                      {tag.split(":")[1]}
                    </Typography>
                  }
                />
              );
            })}
        </div>
      </Box>
    );
  };

  const ShowSubscriptionInfo = () => {
    return (
      <Box
        className="image__checkout"
        sx={{
          bgColor: "#f5f5f5",
          width: "100%",
        }}
      >
        <div className="mt-2 fw-bold mb-2">
          <i className="fa-solid fa-coins"></i>
          <span className="ms-2">
            Monthly Credits Balance: {getSubscriptionCreditsRemaining()}
          </span>
          <br />
          <span className="ms-2">
            Wallet Credits: {getWalletCreditsRemaining()}
          </span>
        </div>
      </Box>
    );
  };

  const ThreeOptionToggleStyle = (
    label,
    option1,
    option2,
    option3,
    value,
    handlerChangeValue
  ) => {
    //LogSomething("ThreeOptionToggleStyle: ", label, option1, option2, option3);
    //LogSomething("ThreeOptionToggleStyle: current:", imageGenStyle);
    return (
      // if mobile, show radio buttons in a column
      // if desktop, show radio buttons in a row

      <Paper
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)", // on extra small (xs) screens, display 1 column
            sm: "repeat(2, 1fr)", // on small (sm) screens, display 2 columns
            md: "repeat(3, 1fr)", // on medium (md) screens and up, display 4 columns
          },
          justifyContent: "center",
          alignItems: "center",
          padding: "5px",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: "Raleway",
            textAlign: "center",
            paddingRight: { xs: "0px", sm: "10px", md: "20px" },
          }}
        >
          {
            //label
          }
        </Typography>
        <ToggleButtonGroup
          sx={{
            fontSize: "10px",
            fontWeight: "400",
            fontFamily: "Raleway",
          }}
          aria-label="style"
          name="style"
          value={value}
          exclusive
          onChange={(event, newValue) => {
            handlerChangeValue(newValue);
          }}
        >
          <ThemeProvider theme={studioTheme}>
            <ToggleButton
              sx={{
                width: "80px",
              }}
              value={option1}
              backgroundcolor={
                value !== undefined
                  ? option1 === value
                    ? "primary"
                    : "default"
                  : "default"
              }
            >
              <Typography sx={{ fontSize: ".8rem" }}>{option1}</Typography>
            </ToggleButton>
          </ThemeProvider>
          <ThemeProvider theme={studioTheme}>
            <ToggleButton
              value={option2}
              backgroundcolor={
                imageGenStyle !== undefined
                  ? option2 === value
                    ? "primary"
                    : "default"
                  : "default"
              }
            >
              <Typography sx={{ fontSize: ".8rem" }}>{option2}</Typography>
            </ToggleButton>
          </ThemeProvider>
          <ThemeProvider theme={studioTheme}>
            <ToggleButton
              value={option3}
              backgroundcolor={
                value !== undefined
                  ? option3 === value
                    ? "primary"
                    : "default"
                  : "default"
              }
            >
              <Typography sx={{ fontSize: ".8rem" }}>{option3}</Typography>
            </ToggleButton>
          </ThemeProvider>
        </ToggleButtonGroup>
      </Paper>
    );
  };

  const NOptionToggleStyle = (
    label,
    options,
    value,
    handlerChangeValue,
    sort = false
  ) => {
    //LogSomething("ThreeOptionToggleStyle: ", label, options, value);
    //LogSomething("ThreeOptionToggleStyle: current:", imageGenStyle);

    // process sort
    if (sort) {
      options.sort();
    }

    return (
      // if mobile, show radio buttons in a column
      // if desktop, show radio buttons in a row

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(2, 1fr)", // on extra small (xs) screens, display 1 column
            sm: "repeat(3, 1fr)", // on small (sm) screens, display 2 columns
            md: "repeat(4, 1fr)", // on medium (md) screens, display 4 columns
            // on large screens and up, display 5 columns
            lg: "repeat(5, 1fr)",
          },

          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          width: "100%",

          border: "1px solid #183E4B",
        }}
      >
        <Box
          sx={{
            padding: "8px",
            backgroundColor: "#white",
            border: "1px solid #darkgray",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              fontFamily: "Raleway",
              textAlign: "center",
              color: "#183E4B",
            }}
          >
            {label}
          </Typography>
        </Box>

        <ToggleButtonGroup
          sx={{
            backgroundColor: "white",
            display: isMobile ? "grid" : "contents",
          }}
          aria-label="style"
          name="style"
          value={value}
          exclusive
          onChange={(event, newValue) => {
            handlerChangeValue(newValue);
          }}
        >
          {options.map((option, index) => {
            return (
              <ThemeProvider theme={studioTheme}>
                <ToggleButton
                  value={option}
                  backgroundcolor={
                    value !== undefined
                      ? option === value
                        ? "primary"
                        : "default"
                      : "default"
                  }
                >
                  <Typography sx={{ fontSize: ".8rem" }}>{option}</Typography>
                </ToggleButton>
              </ThemeProvider>
            );
          })}
        </ToggleButtonGroup>
      </Box>
    );
  };

  const NOptionsRadioStyle = (label, options, value, handlerChangeValue) => {
    const theme = useTheme();

    return (
      // if mobile, show radio buttons in a column
      // if desktop, show radio buttons in a row

      <Box>
        <Typography
          variant="h6"
          color="text.secondary"
          style={{
            textAlign: "center",
            fontFamily: "Raleway",
            fontWeight: "bold",
            padding: "10px",
          }}
        >
          {label}
        </Typography>

        <FormControl
          component="fieldset"
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)", // on extra small (xs) screens, display 1 column
              sm: "repeat(2, 1fr)", // on small (sm) screens, display 2 columns
              md: "repeat(3, 1fr)", // on medium (md) screens and up, display 4 columns
            },
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            textAlign: "center",
          }}
        >
          <RadioGroup
            className="row"
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              "& .MuiFormControlLabel-root": {
                color: "#333",
              },
              "& .MuiRadio-root": {
                color: "#333",
                "&.Mui-checked": {
                  color: "#4A90E2",
                },
              },
            }}
            aria-label="style"
            name="style"
            value={value}
            onChange={(event) => {
              handlerChangeValue(event.target.value);
            }}
          >
            {options.map((option, index) => {
              return (
                <FormControlLabel
                  sx={{
                    alignItems: "center",
                    padding: "1px",
                    textAlign: "center",
                    fontFamily: "Raleway",
                    fontSize: "10px",
                  }}
                  className="col"
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Box>
    );
  };

  const ImageGenOptions = () => {
    return (
      acceptTerms && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            className="row"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {NOptionToggleStyle(
              "AI Model",
              [
                //"SD XL (quad)",
                "SD MED (single)",
                "SD LARGE (single)",
                "FLUX (single)",
                "LUMA (single)",
              ], //photon-flash-1 photon-1 // "SD CORE"], //, "SD ULTRA",
              imageModel,
              setImageModel
            )}
          </Box>

          <Box
            className="row"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {NOptionToggleStyle(
              "Vary Strength",
              //[.6, .65, .7, .75, .8, .85, .9, .95],
              ["Low", "Medium", "High", "Max"],
              varyStrength,
              setVaryStrength
            )}
          </Box>
          {/*}
          <Box
            className="row"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            { 
              NOptionToggleStyle(
              "Aspect Ratio",
              ["1:1", "4:3", "3:2", "16:10", "16:9", "9:16", "10:16", "2:3", "3:4"],
              imageAspect,
              setImageAspect
            )}            
          </Box>
          */}
          <Box
            className="row"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {NOptionToggleStyle(
              "Seed Options",
              ["Original", "Sequential", "Random", "Custom"],
              seedOption,
              setSeedOption
            )}
          </Box>

          <Box
            className="row"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {/* ThreeOptionToggleStyle(
              "Style Options",
              "Realistic",
              "Illustration",
              "Cartoon",
              imageGenStyle,
              setImageGenStyle
            )}
            {ThreeOptionToggleStyle(
              "Modifiers",
              "Cyberpunk",
              "Surrealism",
              "Abstract",
              imageGenStyle2,
              setImageGenStyle2
            ) */}
            {NOptionToggleStyle(
              "Style Options",
              [
                "Realistic",
                "Illustration",
                "Cartoon",
                "Cyberpunk",
                "Surrealism",
                "Abstract",
                "Anime",
                "Comics",
                "Grunge",
                "Gothic",
                "Vintage",
              ],
              imageGenStyle,
              setImageGenStyle
            )}
            {/*NOptionToggleStyle(
              "Style Options II",
              [
                "Rustic",
                "Minimalist",
                "Futuristic",
                "Retro",
                "Urban",
                "Fantasy",
                "Sci-fi",
                "Horror",
                "Steampunk",
                "Masculine",
                "Feminine",
                "Androgynous",               
                "Polar",
                "Tropical",
                "Desert",
                "Mountain",
                "Plains",
                "Forest",
                "Jungle",
                "Sea",
              ],
              imageGenStyle2,
              setImageGenStyle2
            )*/}
          </Box>
          <Box
            className="row"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {NOptionToggleStyle(
              "Atmosphere",
              [
                "Ethereal",
                "Moody",
                "Vibrant",
                "Gritty",
                "Vintage",
                "Foggy",
                "Sunny",
                "Rainy",
                "Snowy",
                "Night",
                "Day",
                "Dusk",
                "Dawn",
              ],
              imageGenOptionAtmosphere,
              setImageGenOptionAtmosphere
            )}
          </Box>

          {AdvancedImageOptions()}
        </Box>
      )
    );
  };

  const RenderTagOptions = () => {
    return (
      <Box
        sx={{
          border: "1px solid #ccc",
        }}
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="body"
              color="text.secondary"
              style={{
                textAlign: "center",
              }}
            >
              Colors
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              <Box
                textAlign="center"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                {RenderColors(data)}
              </Box>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="body"
              color="text.secondary"
              style={{
                textAlign: "center",
              }}
            >
              Materials
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              <Box
                textAlign="center"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                {RenderMaterials(data)}
              </Box>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="body"
              color="text.secondary"
              style={{
                textAlign: "center",
              }}
            >
              Other Tags
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              <Box
                textAlign="center"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                {RenderOtherTags(data)}
              </Box>
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  const RenderCreditsButton = () => {
    return (
      <div className="bg-banner text-center ">
        <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
          <Button
            className="rounded-pill"
            variant="contained"
            onClick={() => {
              navigate("/credits");
            }}
          >
            View Credit Packs
          </Button>
        </div>
      </div>
    );
  };

  const startImageProgressTimer = () => {
    // start progress timer for image generation
    setProgressGenImage(0);
    const timer = setInterval(() => {
      setProgressGenImage((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        //const diff = Math.random() * 5;
        return Math.min(oldProgress + 4, 100);
      });
    }, 250);
    return () => { 
      clearInterval(timer);
      setProgressGenImage(0);
    }
  };

  const startTextProgressTimer = () => {
    // start progress timer for image generation
    setProgressGenText(0);
    const timer = setInterval(() => {
      setProgressGenText((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        //const diff = Math.random() * 5;
        return Math.min(oldProgress + 4, 100);
      });
    }, 500);
    return () => { 
      clearInterval(timer);
      setProgressGenText(0);
    }
  };

  const handleImageGen = async (useImage) => {
    LogSomething("handleImageGen: ", useImage);

    // start progress timer for image generation
    startImageProgressTimer();

    var seedNew = seed;
    if (seedOption.toLowerCase() === "random") {
      seedNew = randomizeSeed();
    } else if (seedOption.toLowerCase() === "original") {
      seedNew = data.seed;
    } else if (seedOption.toLowerCase() === "custom") {
      seedNew = seed;
    }

    // remove extra words from imageModel
    let cleaned = imageModel.replace(" (quad)", "");
    cleaned = cleaned.replace(" (single)", "");

    genImage(
      data,
      userTags,
      seedNew,
      includeTagsGen,
      imageGenStyle,
      imageGenStyle2,
      imageGenOptionAtmosphere,
      imageGenOptionAtmosphere2,
      imageCulturalOption,
      imageAspect,
      cleaned,
      useImage,
      varyStrength
    );
  };

  const RenderImageVaryButtons = () => {
    
    var enoughCredits =
      getSubscriptionCreditsRemaining() + getWalletCreditsRemaining() >=
      productsImageGenPrice;

    var modelTier = imageModel.includes("MED") || imageModel.includes("FLUX") ? "low" : "low";
    var price = DetermineImageCreditPrice(resolutionWidth, resolutionHeight, modelTier); 

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          padding: "10px",
        }}
      >
        <LoadingButton
            sx={{
              width: "100%",
              height: "100%",
              padding: "10px",
              marginBottom: "10px",
            }}
            variant="contained"
            loadingIndicator="Generating similar image..."
            loading={imageGenSimilar}
            disabled={!acceptTerms || imageGenLoading || imageGenSimilar || !enoughCredits}
            onClick={async () => {
              handleImageGen(false);
            }}
          >
            {enoughCredits
              ? `✨ Gen - Similar Image @ ${
                  productsImageGenPrice !== 0 ? productsImageGenPrice : price
                } credits`
              : acceptTerms
              ? `✨ AI Generate Variations - buy more credits`
              : `✨ AI Generate Variations - not enough credits to proceed`}
          </LoadingButton>

          <LoadingButton
            sx={{
              width: "100%",
              height: "100%",
              padding: "10px",
              marginBottom: "10px",
            }}
            loadingIndicator="Varying image..."
            loading={imageGenLoading}
            variant="contained"
            disabled={
              !acceptTerms ||
              imageGenLoading || imageGenSimilar ||
              !enoughCredits ||
              imageModel === "SD XL (quad)"
            }
            onClick={async () => {
              handleImageGen(true);
            }}
          >
          {enoughCredits
              ? `✨ Gen - Vary Image @ ${
                  productsImageGenPrice !== 0 ? productsImageGenPrice : price
                } credits`
              : acceptTerms
              ? `✨ AI Generate Variations - buy more credits`
              : `✨ AI Generate Variations - not enough credits to proceed`}
          </LoadingButton>
      </Box>
    );
  };
  

  const VaryStyles = (data) => {
    if (!data) {
      return null; // or loading state
    }
    /*
    var price = productsImageGenPrice;
    if (price === 0) {
      price = getImageGenPrice();
    }
      */

    var enoughCredits =
      getSubscriptionCreditsRemaining() + getWalletCreditsRemaining() >=
      productsImageGenPrice;

    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        {enoughCredits && inputTermsPrivacy}
        <Typography
                variant="body"
                color="text.secondary"
                style={{
                  textAlign: "center",
                  fontFamily: "Raleway",
                  fontWeight: "300",
                }}
              >
                All AI generated images are saved in 
                <Link href="/profile"> My AI Images</Link>
              </Typography>
        <Card
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            border: "1px solid #ccc",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          { RenderImageQuad(genImages, data.public_url) }

          {imageGenLoading || imageGenSimilar ? (
            <div
              style={{
                width: "100%",
                height: "250px",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
              }}
            >
            
              
              <Backdrop open={imageGenLoading || imageGenSimilar}>
                {
                   <CircularProgress 
                   variant="indeterminate" 
                   value={progressGenImage} 
                   style={{
                     width: "10%",
                     height: "10%",
                     display: "flex",
                     alignContent: "center",
                     alignItems: "center",
                     justifyContent: "center",
                   }}
                 >
                  
                 </CircularProgress>
                }
                
              </Backdrop>
            </div>
          ) : (
            <div></div>
            // </Card>
            //</Box>  <CardMedia
            //    className="img-responsive img-thumbnail"
            //    component="img"
            //    image={data.public_url}
            //    alt={data.description}
            //  />
          )}
        </Card>

        <Box
          className="text-center"
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            border: "1px solid #ccc",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          { RenderImageVaryButtons() }
           

          {/* enoughCredits && (
            <div>
              <LoadingButton
                sx={{
                  width: "100%",
                  height: "100%",
                  padding: "10px",
                  marginBottom: "10px",
                }}
                variant="contained"
                disabled={!acceptTerms || imageGenLoading || !enoughCredits}
                onClick={async () =>
                  genImage(
                    data,
                    userTags,
                    randomizeSeed(),
                    includeTagsGen,
                    imageGenStyle
                  )
                }
              >
                `✨ AI Generate Variations - random seed`
              </LoadingButton>
            </div>
          ) */}

          {/* enoughCredits && (
            <div>
              <LoadingButton
                sx={{
                  width: "100%",
                  height: "100%",
                  padding: "10px",
                  marginBottom: "10px",
                }}
                variant="contained"
                disabled={!acceptTerms || imageGenLoading || !enoughCredits}
                onClick={async () =>
                  genImage(data, userTags, seed, includeTagsGen, imageGenStyle)
                }
              >
                `✨ AI Generate Variations - custom seed`
              </LoadingButton>
            </div>
          ) */}

          {enoughCredits && (
            <>
              <Typography
                variant="body"
                color="text.secondary"
                style={{
                  textAlign: "center",
                  fontFamily: "Raleway",
                  fontWeight: "300",
                  padding: "10px",
                }}
              >
                NOTE: The AI will generate variations of the image based on the
                seed value, style selected and whether tags are included. <br />
                Results may widely vary.
              </Typography>
            </>
          )}
          {ImageGenOptions()}

          {ShowSubscriptionInfo()}
          {!enoughCredits && RenderCreditsButton()}
        </Box>
        {
          //RenderTagOptions()
        }
      </Box>
    );
  };

  const CropCuts = (data) => {
    var cropCoordinates = { x: 100, y: 100, width: 200, height: 200 };
    LogSomething("CropCuts: ", data);
    return !browserName.includes("Safari") ? (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          border: "1px solid #ccc",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CropComponent imgSrc={localImageUrl ?? data.public_url} />
      </Box>
    ) : (
      <div>
        {renderWarningMessage(
          "Image cropping not currently supported on Safari browsers",
          true
        )}
      </div>
    );
  };

  const FormatText = (text) => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          border: "1px solid #ccc",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <Typography
          variant="body"
          color="text.secondary"
          style={{
            textAlign: "center",
          }}
        >
          {text}
        </Typography>
      </Box>
    );
  };

  const getNumCreditsPerStory = (productId, numTokens) => {
    if (productsTextGen) {
      // get product
      let productLocal = productsTextGen.find((p) => p.productId === productId);
      //LogSomething("getNumCreditsPerStory: ", productLocal);
      if (productLocal && productLocal.creditsPrice > 0) {
        numCredits = productLocal.creditsPrice;
      }
    }

    if (numCredits <= 0) {
      var numCredits = 1;
      if (numTokens <= 1) numCredits = 0;
      else if (numTokens <= 128) numCredits = 1;
      else if (numTokens <= 256) numCredits = 2;
      else if (numTokens <= 512) numCredits = 3;
      else if (numTokens <= 768) numCredits = 4;
      else if (numTokens <= 1024) numCredits = 5;
    }
    return numCredits;
  };

  const GenerateStoryButtonOptions = (
    productId,
    numTokens,
    buttonText,
    storyType
  ) => {
    var _textGenPrice = getNumCreditsPerStory(productId, numTokens);
    var enoughCredits =
      getSubscriptionCreditsRemaining() + getWalletCreditsRemaining() >=
      _textGenPrice;

    return (
      <LoadingButton
        sx={{
          width: "100%",
          height: "100%",
          padding: "10px",
          marginTop: "4px",
          marginBottom: "4px",
        }}
        variant="contained"
        loadingIndicator="Generating..."
        loading={storyLoading}
        disabled={!acceptTerms || storyLoading || !enoughCredits} // || (subscription.subscriptionTierId === "1")}
        onClick={async () =>
          GenerateTextFromImage(
            productId,
            data,
            storyType,
            numTokens,
            includeTagsGen
          )
        }
      >
        ✨
        {enoughCredits
          ? buttonText + " (" + _textGenPrice + ")"
          : buttonText + " - buy more credits"}
      </LoadingButton>
    );
  };

  const GeneratePostButtonOptions = (
    productId,
    postType,
    numTokens,
    buttonText,
    genType
  ) => {
    var _textGenPrice = getNumCreditsPerStory(productId, numTokens);
    var enoughCredits =
      getSubscriptionCreditsRemaining() + getWalletCreditsRemaining() >=
      _textGenPrice;

    return (
      <LoadingButton
        sx={{
          width: "100%",
          height: "100%",
          padding: "10px",
          marginBottom: "10px",
        }}
        variant="contained"
        loadingIndicator="Generating..."
        loading={storyLoading}
        disabled={!acceptTerms || storyLoading || !enoughCredits} // || (subscription.subscriptionTierId === "1")}
        onClick={async () =>
          GenerateTextFromImage(
            productId,
            data,
            postType,
            numTokens,
            agentPov1,
            [agentTone1],
            [agentMood1],
            [agentAtmosphere1],
            includeTagsGen
          )
        }
      >
        ✨{enoughCredits ? buttonText : buttonText + " - need more credits"}
      </LoadingButton>
    );
  };

  const textGenerationFiltersPostType = () => {
    var genres = getGenres();
    var themes = getThemes();
    var atmospheric = getAtmospheric();
    var postTypes = getPostTypes();

    return data && data.category ? (
      <div
        className="row"
        style={{
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          marginBottom: "10px",
        }}
      >
        <div className="col-md-4">
          <Stack>
            <Typography
              variant="body"
              color="text.secondary"
              style={{
                textAlign: "center",
                padding: "10px",
              }}
            >
              Type:
            </Typography>
            <Select
              value={selectedTheme1}
              onChange={handleThemeChange1}
              inputProps={{ "aria-label": "Without label" }}
            >
              {postTypes.map((theme, index) => (
                <MenuItem key={index} value={theme}>
                  {theme}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </div>

        <div
          className="col-md-4"
          style={{
            textAlign: "center",
          }}
        >
          <Stack>
            <Typography
              variant="body"
              color="text.secondary"
              style={{
                textAlign: "center",
                padding: "10px",
              }}
            >
              Moods
            </Typography>
            <Select
              value={selectedTheme2}
              onChange={handleThemeChange2}
              renderValue={(value) =>
                value === undefined ? "mood" : `${value}`
              }
              inputProps={{ "aria-label": "Without label" }}
            >
              {themes.map((theme, index) => (
                <MenuItem key={index} value={theme}>
                  {theme}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </div>

        <div
          className="col-md-4"
          style={{
            textAlign: "center",
          }}
        >
          <Stack>
            <Typography
              variant="body"
              color="text.secondary"
              style={{
                textAlign: "center",
                padding: "10px",
              }}
            >
              Atmopshere
            </Typography>
            <Select
              value={selectedTheme3}
              onChange={handleThemeChange3}
              renderValue={(value) =>
                value === undefined ? "atmosphere" : `${value}`
              }
              inputProps={{ "aria-label": "Without label" }}
            >
              {atmospheric.map((theme, index) => (
                <MenuItem key={index} value={theme}>
                  {theme}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </div>
      </div>
    ) : (
      <div></div>
    );
  };

  const getDesignTypes = () => {
    var designTypes = [
      "Concept",
      "Mood Board",
      "Design Review",
      "Design Tips",
      "Design Trends",
      "Design Inspiration",
      "Design Guide",
    ];
    return designTypes;
  };

  const getDesignThemes = () => {
    var designThemes = [
      "Modern",
      "Retro",
      "Vintage",
      "Minimalist",
      "Futuristic",
      "Classic",
      "Elegant",
      "Luxury",
      "Industrial",
      "Scandinavian",
      "Bohemian",
      "Eclectic",
      "Coastal",
      "Farmhouse",
      "Rustic",
      "Traditional",
      "Transitional",
      "Contemporary",
      "Mid-Century Modern",
      "Shabby Chic",
      "French Country",
      "Tropical",
      "Asian",
      "Moroccan",
      "Mediterranean",
      "Tuscan",
      "Cottage",
      "Beach",
      "Southwestern",
      "Victorian",
      "Art Deco",
      "Gothic",
      "Baroque",
      "Rococo",
      "Renaissance",
      "Colonial",
      "Craftsman",
      "Mission",
      "Prairie",
      "Art Nouveau",
      "Bauhaus",
      "De Stijl",
      "International Style",
      "Postmodern",
      "Brutalist",
      "High-Tech",
      "Organic",
      "Biophilic",
      "Sustainable",
      "Green",
      "Eco-Friendly",
      "Smart",
      "Healthy",
      "Wellness",
      "Holistic",
      "Zen",
      "Feng Shui",
      "Vastu",
    ];
    return designThemes;
  };

  const getCharacterTypes = () => {
    var characterTypes = [
      "Protagonist",
      "Antagonist",
      "Sidekick",
      "Mentor",
      "Family",
      "Friend",
      "Enemy",
      "Hero",
      "Villain",
    ];
    return characterTypes;
  };

  const getCharacterAtmoshperes = () => {
    var characterAtmospheres = [
      "Tragic",
      "Happy",
      "Sad",
      "Angry",
      "Exciting",
      "Peaceful",
      "Mysterious",
      "Dangerous",
      "Romantic",
      "Epic",
      "Funny",
      "Heartwarming",
      "Heartbreaking",
      "Inspiring",
      "Motivating",
      "Challenging",
      "Frightening",
      "Thrilling",
      "Suspenseful",
      "Intense",
      "Relaxing",
      "Soothing",
      "Uplifting",
      "Hopeful",
      "Empowering",
    ];
    return characterAtmospheres.sort();
  };

  const textGenerationFiltersByCategory = () => {
    //var themes = getThemes();
    //var atmospheric = getAtmospheric();
    //var moods = getMoods();

    if (data && data.category) {
      var genres = getGenres();
      var themes = getThemes();
      var atmospheric = getAtmospheric();
      var postTypes = getPostTypes();

      var theme1Label = "Genres";
      var theme2Label = "Moods";
      var theme3Label = "Atmosphere";

      if (data.category === "Culinary") {
        postTypes = ["Recipe"];
        themes = ["Breakfast", "Lunch", "Dinner", "Dessert"];
        atmospheric = ["Sweet", "Savory", "Spicy", "Sour", "Extra spicy"];
        theme1Label = "Recipe Type";
        theme2Label = "Meal Type";
        theme3Label = "Flavor Profile";
      } else if (data.category === "Characters") {
        postTypes = [
          "Hero's Journey",
          "Backstory",
          "Life Altering Events",
          "Character Traits",
          "Character Arc",
        ];
        themes = getCharacterTypes();
        atmospheric = getCharacterAtmoshperes();
        theme1Label = "Story Type";
        theme2Label = "Story Arc";
        theme3Label = "Atmosphere";
      } else if (data.category === "Fantasy") {
        postTypes = [
          "Fantasy RPG Encounter",
          "Fantasy RPG Quest",
          "Backstory",
          "Battle Scene",
          "Character Arc",
          "Character Design",
          "Story Arc",
          "Village Encounter",
          "Forest Encounter",
          "Desert Encounter",
          "Mountain Encounter",
          "Swamp Encounter",
          "Dungeon Encounter",
          "Castle Encounter",
        ];
        themes = [
          "Forest",
          "Desert",
          "Mountains",
          "Plains",
          "Swamp",
          "Underwater",
          "City",
          "Castle",
          "Dungeon",
          "Cave",
          "Ruins",
          "Temple",
          "Tavern",
          "Inn",
          "Village",
          "Town",
          "Capital",
          "Kingdom",
          "Empire",
          "Continent",
          "World",
          "Plane",
          "Dimension",
        ];
        theme1Label = "Story Type";
        theme2Label = "Story Arc";
        theme3Label = "Atmosphere";
      } else if (data.category === "Cinema") {
        postTypes = [
          "Movie Script Pitch",
          "Movie Scene",
          "Movie Title",
          "Movie Poster",
          "Movie Trailer",
          "Movie Review",
        ];
        themes = [
          "Action",
          "Adventure",
          "Comedy",
          "Drama",
          "Fantasy",
          "Horror",
          "Mystery",
          "Romance",
          "Sci-Fi",
          "Thriller",
        ];
        theme1Label = "Cinematic Type";
        theme2Label = "Genre";
        theme3Label = "Atmosphere";
      } else if (data.category === "Anime") {
        postTypes = [
          "Anime battle scene",
          "Anime character design",
          "Anime character arc",
          "Anime story arc",
          "Anime backstory",
          "Anime trailer",
          "Anime review",
        ];
        themes = [
          "Action",
          "Adventure",
          "Comedy",
          "Drama",
          "Fantasy",
          "Horror",
          "Mystery",
          "Romance",
          "Sci-Fi",
          "Thriller",
        ];
        theme1Label = "Anime Type";
        theme2Label = "Genre";
        theme3Label = "Atmosphere";
      } else if (data.category === "Portraits") {
        postTypes = [
          "Fictional Backstory",
          "Character Arc",
          "Character Design",
          "Character Traits",
          "Most Wanted Profile",
          "Love Letter",
          "Haiku",
          "Poem",
        ];
        themes = [
          "Action",
          "Adventure",
          "Comedy",
          "Drama",
          "Fantasy",
          "Horror",
          "Mystery",
          "Romance",
          "Sci-Fi",
          "Thriller",
        ];
        theme1Label = "Post Type";
        theme2Label = "Genre";
        theme3Label = "Atmosphere";
      } else if (data.category === "Science Fiction") {
        postTypes = [
          "SciFi battle story",
          "SciFi character design",
          "SciFi character arc",
          "SciFi story arc",
          "SciFi backstory",
          "SciFi trailer",
          "SciFi review",
        ];
        themes = [
          "Action",
          "Adventure",
          "Comedy",
          "Drama",
          "Fantasy",
          "Horror",
          "Mystery",
          "Romance",
          "Thriller",
        ];
        theme1Label = "SciFi Story";
        theme2Label = "Genre";
        theme3Label = "Atmosphere";
      } else if (data.category === "Interior Design") {
        postTypes = getDesignTypes();
        themes = getDesignThemes();

        theme1Label = "Design Type";
        theme2Label = "Design Theme";
        theme3Label = "Atmosphere";
      } else if (data.category === "Floral Arrangements") {
        postTypes = getDesignTypes();
        themes = getDesignThemes();

        theme1Label = "Design Type";
        theme2Label = "Design Theme";
        theme3Label = "Atmosphere";
      } else if (data.category === "Fashion") {
        postTypes = [
          "Fashion Design",
          "Fashion Show",
          "Fashion Review",
          "Fashion Trends",
          "Fashion Inspiration",
          "Fashion Guide",
        ];
        themes = [
          "Casual",
          "Formal",
          "Business",
          "Athletic",
          "Bohemian",
          "Urban",
          "Gothic",
          "Punk",
          "Retro",
          "Vintage",
          "Modern",
          "Classic",
          "Elegant",
          "Luxury",
          "Streetwear",
          "Hipster",
          "Preppy",
          "Artsy",
          "Minimalist",
          "Edgy",
          "Chic",
          "Trendy",
          "Eclectic",
          "Avant-Garde",
          "Romantic",
          "Sexy",
          "Sophisticated",
          "Whimsical",
          "Feminine",
          "Masculine",
          "Androgynous",
          "Unisex",
          "Youthful",
          "Mature",
          "Timeless",
          "Seasonal",
        ];

        theme1Label = "Fashion Type";
        theme2Label = "Fashion Theme";
        theme3Label = "Atmosphere";
      } else if (data.category === "Environments") {
        // provide options for different types of environments and landscapes including exoplanet environments and alien landscapes
        postTypes = [
          "Haiku",
          "Poem",
          "Environment Design",
          "Environment Concept",
          "Event Setting",
          "World Building",
          "Planning",
          "Location Review",
          "Location Guide",
          "Location Tips",
          "Historical Event",
          "Future Event",
          "Story of Discovery",
          "Story of Exploration",
          "Story of Adventure",
          "Story of Survival",
          "Story of Mystery",
        ];

        themes = getGenres();
        theme1Label = "Story Type";
        theme2Label = "Genre";
        theme3Label = "Atmosphere";
      } else {
        postTypes = ["Story", "Poem", "Haiku", "Letter", "Movie Script Pitch"];
        themes = getThemes();
        theme1Label = "Genre";
        theme2Label = "Mood";
        theme3Label = "Atmosphere";
      }

      return (
        <div
          className="row"
          style={{
            color: "black",
            border: "1px solid #183e4b ",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            marginBottom: "10px",
            backgroundColor: !customExpanded ? "#f5f5f5" : "mintcream",
          }}
        >
          <div
            className="col-md-4"
            style={{
              textAlign: "center",
            }}
          >
            <Stack>
              <Typography
                variant="body"
                color="text.primary"
                style={{
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {theme1Label}
              </Typography>
              <Select
                value={selectedTheme1}
                defaultValue={data.category}
                onChange={handleThemeChange1}
                inputProps={{ "aria-label": "Without label" }}
              >
                {postTypes.sort().map((theme, index) => (
                  <MenuItem key={index} value={theme}>
                    {theme}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </div>

          <div
            className="col-md-4"
            style={{
              textAlign: "center",
            }}
          >
            <Stack>
              <Typography
                variant="body"
                color="text.primary"
                style={{
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {theme2Label}
              </Typography>

              <Select
                value={selectedTheme2}
                onChange={handleThemeChange2}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {themes.sort().map((theme, index) => (
                  <MenuItem key={index} value={theme}>
                    {theme}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </div>

          <div
            className="col-md-4"
            style={{
              textAlign: "center",
            }}
          >
            <Stack>
              <Typography
                variant="body"
                color="text.primary"
                style={{
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                {theme3Label}
              </Typography>
              <Select
                value={selectedTheme3}
                onChange={handleThemeChange3}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {atmospheric.sort().map((theme, index) => (
                  <MenuItem key={index} value={theme}>
                    {theme}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const showFantasyOptions = () => {
    return (
      <Box
        sx={{
          paddingBottom: "10px",
        }}
      >
        {GeneratePostButtonOptions(
          SMARTAI_PRODUCT_TEXTGEN_1024,
          "a fantasy story",
          1024,
          "Generate a Fantasy Story",
          "Long Fantasy Story"
        )}
      </Box>
    );
  };

  const AgentOptionsWrapper = (
    expandValue,
    expandHandler,
    optionsTitle,
    optionsIcon,
    category,
    toggles
  ) => {
    return (
      <Accordion
        expanded={expandValue}
        sx={{
          width: "100%",
          height: "100%",
          padding: "10px",
          marginTop: "10px",
          fontSize: "1.2rem",
          fontFamily: "Raleway",
          disabled: { loading },
          backgroundColor: !expandValue ? "#f5f5f5" : "lightgray",
        }}
      >
        <AccordionSummary
          onClick={() => expandHandler(!expandValue)}
          expandIcon={
            <ExpandMoreIcon onClick={() => expandHandler(!expandValue)} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            width: "100%",
            height: "100%",

            fontSize: "1.2rem",
            fontFamily: "Raleway",
            backgroundColor: !expandValue ? "#f5f5f5" : "lightgray",
          }}
        >
          <Typography
            variant="h5"
            color="text.secondary"
            style={{
              width: "100%",
              fontFamily: "Raleway",
              fontWeight: "600",
              color: !expandValue ? "#A6A6A6" : "#183E4B",
            }}
          >
            ✨ {optionsTitle}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: !expandValue ? "#f5f5f5" : "#f5f5f5",
            border: "1px solid #ccc",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            fontFamily: "Raleway",
            display: "flex",
          }}
        >
          <div>
            {toggles.map((toggle, index) => (
              <Box>
                {toggle}
                <br />
              </Box>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  const AgentOptionsWriter = (category) => {
    return AgentOptionsWrapper(
      agentOptionsStoryExpanded,
      setAgentOptionsStoryExpanded,
      "Storyteller Options",
      agentIconWriter,
      category,
      [
        NOptionToggleStyle(
          "POV",
          [
            "First Person",
            "Second Person",
            "Third Limited",
            "Third Omniscient",
            "Third Objective",
          ],
          agentPov1,
          setAgentPov1
        ),
        NOptionToggleStyle(
          "Story Type",
          getStoryTypesForCategory(category),
          agentStoryType,
          setAgentStoryType,
          true
        ),
        NOptionToggleStyle(
          "Tone",
          [
            "Formal",
            "Informal",
            "Professional",
            "Casual",
            "Friendly",
            "Humorous",
            "Serious",
            "Sarcastic",
            "Optimistic",
            "Pessimistic",
            "Neutral",
            "Sincere",
            "Ironic",
          ],
          agentTone1,
          setAgentTone1,
          true
        ),
        NOptionToggleStyle(
          "Mood",
          [
            "Happy",
            "Sad",
            "Excited",
            "Scared",
            "Angry",
            "Neutral",
            "Mysterious",
            "Surprised",
            "Anxious",
            "Hopeful",
            "Nostalgic",
          ],
          agentMood1,
          setAgentMood1,
          true
        ),
      ]
    );
  };

  const AgentOptionsMarketing = (category) => {
    return AgentOptionsWrapper(
      agentOptionsMarketingExpanded,
      setAgentOptionsMarketingExpanded,
      "Marketing Options",
      agentIconWriter,
      category,
      [
        NOptionToggleStyle(
          "Brand Voice",
          [
            "Formal",
            "Informal",
            "Professional",
            "Casual",
            "Friendly",
            "Humorous",
          ],
          agentVoice,
          setAgentVoice
        ),
        NOptionToggleStyle(
          "Audience",
          [
            "General",
            "Millenials",
            "Gen Z",
            "Boomers",
            "Teens",
            "Kids",
            "Parents",
            "Students",
          ],
          agentAudience,
          setAgentAudience
        ),
        NOptionToggleStyle(
          "Goals",
          ["Awareness", "Engage", "Convert", "Retain", "Loyalty", "Advocacy"],
          agentMarketingGoals,
          setAgentMarketingGoals
        ),
        NOptionToggleStyle(
          "Call To Action",
          [
            "Learn More",
            "Buy Now",
            "Subscribe",
            "Sign Up",
            "Donate",
            "Contact Us",
          ],
          agentMarketingCTA,
          setAgentMarketingCTA
        ),
      ]
    );
  };

  const AdvancedImageOptions = (category) => {
    return (
      <Box
        sx={{
          padding: "10px",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            border: "1px solid #183E4B",
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              fontFamily: "Raleway",
              fontSize: "14px",
              padding: "5px",
            }}
          >
            Image Seed
          </Typography>

          <InputBase
            type="number"
            sx={{
              border: "1px solid black",
              outline: "none",
              width: "70px",
              padding: "4px",
              input: {
                color: "black",
                padding: "4px",
                boxSizing: "border-box",
                fontSize: "14px",
                marginRight: "0px",
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  WebkitAppearance: "none!important",
                  margin: 0,
                },
                "&[type=number]": {
                  MozAppearance: "textfield!important",
                },
              },
            }}
            value={seed}
            onChange={(event) => {
              const value = parseInt(event.target.value, 10);
              if (value > 1000000) {
                setSeed(1000000);
              } else if (value < 0) {
                setSeed(0);
              } else {
                setSeed(value);
              }
            }}
            inputProps={{ min: 0, max: 1000000 }}
          />

          {inputIncludeTags}
        </Box>
        {AgentOptionsWrapper(
          imageGenOptionsExpanded,
          setImageGenOptionsExpanded,
          "Advanced Image Options",
          agentIconInteriorDesigner,
          category,
          [
            NOptionToggleStyle(
              "Style Options II",
              [
                "Rustic",
                "Minimalist",
                "Futuristic",
                "Retro",
                "Urban",
                "Fantasy",
                "Sci-fi",
                "Horror",
                "Steampunk",
                "Masculine",
                "Feminine",
                "Androgynous",
                "Polar",
                "Tropical",
                "Desert",
                "Mountain",
                "Plains",
                "Forest",
                "Jungle",
                "Sea",
              ],
              imageGenStyle2,
              setImageGenStyle2
            ),
            NOptionToggleStyle(
              "Atmosphere II",
              [
                "Calm",
                "Chaotic",
                "Dangerous",
                "Eerie",
                "Gloomy",
                "Misty",
                "Serene",
                "Stormy",
                "Sunny",
                "Tense",
                "Tranquil",
                "Warm",
                "Windy",
                "Peaceful",
                "Hopeful",
                "Inspired",
                "Bored",
                "Relaxed",
                "Stressed",
                "Tired",
                "Energetic",
                "Motivated",
                "Romantic",
                "Nostalgic",
              ],
              imageGenOptionAtmosphere2,
              setImageGenOptionAtmosphere2
            ),
            NOptionToggleStyle(
              "Cultural Options",
              [
                "Asian",
                "African",
                "European",
                "Latin",
                "Middle Eastern",
                "Indian",
                "Native American",
                "Pacific Islander",
                "Aboriginal",
                "Inuit",
                "Polynesian",
                "Maori",
                "Celtic",
                "Nordic",
                "Slavic",
                "Mediterranean",
                "Baltic",
                "Caribbean",
                "South American",
                "Central American",
                "North American",
                "Australian",
                "New Zealand",
                "Urban Punk",
                "Urban Hip Hop",
                "Ska",
                "Reggae",
                "Rap",
                "Rock",
                "Metal",
                "Punk",
                "Goth",
                "Emo",
                "Country",
                "California Valley",
                "Geek",
                "Cosplay",
                "Gamer",
                "Harajuku",
                "Kawaii",
                "Otaku",
              ],
              imageCulturalOption,
              setImageCulturalOption
            ),
          ]
        )}
      </Box>
    );
  };

  const AgentOptionsAdvanced = (category) => {
    return AgentOptionsWrapper(
      agentOptionsExpanded,
      setAgentOptionsExpanded,
      "Advanced Options",
      agentIconWriter,
      category,
      [
        NOptionToggleStyle(
          "Tone 2",
          ["Formal", "Informal", "Professional", "Casual", "Friendly"],
          agentTone2,
          setAgentTone2
        ),
        NOptionToggleStyle(
          "Mood #2",
          ["Romantic", "Nostalgic", "Peaceful", "Hopeful", "Inspired", "Bored"],
          agentMood2,
          setAgentMood2
        ),
        NOptionToggleStyle(
          "Mood #3",
          ["Relaxed", "Stressed", "Tired", "Energetic", "Motivated"],
          agentMood3,
          setAgentMood3
        ),
        NOptionToggleStyle(
          "Atmospherics",
          ["Calm", "Chaotic", "Dangerous", "Eerie", "Gloomy", "Misty"],
          agentAtmosphere1,
          setAgentAtmosphere1
        ),
        NOptionToggleStyle(
          "Atmos. #2",
          ["Serene", "Stormy", "Sunny", "Tense", "Tranquil", "Warm", "Windy"],
          agentAtmosphere2,
          setAgentAtmosphere2
        ),
      ]
    );
  };

  const AgentOptionsByCategory = (category) => {
    let culinaryToggles = NOptionToggleStyle(
      "Culinary Options",
      ["Fancy", "Vegan", "Mexican", "Italian", "Thai", "Indian"],
      agentCulinaryCuisine,
      setagentCulinaryCuisine
    );
    let characterToggles = NOptionToggleStyle(
      "Character Options",
      ["Hero", "Villain", "Sidekick", "Mentor", "Antagonist"],
      agentCharacter1,
      setAgentCharacter1
    );
    let fantasyToggles = NOptionToggleStyle(
      "Fantasy Options",
      ["Hero's Journey", "Magic", "Dragons", "Elves", "Wizards", "Fairies"],
      agentFantasy1,
      setAgentFantasy1
    );
    let cinemaToggles = NOptionToggleStyle(
      "Cinema Options",
      ["Action", "Comedy", "Drama", "Horror", "Romance", "SciFi"],
      agentCinemaGenre,
      setagentCinemaGenre
    );
    let animeToggles = NOptionToggleStyle(
      "Anime Options",
      ["Chibi", "Harajuku", "Shonen", "Shojo", "Seinen", "Mecha"],
      agentAnimeSubgenre,
      setagentAnimeSubgenre
    );
    let portraitToggles = NOptionToggleStyle(
      "Portrait Options",
      ["Love Interest", "Friend", "Enemy", "Secrets", "Villain"],
      agentPortrait1,
      setAgentPortrait1
    );
    let natureToggles = NOptionToggleStyle(
      "Habitats",
      ["Forest", "Desert", "Mountains", "Plains", "Jungle", "Tundra"],
      agentNatureHabitat,
      setAgentNatureHabitat
    );
    let natureToggles2 = NOptionToggleStyle(
      "Species",
      ["Mammals", "Reptiles", "Birds", "Insects", "Fish", "Amphibians"],
      agentNatureSpecies,
      setAgentNatureSpecies
    );
    let natureToggles3 = NOptionToggleStyle(
      "Locations",
      ["North", "South", "East", "West", "Central", "Tropical"],
      agentNatureLocation,
      setAgentNatureLocation
    );

    // define scifi subgenres
    let scifiToggles1 = NOptionToggleStyle(
      "Subgenre",
      [
        "Cyberpunk",
        "Space Opera",
        "Military",
        "Time Travel",
        "Alien Invasion",
        "Post-Apocalyptic",
      ],
      agentScifiGenre,
      setAgentScifiGenre
    );
    let scifiToggles2 = NOptionToggleStyle(
      "Technology",
      [
        "Near Future",
        "Far Future",
        "Advanced",
        "Primitive",
        "Post-Apocalyptic",
      ],
      agentScifiTech,
      setAgentScifiTech
    );
    let scifiToggles3 = NOptionToggleStyle(
      "World Setting",
      ["Space", "Planet", "Moon", "Asteroid", "Space Station", "Underwater"],
      agentScifiWorld,
      setAgentScifiWorld
    );

    let materialsToggle = NOptionToggleStyle(
      "Materials",
      ["Wood", "Metal", "Plastic", "Glass", "Stone", "Ceramic"],
      agentMaterials,
      setAgentMaterials
    );

    let texturesToggle = NOptionToggleStyle(
      "Textures",
      ["Smooth", "Rough", "Soft", "Hard", "Wet", "Dry"],
      agentTextures,
      setAgentTextures
    );

    let fashionToggle = NOptionToggleStyle(
      "Fashion",
      ["Casual", "Formal", "Sporty", "Elegant", "Retro", "Vintage"],
      agentFashionStyle,
      setAgentFashionStyle
    );

    let fashionToggle2 = NOptionToggleStyle(
      "Fashion #2",
      [
        "Casual",
        "Formal",
        "Business",
        "Athletic",
        "Bohemian",
        "Urban",
        "Gothic",
        "Punk",
        "Retro",
        "Vintage",
        "Modern",
        "Classic",
        "Elegant",
        "Luxury",
        "Streetwear",
        "Hipster",
        "Preppy",
        "Artsy",
        "Minimalist",
        "Edgy",
        "Chic",
        "Trendy",
        "Eclectic",
        "Avant-Garde",
        "Romantic",
        "Sexy",
        "Sophisticated",
        "Whimsical",
        "Feminine",
        "Masculine",
        "Androgynous",
        "Unisex",
        "Youthful",
        "Mature",
        "Timeless",
        "Seasonal",
      ],
      agentFashionStyle,
      setAgentFashionStyle
    );

    let interiorDesignToggle = NOptionToggleStyle(
      "Interior Design",
      ["Modern", "Rustic", "Minimalist", "Eclectic", "Bohemian"],
      agentInteriorDesignStyle,
      setAgentInteriorDesignStyle
    );

    let colorPaletteToggle = NOptionToggleStyle(
      "Color Palette",
      ["Mono", "Analogous", "Complementary", "Triadic", "Tetradic"],
      agentColorPalette,
      setAgentColorPalette
    );
    let togglePOV = NOptionToggleStyle(
      "POV",
      [
        "First Person",
        "Second Person",
        "Third Limited",
        "Third Omniscient",
        "Third Objective",
      ],
      agentPov1,
      setAgentPov1
    );

    let toggleTone1 = NOptionToggleStyle(
      "Tone",
      ["Dramatic", "Funny", "Personal", "Inspirational", "Informative"],
      agentTone1,
      setAgentTone1
    );

    let toggleMood1 = NOptionToggleStyle(
      "Mood Options",
      ["Happy", "Sad", "Excited", "Scared", "Angry", "Mysterious"],
      agentMood1,
      setAgentMood1
    );
    let toggleAudience = NOptionToggleStyle(
      "Audience",
      ["General", "Millenials", "Gen Z", "Boomers", "Teens", "Kids"],
      agentAudience,
      setAgentAudience
    );
    let toggleDifficulty = NOptionToggleStyle(
      "Difficulty",
      ["Easy", "Medium", "Hard", "Expert"],
      agentDifficulty,
      setAgentDifficulty
    );
    let toggleDietaryRestrictions = NOptionToggleStyle(
      "Dietary Restrictions",
      ["Vegetarian", "Vegan", "Gluten Free", "Dairy Free", "Keto", "Paleo"],
      agentDietaryRestrictions,
      setAgentDietaryRestrictions
    );
    let toggleModel = NOptionToggleStyle(
      "AI Model",
      ["ChatGPT mini", "ChatGPT 4o", "Gemini flash", "Gemini Pro 1.5"], // "SD CORE"], //, "SD ULTRA",
      textModel,
      setTextModel
    );
    //let scifiToggles3 = NOptionToggleStyle("Alien Species", ["Humanoid", "Insectoid", "Reptilian", "Robotic", "Energy Being", "Silicon Based"], agentScifiAlien, setAgentScifiAlien);

    let toggles = null;

    switch (category) {
      case "Culinary":
        toggles = [
          culinaryToggles,
          toggleDifficulty,
          toggleDietaryRestrictions,
        ];
        break;
      case "Characters":
        toggles = [togglePOV, toggleMood1, toggleTone1, characterToggles];
        break;
      case "Fantasy":
        toggles = [togglePOV, toggleMood1, toggleTone1, fantasyToggles];
        break;
      case "Science Fiction":
        toggles = [
          togglePOV,
          toggleMood1,
          toggleTone1,
          scifiToggles1,
          scifiToggles2,
          scifiToggles3,
        ];
        break;
      case "Robots":
        toggles = [
          togglePOV,
          toggleMood1,
          toggleTone1,
          scifiToggles1,
          scifiToggles2,
          scifiToggles3,
        ];
        break;
      case "Cinema":
        toggles = [togglePOV, toggleMood1, toggleTone1, cinemaToggles];
        break;
      case "Anime":
        toggles = [togglePOV, toggleMood1, toggleTone1, animeToggles];
        break;
      case "Portraits":
        toggles = [togglePOV, toggleMood1, toggleTone1, portraitToggles];
        break;
      case "Animals":
        toggles = [
          togglePOV,
          toggleMood1,
          toggleTone1,
          natureToggles,
          natureToggles2,
          natureToggles3,
        ];
        break;
      case "Nature":
        toggles = [
          togglePOV,
          toggleMood1,
          toggleTone1,
          natureToggles,
          natureToggles2,
          natureToggles3,
        ];
        break;
      case "Science":
        toggles = [
          togglePOV,
          toggleMood1,
          toggleTone1,
          natureToggles,
          natureToggles2,
          natureToggles3,
        ];
        break;
      case "Floral Arrangements":
        toggles = [
          togglePOV,
          toggleMood1,
          toggleTone1,
          natureToggles,
          natureToggles2,
          natureToggles3,
        ];
        break;
      case "Comics":
        toggles = [
          togglePOV,
          toggleMood1,
          toggleTone1,
          characterToggles,
          scifiToggles3,
          toggleAudience,
        ];
        break;
      case "Environments":
        toggles = [
          togglePOV,
          toggleMood1,
          toggleTone1,
          natureToggles,
          natureToggles2,
          natureToggles3,
        ];
        break;
      case "Fashion":
        toggles = [
          togglePOV,
          toggleMood1,
          toggleTone1,
          fashionToggle,
          colorPaletteToggle,
        ];
        break;
      case "Interior Design":
        toggles = [
          togglePOV,
          toggleMood1,
          toggleTone1,
          interiorDesignToggle,
          colorPaletteToggle,
        ];
        break;
      case "Materials":
        toggles = [
          togglePOV,
          toggleMood1,
          toggleTone1,
          materialsToggle,
          colorPaletteToggle,
        ];
        break;
      case "Textures":
        toggles = [
          togglePOV,
          toggleMood1,
          toggleTone1,
          texturesToggle,
          colorPaletteToggle,
        ];
        break;
      default:
        toggles = null;
    }

    // check for null toggles
    if (toggles === null) {
      return <></>;
    }

    return AgentOptionsWrapper(
      agentOptionsGuruExpanded,
      setAgentOptionsGuruExpanded,
      `${category} Options`,
      agentIconGuru,
      category,
      toggles
    );
  };

  const AgentOptionsAdvancedSection = () => {
    return (
      <Accordion expanded={agentOptionsExpanded}>
        <AccordionSummary
          onClick={() => setAgentOptionsExpanded(!agentOptionsExpanded)}
          expandIcon={
            <ExpandMoreIcon
              onClick={() => setAgentOptionsExpanded(!agentOptionsExpanded)}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            width: "100%",
            height: "100%",

            padding: "10px",
            fontSize: "1.2rem",
            fontFamily: "Raleway",
            backgroundColor: !agentOptionsExpanded ? "#f5f5f5" : "#8BA0A4",
          }}
        >
          <Typography
            variant="h5"
            color="text.secondary"
            style={{
              width: "100%",
              padding: "10px",
              fontFamily: "Raleway",
              fontWeight: "600",
            }}
          >
            ✨ Advanced Options{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: !agentOptionsExpanded ? "#f5f5f5" : "#8BA0A4",
          }}
        >
          {AgentOptionsAdvanced()}
        </AccordionDetails>
      </Accordion>
    );
  };

  const AgentsTab = () => {
    var enoughCredits =
      getSubscriptionCreditsRemaining() + getWalletCreditsRemaining() >=
      productsImageGenPrice;

    // setup a grid for the story options and themes
    return data ? (
      <Box className="story">
        {enoughCredits && inputTermsPrivacy}
        {SetupAgentBox(data.category)}
        <Typography
          variant="body2"
          color="text.secondary"
          style={{
            textAlign: "center",
            fontFamily: "Raleway",
            fontWeight: "300",
            fontStyle: "italic",
            padding: "10px",
          }}
        >
          NOTE: AI Agents and GenAI are experimental in nature. <br />
          Occasionally the AI may generate unexpected or unusual results. <br />{" "}
        </Typography>
        {ShowGeneratedTextSection()}
        {NOptionToggleStyle(
          "AI Model",
          ["ChatGPT mini", "ChatGPT 4o", "Gemini flash", "Gemini Pro 1.5"], // "SD CORE"], //, "SD ULTRA",
          textModel,
          setTextModel
        )}
        {AgentOptionsWriter(data.category)}
        {AgentOptionsMarketing(data.category)}

        {ShowSubscriptionInfo()}
        {!enoughCredits && RenderCreditsButton()}
      </Box>
    ) : (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
         <CircularProgress 
                variant="indeterminate" 
                style={{
                  width: "10%",
                  height: "10%",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >

               
              </CircularProgress>
      </Box>
    );
  };
  const AgentsGurusTab = () => {
    var enoughCredits =
      getSubscriptionCreditsRemaining() + getWalletCreditsRemaining() >=
      productsImageGenPrice;

    // setup a grid for the story options and themes
    return data ? (
      <Box className="story">
        {enoughCredits && inputTermsPrivacy}
        {data.category === "Comics"
          ? SetupComicsBox()
          : SetupGurusBox(data.category)}
        <Typography
          variant="body2"
          color="text.secondary"
          style={{
            textAlign: "center",
            fontFamily: "Raleway",
            fontWeight: "300",
            fontStyle: "italic",
            padding: "10px",
          }}
        >
          <strong>NOTE:</strong> AI Agents and GenAI are experimental in nature.{" "}
          <br />
          Occasionally the AI may generate unexpected or unusual results. <br />{" "}
        </Typography>
        {ShowGeneratedTextSection()}
        {NOptionToggleStyle(
          "AI Model",
          ["ChatGPT mini", "ChatGPT core", "Gemini flash", "Gemini Pro 1.5"], // "SD CORE"], //, "SD ULTRA",
          textModel,
          setTextModel
        )}
        ;{AgentOptionsByCategory(data.category)}
        {ShowSubscriptionInfo()}
        {!enoughCredits && RenderCreditsButton()}
      </Box>
    ) : (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
         <CircularProgress 
                variant="indeterminate" 
                value={progressGenImage} 
                style={{
                  width: "10%",
                  height: "10%",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
               
              </CircularProgress>
      </Box>
    );
  };
  const PromptsPoems = () => {
    // setup a grid for the story options and themes
    return data ? (
      <div className="story">
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            border: "1px solid #ccc",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          {
            // setup a grid for the story options and themes, should look cool and slick with shadowed borders using theme colors
          }

          {textGenerationFiltersPostType()}

          {GeneratePostButtonOptions(
            19,
            "an AI image generation prompt",
            128,
            "Generate an AI Image Generation Prompt",
            "AI Image Generation Prompt"
          )}

          {GeneratePostButtonOptions(
            21,
            "poem",
            256,
            "Generate a Poem",
            "Poem"
          )}

          {GeneratePostButtonOptions(
            21,
            "Haiku poem",
            256,
            "Generate a Haiku",
            "Haiku Poem"
          )}
          {GeneratePostButtonOptions(
            23,
            "a letter",
            512,
            "Generate a Letter",
            "Letter"
          )}
        </Box>
      </div>
    ) : (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress 
                variant="indeterminate" 
                style={{
                  width: "25%",
                  height: "25%",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
               
              </CircularProgress>
      </Box>
    );
  };

  const browserDetailsLog = () => {
    LogSomething("Browser: " + browserName);
    LogSomething("Browser Version: " + browserVersion);
    LogSomething("Operating System: " + osName);
    LogSomething("OS Version: " + osVersion);
    LogSomething("Device Type: " + deviceType);
    LogSomething("Mobile Vendor: " + mobileVendor);
    LogSomething("Mobile Model: " + mobileModel);
    LogSomething("User Agent: " + getUA);
    LogSomething("Is Desktop: " + isDesktop);
    LogSomething("Is Mobile: " + isMobile);
    LogSomething("Is Tablet: " + isTablet);
  };

  const browserDetails = () => {
    return (
      <Paper
        elevation={3}
        style={{
          padding: "10px",
          marginTop: "10px",
          marginBottom: "10px",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Typography>Browser: {browserName}</Typography>
        <Typography>Browser Version: {browserVersion}</Typography>
        <Typography>Operating System: {osName}</Typography>
        <Typography>OS Version: {osVersion}</Typography>
        <Typography>Device Type: {deviceType}</Typography>
        <Typography>Mobile Vendor: {mobileVendor}</Typography>
        <Typography>Mobile Model: {mobileModel}</Typography>
        <Typography>User Agent: {getUA}</Typography>
        <Typography>Is Desktop: {isDesktop ? "Yes" : "No"}</Typography>
        <Typography>Is Mobile: {isMobile ? "Yes" : "No"}</Typography>
        <Typography>Is Tablet: {isTablet ? "Yes" : "No"}</Typography>
      </Paper>
    );
  };

  function renderWarningMessage(message, includeBrowserDetails) {
    return (
      <div className="row">
        <div className="col-md-12">
          <div
            className="alert alert-warning"
            role="alert"
            style={{
              padding: "10px",
              marginTop: "10px",
              marginBottom: "10px",
              backgroundColor: "#f5f5f5",
            }}
          >
            {message}
            {
              //(includeBrowserDetails === true ? browserDetails() : <></>)
            }
          </div>
        </div>
      </div>
    );
  }

  const StudioCard = (
    <div
      className="custom__card"
      style={{
        backgroundColor: "white",
        border: "1px solid #ccc",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        width: "100%",
        minWidth: "400px !important",
        maxWidth: "600px",
        position: "relative",
        top: "10",
      }}
    >
      <div className="leftbadge">✨ STUDIO ✨</div>
      <div className="badge">✨BETA 2✨</div>

      {browserName.includes("Safari") ? RenderTabsSafari() : RenderTabs()}

      <div className="main__body">
        {currentTab === 0 ? VaryStyles(data) : <></>}
        {currentTab === 1 ? AgentsTab() : <></>}
        {currentTab === 2 ? AgentsGurusTab() : <></>}
        {currentTab === 3 ? EffectFilters(data) : <></>}
        {currentTab === 4 ? DuoToneFilters(data) : <></>}
        {currentTab === 5 ? CropCuts(data) : <></>}

        {
          //currentTab === 5 ? VaryStyles(data) : <></>
        }
      </div>
    </div>
  );

  const WantMoreCredits = () => {
    return (
      <div className="bg-banner my-3 px-4 text-center ">
        <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
          <p className="mb-0 me-3 size-options-text">Want more credits?</p>
          <Button
            className="rounded-pill"
            variant="contained"
            onClick={() => {
              navigate("/credits");
            }}
          >
            View Credit Packs
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {data ? (
        <div
          style={{
            backgroundColor: "#f5f5f5",
            padding: "10px",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",

              backgroundColor: "#f5f5f5",
              padding: "10px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            <div
              className="col-md-6 "
              style={{
                textAlign: "center",
                marginBottom: "20px",
                maxWidth: "600px",
                minWidth: "400px",
              }}
            >
              {ImageCard}
            </div>
            <div
              className="col-md-6"
              style={{
                textAlign: "center",
                maxWidth: "600px",
                minWidth: "400px",
              }}
            >
              {StudioCard}
            </div>
          </div>

          {WantMoreCredits()}
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
           <CircularProgress 
                variant="indeterminate" 
                style={{
                  width: "10%",
                  height: "10%",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
               
              </CircularProgress>
        </Box>
      )}
    </>
  );
}
