import { apiURI } from "../shared/api.settings";
import axios from "axios";
import { LogSomething, TraceSomething } from "../utils/utilsStringFuncs";

class ImageService {
  static GetImageByIdentifier = async (userId, identifier, token) => {
    return fetch(`${apiURI}/image/${identifier}?userId=${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());
  };

  static GetRelatedImages = async (userId, identifier, maxResults, token) => {
    return fetch(
      `${apiURI}/image/${identifier}/related?max=${maxResults}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetFeaturedImages = async (userId, page = 0, pageSize = 24, token) => {
    return fetch(
      `${apiURI}/image/featured?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  /*
  static GetCleanImages = async (userId, page = 0, pageSize = 24, token) => {
    var images = await this.GetImages(userId, page, pageSize, token);
    console.log("GetCleanImages: ", JSON.stringify(images));
    // loop through images and replace space in url with %20
   
    for (var i = 0; i < images.length; i++) {
      images[i].public_url_thumbnail = images[i].public_url_thumbnail.replace(
        / /g,
        "%20"
      );
      images[i].public_url_protected = images[i].public_url_protected.replace(
        / /g,
        "%20"
      );
      images[i].public_url_reduced = images[i].public_url_reduced.replace(
        / /g,
        "%20"
      );
    }
     
    return images;
  };
 */

  static GetImages = async (userId, page = 0, pageSize = 24, token) => {
    const CACHE_KEY = `smartai_recent_images_${page}_${pageSize}`;
    const CACHE_TTL = 5 * 60 * 1000; // 5 minutes

    try {
      // Check cache first
      const cached = localStorage.getItem(CACHE_KEY);
      if (cached) {
        console.log("GetImages Cache hit");
        const { data, timestamp } = JSON.parse(cached);
        if (Date.now() - timestamp < CACHE_TTL) {
          return data;
        }
      }

      console.log("GetImages Cache miss");

      // Fetch fresh data
      const response = await fetch(
        `${apiURI}/image/?page=${page}&pageSize=${pageSize}&userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      var images = await response.json();
      // Cache response
      localStorage.setItem(
        CACHE_KEY,
        JSON.stringify({
          data: images,
          timestamp: Date.now(),
        })
      );

      return images;
    } catch (error) {
      console.error("Failed to fetch images:", error);
      throw error;
    }
  };

  static GetImagesByCategory = async (
    userId,
    category = "all",
    page = 0,
    pageSize = 24,
    token
  ) => {
    // encode category
    category = encodeURIComponent(category);

    const CACHE_KEY = `smartai_recent_images_${category}_${page}_${pageSize}`;
    const CACHE_TTL = 5 * 60 * 1000; // 5 minutes

    try {
      // Check cache first
      const cached = localStorage.getItem(CACHE_KEY);
      if (cached) {
        console.log("GetImagesByCategory Cache hit");
        const { data, timestamp } = JSON.parse(cached);
        if (Date.now() - timestamp < CACHE_TTL) {
          return data;
        }
      }

      console.log("GetImagesByCategory Cache miss");

      /*
  return fetch(
    `${apiURI}/image/category/${category}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json());
*/

      // Fetch fresh data
      const response = await fetch(
        `${apiURI}/image/category/${category}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      var images = await response.json();
      // Cache response
      localStorage.setItem(
        CACHE_KEY,
        JSON.stringify({
          data: images,
          timestamp: Date.now(),
        })
      );

      return images;
    } catch (error) {
      console.error("Failed to fetch images:", error);
      throw error;
    }
  };

  /* static GetImages = async (userId, page = 0, pageSize = 24, token) => {
    console.log("GetImages: ", JSON.stringify(userId));
    try
    {
      let response = await fetch(`https://storage.googleapis.com/smartai-studio-dev-images-cache-na/recent/smartai_studio_recent_all.json`, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "no-cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          //"Content-Type": "application/json",
          //Authorization: `Bearer ${token}`,
        },
      });
      console.log("GetImages response1:", response);
      let response2 = JSON.parse(response);
      console.log("GetImages response2:", response2);
      return response2;
    }
    catch(err)
    {
      console.log("GetImages Error: ", err);
    }
  };
  //let response = JSON.parse(jsonLatest);
  //console.log("GetImages response:", response);
  //return response;
*/

  /*
  static GetImages = async (userId, page = 0, pageSize = 24, token) => {
    const jsonLatest = "https://storage.googleapis.com/smartai-studio-dev-images-cache-na/recent/smartai_studio_recent_all.json";
    const corsProxyUrl = "https://api.allorigins.win/get?url=";
    
    try {
      // Try using CORS proxy
      const response = await axios.get(`${jsonLatest}`);
      
      if (response.data?.contents) {
        return JSON.parse(response.data.contents);
      }
      
      // Fallback to API endpoint if proxy fails
      const apiResponse = await axios.get(
        `${apiURI}/image/?page=${page}&pageSize=${pageSize}&userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      
      return apiResponse.data;
      
    } catch (error) {
      console.error('Error fetching images:', error);
      // Final fallback to API endpoint
      const fallbackResponse = await axios.get(
        `${apiURI}/image/?page=${page}&pageSize=${pageSize}&userId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return fallbackResponse.data;
    }
  };
*/

  /*
static GetImages = async (userId, page = 0, pageSize = 24, token) => {
  const CACHE_KEY = 'smartai_recent_images';
  const CACHE_TTL = 5 * 60 * 1000; // 5 minutes
  
  try {
    // Check cache first
    const cached = localStorage.getItem(CACHE_KEY);
    if (cached) {
      const { data, timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp < CACHE_TTL) {
        return data;
      }
    }

    // Fetch fresh data
    const response = await axios({
      method: 'get',
      url: 'https://smartai-studio-dev-images-cache-na.storage.googleapis.com/recent/smartai_studio_recent_all.json',
      timeout: 5000,
      responseType: 'json',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'mode': 'no-cors'
      }
    });

    console.log('Fetched images:', response.data);

    // Cache response
    localStorage.setItem(CACHE_KEY, JSON.stringify({
      data: response.data,
      timestamp: Date.now()
    }));

    return response.data;

  } catch (error) {
    console.error('Failed to fetch images:', error);
    throw error;
  }
};
*/
  static GetUserImages = async (userId, page = 0, pageSize = 24, token) => {
    return fetch(
      `${apiURI}/image/user?userId=${userId}&page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetUserImagesAI = async (userId, page = 0, pageSize = 24, token) => {
    return fetch(
      `${apiURI}/image/generated/user?userId=${userId}&page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetUserImagesWithStories = async (
    userId,
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/user/textgen?userId=${userId}&page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetUserImage = async (userId, imageId, token) => {
    return fetch(`${apiURI}/image/${imageId}/user/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());
  };

  static GetImagesBySearchTerm = async (
    userId,
    searchTerm = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/search/${searchTerm}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetImagesByPhraseSearchTerm = async (
    userId,
    searchTerm = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/search/phrase/${searchTerm}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetImagesByTag = async (
    userId,
    tagValue = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/tag/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByCategoryAndTag = async (
    userId,
    category,
    tagValue = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/category/${category}/tag/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByCategoryAndHeight = async (
    userId,
    category,
    tagValue = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/category/${category}/height/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByCategoryAndTopic = async (
    userId,
    category,
    tagValue = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/category/${category}/topic/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByCategoryAndWidth = async (
    userId,
    category,
    tagValue = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/category/${category}/width/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByCategoryAndColor = async (
    userId,
    category,
    tagValue = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/category/${category}/color/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByTopicAndTag = async (
    userId,
    topic,
    tagValue = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/topic/${topic}/tag/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByTopic = async (
    userId,
    topic = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/topic/${topic}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetImagesByMaterial = async (
    userId,
    material = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/materials/${material}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetImageUploadsByUser = async (
    userId,
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/${userId}/uploads/?page=${page}&pageSize=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  static GetRandomImagesByCategory = async (
    userId,
    category = "all",
    page = 0,
    pageSize = 24,
    maxImages = 20,
    token
  ) => {
    TraceSomething("GetRandomImagesByCategory: " + userId + " - " + token);
    return fetch(
      `${apiURI}/image/random/category/${category}?page=${page}&pageSize=${pageSize}&maxImages=${maxImages}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  // image.service.js

  /*
static GetRandomImagesByCategory = async ( userId,
  category = "all",
  page = 0,
  pageSize = 24,
  maxImages = 20,
  token) => {
  const response = await axios.get(`${apiURI}/image/random/category/${category}?page=${page}&pageSize=${pageSize}&maxImages=${maxImages}&userId=${userId}`);
  return response.data;
};
*/

  static GetImagesByColor = async (
    userId,
    color = "",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/tag/color/${color}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByHeight = async (
    userId,
    tagValue = "1024",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/tag/height/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };
  static GetImagesByWidth = async (
    userId,
    tagValue = "1024",
    page = 0,
    pageSize = 24,
    token
  ) => {
    return fetch(
      `${apiURI}/image/tag/width/${tagValue}?page=${page}&pageSize=${pageSize}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  /*
    static getFavoriteForUser = async (userId, token) => {
        // console.log("getFavoriteForUser: ", userId);
        return fetch(`${apiURI}/image/user/${userId}/favorites`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        .then((response) => response.json());
    }
    */

  static getFavoriteImagesForUser = async (userId, token) => {
    //console.log("getFavoriteImagesForUser: ", userId);

    return fetch(`${apiURI}/image/user/${userId}/favorites?userId=${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());
  };
  static GetFavoriteStatus = async (userId, imageId, token) => {
    //console.log("getFavoriteImagesForUser: ", userId);

    return fetch(`${apiURI}/image/user/${userId}/favorites/${imageId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());
  };
  static favoriteImage = async (userId, imageId, mode, token) => {
    //console.log("favoriteImage:", userId, imageId, mode);
    // fire and forget
    // get method
    return fetch(
      `${apiURI}/collections/favorites/${userId}/${imageId}?mode=${mode}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json());
  };

  /*const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      ImageService.UploadImage(formData).then((response) => {
        navigate(`/image/${response.id}`);
      }
      );
    }
  };
  */

  static UploadImage = async (userId, bytes, thumbnail, meta, token) => {
    var body = {
      userId: userId,
      productType: 20, //image upload
      mode: 1, // "pay with credits"
      imageMetadata: meta,
      imageBytes: bytes,
      imageBytesThumbnail: bytes,
      productId: "68",
      imageUrl: "",
      assetId: "",
    };

    console.log("ProductService - UploadImage - body: ", body);
    return fetch(`${apiURI}/product/image/upload`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  };

  static DescribeImage = async (userId, formData, thumbnail, token) => {
    var body = {
      userId: userId,
      productType: 25,
      mode: 1,
      imageBytes: formData,
      imageBytesThumbnail: null,
      productId: "67",
      imageUrl: "",
      assetId: "",
    };
    /*
"productId": 67,
  "name": "image-describe-low",
  "description1": "smartai.studio - describe image - 256tokens - low tier",
  */
    console.log("ProductService - DescribeImage - body: ", body);
    return fetch(`${apiURI}/product/image/describe`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  };

  static GenerateTextFromImageContext = async (context, imageData, themes) => {
    //console.log("GenerateTextFromImageContext:", context, imageData, themes);

    // sample payload
    /*
            {
                "prompt":"Doug the barbarian was a well loved member of the community, well respected for his work ethic and his love for animals, but harbored a horrible secret.",
                "max_new_tokens":1024,
                "temperature":0.3,
                "top_k":0,
                "top_p":0.9,
                "do_sample":true,
                "repetition_penalty":1.0
            }
        */

    // sample url
    // http://smartmonster:8081/capability/text/generation/mistral/7b/chat
    /*
        let colorTags = imageData.tags.filter((tag) => tag.startsWith("color:"));        
        let materialsTags = imageData.tags.filter((tag) => tag.startsWith("materials:"));
        let topicTags = imageData.tags.filter((tag) => tag.startsWith("topic:"));
        //let categoryTags = imageData.tags.filter((tag) => tag.startsWith("category:"));
        //let heightTags = imageData.tags.filter((tag) => tag.startsWith("height:"));
        //let widthTags = imageData.tags.filter((tag) => tag.startsWith("width:"));
        let tags = imageData.tags.filter((tag) => tag.startsWith("tag:"));
        let locations = imageData.tags.filter((tag) => tag.startsWith("location:"));
        
        // TODO - Add tagsExtra to the prompt
        let promptPrefix = "Generate a " + imageData.category + " " + context +  " establishing context with the following ";
        
        // create delimited string of color tags after joining them, remove the 'color:' prefix
        let colorTagsString = colorTags.join(", ").r
        // create delimited string of materials tags after joining them, remove the 'materials:' prefix
        let materialsTagsString = materialsTags.join(", ").replace("materials:","");
        // create delimited string of topic tags after joining them, remove the 'topic:' prefix
        let topicTagsString = topicTags.join(", ").replace("topic:","");
        // create delimited string of tags after joining them, remove the 'tag:' prefix
        let tagsString = tags.join(", ").replace("tag:","");
        // create delimited string of locations after joining them, remove the 'location:' prefix
        let locationsString = locations.join(", ").replace("location:","");
            
        console.log("GenerateTextFromImageContext colorTagsString:", colorTagsString);
        console.log("GenerateTextFromImageContext materialsTagsString:", materialsTagsString);
        console.log("GenerateTextFromImageContext topicTagsString:", topicTagsString);
        console.log("GenerateTextFromImageContext tagsString:", tagsString);
        console.log("GenerateTextFromImageContext locationsString:", locationsString);

          let promptComplete = "[INST] " + promptPrefix + 
            (colorTags.length > 0) ? " colors:" + colorTags.join(", ").replace("color:","") : "" + 
            (materialsTags.length > 0) ? " and materials: " + materialsTags.join(", ") : "" + 
            (topicTags.length > 0) ? " and topics: " + topicTags.join(", ") : "" +       
            (tags.length > 0) ? " and tags: " + tags.join(", ") : "" + 
            (themes.length > 0) ? " and incorporate the following thematic elements: " + themes.split(", ") : "" +
            (locations.length > 0) ? " and locations: " + locations.join(", ") : "" +
            + " [/INST]";
        
*/
    // TODO - Add tagsExtra to the prompt
    let promptPrefix = "Generate a " + imageData.category + " " + context;

    let promptComplete = "[INST] " + promptPrefix + " [/INST]";

    // console.log("GenerateTextFromImageContext promptComplete:", promptComplete);

    let payload = {
      prompt: promptComplete,
      max_new_tokens: 1024,
      temperature: 0.3,
      top_k: 0,
      top_p: 0.9,
      do_sample: true,
      repetition_penalty: 1.0,
    };

    // console.log("GenerateTextFromImageContext payload:", payload);

    /*
        return fetch(`${apiURI}/capability/text/generation/mistral/7b/chat`, {
            method: 'POST',
            mode: "no-cors", // no-cors, *cors, same-origin
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload),
        }).then((response) => response.json());
*/

    /*
 



      
            // console.log("GenerateTextFromImageContext promptComplete:", promptComplete);
        // POST request using fetch with set headers        
        const requestOptions = {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            "Content-Type": "application/json",
          },
            body: JSON.stringify({ 
                prompt: promptComplete,
                max_new_tokens: 512,
                temperature: 0.3,
                top_k: 0,
                top_p: 0.9,
                do_sample: true,
                repetition_penalty: 1.0
            })
        };
        fetch(`${apiGenerateURI}/capability/text/generation/mistral/7b/chat`, requestOptions)
        .then(response => response.json());

        // console.log("GenerateTextFromImageContext requestOptions:", requestOptions);
*/
  };

  static GetImagesTest = (userId, page = 0, pageSize = 24, token) => {
    let jsonLatest = `[
    {
        "image_id": "ed65e6a7-3733-4e42-bc59-ef762d08cf6d",
        "user_id": "smartai_admin_generator",
        "is_favorited": false,
        "UserName": null,
        "Sku": null,
        "Seed": "18981",
        "Height": 1024,
        "Width": 1024,
        "public_url": "https://storage.googleapis.com/smartai-stockimage-dev-storage-platform-na/Culinary/20241214/f30043fb-4df2-4c86-960c-0754422345fe-0_20241213205650.png",
        "related_image_ids": [
            ""
        ],
        "generated_timestamp": "2024-12-14T21:32:23.768925Z",
        "Title": "Culinary, Blackberry Parfait, Sweet Healthy Treat",
        "Category": "Culinary",
        "Description": "Culinary vegan superfood, a delightful parfait, layered with creamy yogurt, crunchy granola, and juicy blackberries, is drizzled with a golden honey glaze. The parfait sits on a marble countertop, with a spoon resting beside it, ready to be enjoyed. This simple yet elegant dessert is a perfect combination of textures and flavors, making it a satisfying and healthy treat.",
        "public_url_thumbnail": "https://storage.googleapis.com/smartai-stockimage-dev-storage-thumbnail-na/Culinary/20241214/ed65e6a7-3733-4e42-bc59-ef762d08cf6d.png",
        "public_url_protected": "https://storage.googleapis.com/smartai-stockimage-dev-storage-protected-na/Culinary/20241214/ed65e6a7-3733-4e42-bc59-ef762d08cf6d.png",
        "public_url_reduced": "https://storage.googleapis.com/smartai-stockimage-dev-storage-platform-reduced-na/Culinary/20241214/ed65e6a7-3733-4e42-bc59-ef762d08cf6d.png",
        "num_steps": 40,
        "Tags": [
            "category:Culinary",
            "topic:Snacks",
            "topic:Healthy",
            "color:Honey Gold",
            "color:Snow White",
            "material:Glass",
            "topic:Marketing",
            "topic:Parfaits",
            "topic:Superfoods",
            "tag:honey",
            "tag:parfait",
            "tag:yogurt",
            "tag:Honey Glaze",
            "tag:granola",
            "tag:Blackberries",
            "color:Muesli ",
            "color:Jet Black",
            "color:Light Beige",
            "color:Ivory White",
            "color:Mint Cream ",
            "color:Rangoon Green ",
            "color:Harvest Gold ",
            "color:Apache ",
            "color:Copper Canyon ",
            "color:Chelsea Gem ",
            "resolution:1024x1024"
        ],
        "prompted_by": "smartai_prompt_service",
        "generated_by_model": "flux schnell",
        "generated_by": "smartai_admin_generator",
        "is_enabled": true,
        "is_published": true
    },
    {
        "image_id": "1090d2de-efc4-48ed-9b6f-897ded88e956",
        "user_id": "smartai_admin_generator",
        "is_favorited": false,
        "UserName": null,
        "Sku": null,
        "Seed": "29131",
        "Height": 1024,
        "Width": 1024,
        "public_url": "https://storage.googleapis.com/smartai-stockimage-dev-storage-platform-na/Culinary/20241214/2c962085-c4fc-4fa8-90a6-63a9b6caa3b5-0_20241213205533.png",
        "related_image_ids": [
            ""
        ],
        "generated_timestamp": "2024-12-14T21:32:18.803496Z",
        "Title": "Culinary, Blackberry Parfait, Sweet Healthy Treat",
        "Category": "Culinary",
        "Description": "Culinary vegan superfood, a delightful parfait, layered with creamy yogurt, crunchy granola, and juicy blackberries, is drizzled with a golden honey glaze. The parfait sits on a marble countertop, with a spoon resting beside it, ready to be enjoyed. This simple yet elegant dessert is a perfect combination of textures and flavors, making it a satisfying and healthy treat.",
        "public_url_thumbnail": "https://storage.googleapis.com/smartai-stockimage-dev-storage-thumbnail-na/Culinary/20241214/1090d2de-efc4-48ed-9b6f-897ded88e956.png",
        "public_url_protected": "https://storage.googleapis.com/smartai-stockimage-dev-storage-protected-na/Culinary/20241214/1090d2de-efc4-48ed-9b6f-897ded88e956.png",
        "public_url_reduced": "https://storage.googleapis.com/smartai-stockimage-dev-storage-platform-reduced-na/Culinary/20241214/1090d2de-efc4-48ed-9b6f-897ded88e956.png",
        "num_steps": 40,
        "Tags": [
            "category:Culinary",
            "topic:Snacks",
            "topic:Healthy",
            "color:Honey Gold",
            "color:Snow White",
            "material:Glass",
            "topic:Marketing",
            "topic:Parfaits",
            "topic:Superfoods",
            "tag:honey",
            "tag:parfait",
            "tag:yogurt",
            "tag:Honey Glaze",
            "tag:granola",
            "tag:Blackberries",
            "color:Muesli ",
            "color:Jet Black",
            "color:Light Beige",
            "color:Ivory White",
            "color:Mint Cream ",
            "color:Rangoon Green ",
            "color:Harvest Gold ",
            "color:Apache ",
            "color:Copper Canyon ",
            "color:Chelsea Gem ",
            "resolution:1024x1024"
        ],
        "prompted_by": "smartai_prompt_service",
        "generated_by_model": "flux schnell",
        "generated_by": "smartai_admin_generator",
        "is_enabled": true,
        "is_published": true
    },
    {
        "image_id": "78250911-fc07-4000-afbb-c67739941bed",
        "user_id": "smartai_admin_generator",
        "is_favorited": false,
        "UserName": null,
        "Sku": null,
        "Seed": "3496",
        "Height": 1024,
        "Width": 1024,
        "public_url": "https://storage.googleapis.com/smartai-stockimage-dev-storage-platform-na/Anime/20241214/90aa78c5-caa8-4e1d-adc8-3b44690da174-0_20241213212824.png",
        "related_image_ids": [
            ""
        ],
        "generated_timestamp": "2024-12-14T21:32:13.671956Z",
        "Title": "Anime Characters, Smiling Bodega Employee",
        "Category": "Anime",
        "Description": "Anime character design, a chibi bodega employee with a bright smile and large, expressive eyes stands on a platform in a brightly lit convenience store bodega. The character is dressed in a red hoodie, blue jeans, and sneakers, friendly demeanor and the vibrant colors of the store create a cheerful and inviting atmosphere, background is a blur of shelves stocked with colorful goods",
        "public_url_thumbnail": "https://storage.googleapis.com/smartai-stockimage-dev-storage-thumbnail-na/Anime/20241214/78250911-fc07-4000-afbb-c67739941bed.png",
        "public_url_protected": "https://storage.googleapis.com/smartai-stockimage-dev-storage-protected-na/Anime/20241214/78250911-fc07-4000-afbb-c67739941bed.png",
        "public_url_reduced": "https://storage.googleapis.com/smartai-stockimage-dev-storage-platform-reduced-na/Anime/20241214/78250911-fc07-4000-afbb-c67739941bed.png",
        "num_steps": 40,
        "Tags": [
            "category:Anime",
            "topic:Character Design",
            "topic:Illustrations",
            "color:Tangerine",
            "color:Sky Blue",
            "material:wool",
            "topic:Chibi",
            "topic:Urban",
            "tag:character",
            "tag:bodega",
            "tag:smile",
            "tag:chibi",
            "tag:friendly",
            "color:Vivid Auburn ",
            "color:Cocoa ",
            "color:Brown Sugar ",
            "color:Mineral Green ",
            "color:Casal ",
            "color:Acadia Black",
            "color:Forest Green",
            "color:Light Mauve",
            "color:Sandstone",
            "color:Rouge pink",
            "resolution:1024x1024"
        ],
        "prompted_by": "smartai_prompt_service",
        "generated_by_model": "flux schnell",
        "generated_by": "smartai_admin_generator",
        "is_enabled": true,
        "is_published": true
    },
    {
        "image_id": "fabc819f-f566-4bd1-afc8-ece95f63ce9d",
        "user_id": "smartai_admin_generator",
        "is_favorited": false,
        "UserName": null,
        "Sku": null,
        "Seed": "31881",
        "Height": 1024,
        "Width": 1024,
        "public_url": "https://storage.googleapis.com/smartai-stockimage-dev-storage-platform-na/Culinary/20241214/b777c576-d236-469d-9cdf-a2d25f883c6b-0_20241213210631.png",
        "related_image_ids": [
            ""
        ],
        "generated_timestamp": "2024-12-14T21:32:08.954028Z",
        "Title": "Culinary, Iced Tea, Refreshing Summer Drink",
        "Category": "Culinary",
        "Description": "Culinary marketing, drinks, a tall glass of iced tea, filled with ice and bubbles, sits on a table in the warm summer sun. The drink is a refreshing and thirst-quenching treat, perfect for a hot day. The ice cubes are melting, releasing tiny bubbles that rise to the surface, creating a delightful visual effect.",
        "public_url_thumbnail": "https://storage.googleapis.com/smartai-stockimage-dev-storage-thumbnail-na/Culinary/20241214/fabc819f-f566-4bd1-afc8-ece95f63ce9d.png",
        "public_url_protected": "https://storage.googleapis.com/smartai-stockimage-dev-storage-protected-na/Culinary/20241214/fabc819f-f566-4bd1-afc8-ece95f63ce9d.png",
        "public_url_reduced": "https://storage.googleapis.com/smartai-stockimage-dev-storage-platform-reduced-na/Culinary/20241214/fabc819f-f566-4bd1-afc8-ece95f63ce9d.png",
        "num_steps": 40,
        "Tags": [
            "category:Culinary",
            "topic:Citrus",
            "topic:Drinks",
            "color:Clear",
            "color:Dark Green",
            "topic:Marketing",
            "topic:Iced",
            "tag:Glass",
            "tag:bubbles",
            "tag:ice",
            "tag:iced tea",
            "tag:refreshing",
            "tag:summer drink",
            "tag:Ice",
            "color:Light Green",
            "color:Peach",
            "color:Golden Yellow",
            "color:Carrot Orange ",
            "color:Spanish Green ",
            "color:Flame ",
            "color:Trendy Green ",
            "color:Tahiti Gold ",
            "color:Sea Mist ",
            "color:Dark Orange ",
            "resolution:1024x1024"
        ],
        "prompted_by": "smartai_prompt_service",
        "generated_by_model": "flux schnell",
        "generated_by": "smartai_admin_generator",
        "is_enabled": true,
        "is_published": true
    },
    {
        "image_id": "54f43812-1010-47b6-a856-6991672bb249",
        "user_id": "smartai_admin_generator",
        "is_favorited": false,
        "UserName": null,
        "Sku": null,
        "Seed": "8884",
        "Height": 1024,
        "Width": 1024,
        "public_url": "https://storage.googleapis.com/smartai-stockimage-dev-storage-platform-na/Anime/20241214/ef671c13-591c-4ef0-a1f7-7b48d9d5f4d3-0_20241213213606.png",
        "related_image_ids": [
            ""
        ],
        "generated_timestamp": "2024-12-14T21:32:04.408253Z",
        "Title": "Anime, Cyberpunk Cityscape, A Night Out",
        "Category": "Anime",
        "Description": "A young woman with striking blue eyes and long black braids stands confidently in a cyberpunk cityscape, her sleek black outfit and futuristic headphones hinting at a life of adventure and intrigue. The neon lights of the city reflect in her eyes, suggesting a world of possibilities and danger. The woman&#39;s determined expression and the intricate details of her attire speak to a story of resilience and strength in a world where technology and humanity collide.",
        "public_url_thumbnail": "https://storage.googleapis.com/smartai-stockimage-dev-storage-thumbnail-na/Anime/20241214/54f43812-1010-47b6-a856-6991672bb249.png",
        "public_url_protected": "https://storage.googleapis.com/smartai-stockimage-dev-storage-protected-na/Anime/20241214/54f43812-1010-47b6-a856-6991672bb249.png",
        "public_url_reduced": "https://storage.googleapis.com/smartai-stockimage-dev-storage-platform-reduced-na/Anime/20241214/54f43812-1010-47b6-a856-6991672bb249.png",
        "num_steps": 40,
        "Tags": [
            "category:Anime",
            "topic:Characters",
            "topic:Futuristic",
            "color:Rosy Finch",
            "color:Pale Peach",
            "location:Los Angeles",
            "material:Leather",
            "topic:Cyberpunk",
            "topic:Fashion",
            "tag:Cities",
            "tag:cyberpunk city",
            "tag:anime girl",
            "tag:black outfit",
            "tag:urban fantasy",
            "tag:powerful hybrid",
            "tag:modified human",
            "tag:goth",
            "tag:cyberpunk fashion",
            "color:Jet Black",
            "color:Golden Yellow",
            "color:Electric Blue",
            "color:Deep Indigo",
            "color:Crimson Red",
            "color:Cerulean Blue",
            "color:Blue Koi ",
            "resolution:1024x1024"
        ],
        "prompted_by": "smartai_prompt_service",
        "generated_by_model": "flux schnell",
        "generated_by": "smartai_admin_generator",
        "is_enabled": true,
        "is_published": true
    },
    {
        "image_id": "25093f0b-ffe6-4d7e-8d9a-42c933379671",
        "user_id": "smartai_admin_generator",
        "is_favorited": false,
        "UserName": null,
        "Sku": null,
        "Seed": "59872",
        "Height": 1536,
        "Width": 1536,
        "public_url": "https://storage.googleapis.com/smartai-stockimage-dev-storage-platform-na/Interior Design/20241214/25093f0b-ffe6-4d7e-8d9a-42c933379671_00.png",
        "related_image_ids": [
            ""
        ],
        "generated_timestamp": "2024-12-14T21:31:59.661129Z",
        "Title": "Kitchen Appliances, Gleaming Silver Toaster",
        "Category": "Interior Design",
        "Description": "Appliance profile, Kitchen, a gleaming silver toaster sits on a white marble countertop, ready to bring warmth and a satisfying crunch to your morning toast. Its sleek lines and polished finish suggest a modern design, while the black accents add a touch of sophistication.  The toaster seems to be waiting patiently for its next task, eager to transform slices of bread into golden-brown perfection.",
        "public_url_thumbnail": "https://storage.googleapis.com/smartai-stockimage-dev-storage-thumbnail-na/Interior Design/20241214/25093f0b-ffe6-4d7e-8d9a-42c933379671.png",
        "public_url_protected": "https://storage.googleapis.com/smartai-stockimage-dev-storage-protected-na/Interior Design/20241214/25093f0b-ffe6-4d7e-8d9a-42c933379671.png",
        "public_url_reduced": "https://storage.googleapis.com/smartai-stockimage-dev-storage-platform-reduced-na/Interior Design/20241214/25093f0b-ffe6-4d7e-8d9a-42c933379671.png",
        "num_steps": 40,
        "Tags": [
            "category:Interior Design",
            "topic:Kitchen",
            "topic:Toasters",
            "color:White",
            "color:Silver",
            "location:Kitchen",
            "material:Stainless Steel",
            "topic:Appliances",
            "topic:Home",
            "tag:toast",
            "tag:Countertops",
            "tag:toaster",
            "tag:modern design",
            "tag:kitchen appliance",
            "tag:breakfast",
            "tag:bread",
            "tag:household",
            "tag:items",
            "color:Silver Chalice ",
            "color:Gray",
            "color:Cream",
            "color:Black",
            "color:Beige",
            "color:Carbon Gray ",
            "color:Green White ",
            "resolution:1536x1536"
        ],
        "prompted_by": "smartai_prompt_service",
        "generated_by_model": "luma-photon-flash-1",
        "generated_by": "smartai_admin_generator",
        "is_enabled": true,
        "is_published": true
    },
    {
        "image_id": "ef60d9e7-6b1b-40df-985c-ee1203d62e88",
        "user_id": "smartai_admin_generator",
        "is_favorited": false,
        "UserName": null,
        "Sku": null,
        "Seed": "31144",
        "Height": 1024,
        "Width": 1024,
        "public_url": "https://storage.googleapis.com/smartai-stockimage-dev-storage-platform-na/Anime/20241214/214061ab-3179-4896-9513-b1cb9c9aedb5-0_20241213212756.png",
        "related_image_ids": [
            ""
        ],
        "generated_timestamp": "2024-12-14T21:31:54.215384Z",
        "Title": "Anime Characters, Smiling Bodega Employee",
        "Category": "Anime",
        "Description": "Anime character design, a chibi bodega employee with a bright smile and large, expressive eyes stands on a platform in a brightly lit convenience store bodega. The character is dressed in a red hoodie, blue jeans, and sneakers, friendly demeanor and the vibrant colors of the store create a cheerful and inviting atmosphere, background is a blur of shelves stocked with colorful goods",
        "public_url_thumbnail": "https://storage.googleapis.com/smartai-stockimage-dev-storage-thumbnail-na/Anime/20241214/ef60d9e7-6b1b-40df-985c-ee1203d62e88.png",
        "public_url_protected": "https://storage.googleapis.com/smartai-stockimage-dev-storage-protected-na/Anime/20241214/ef60d9e7-6b1b-40df-985c-ee1203d62e88.png",
        "public_url_reduced": "https://storage.googleapis.com/smartai-stockimage-dev-storage-platform-reduced-na/Anime/20241214/ef60d9e7-6b1b-40df-985c-ee1203d62e88.png",
        "num_steps": 40,
        "Tags": [
            "category:Anime",
            "topic:Character Design",
            "topic:Illustrations",
            "color:Tangerine",
            "color:Sky Blue",
            "material:wool",
            "topic:Chibi",
            "topic:Urban",
            "tag:character",
            "tag:bodega",
            "tag:smile",
            "tag:chibi",
            "tag:friendly",
            "color:Vivid Auburn ",
            "color:Cocoa ",
            "color:Brown Sugar ",
            "color:Mineral Green ",
            "color:Casal ",
            "color:Acadia Black",
            "color:Forest Green",
            "color:Light Mauve",
            "color:Sandstone",
            "color:Rouge pink",
            "resolution:1024x1024"
        ],
        "prompted_by": "smartai_prompt_service",
        "generated_by_model": "flux schnell",
        "generated_by": "smartai_admin_generator",
        "is_enabled": true,
        "is_published": true
    }
]`;

    // now return as an array of objects
    let response = JSON.parse(jsonLatest);
    console.log("GetImages response:", response);
    return response;
  };
  /*
    static GetImagePrice = async (width, height) => {
      return fetch(`${apiURI}/price?width=${width}&height=${height}`)
        .then((response) => response.json());
    }
  
    static GetImagePricesheet = async (color = '',page = 0, pageSize = 24) => {
      return fetch(`${apiURI}/prices`)
        .then((response) => response.json());
    }
    */
}

export default ImageService;
