import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faUpload } from "@fortawesome/free-solid-svg-icons";
import { GoogleAuthProvider } from "firebase/auth";

// Services
import ImageService from "../../services/image.service";
import ListService from "../../services/list.service";
import ProductService from "../../services/product.service";
import UserService from "../../services/user.service";
import { useAuth, EnsureToken } from "../../services/auth/use-auth";
import useGoogleOneTap from "../../hooks/useGoogleOneTap";

// Components
import Searchbar from "../../components/searchbar/searchbar";

// Styles
import "./uploadDetail.css";
import s from "./uploadDetail.module.scss";

const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3MB in bytes
const DEFAULT_PAGE_SIZE = 24;

const UploadDetail = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // State Management
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [products, setProducts] = useState([]);
  const [credits, setCredits] = useState({ numSubscriptionCreditsRemaining: 0, numWalletCreditsRemaining: 0 });
  
  // UI States
  const [loading, setLoading] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);
  const [isDescribing, setIsDescribing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMobileResolution, setIsMobileResolution] = useState(false);

  // File Upload States
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [imageDetails, setImageDetails] = useState({
    width: 0,
    height: 0,
    resolution: ""
  });

  // Fetch initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      if (!auth.user) return;

      try {
        const initialCategory = searchParams.get("q") || "All";
        const [categoriesResponse, productsResponse] = await Promise.all([
          ListService.GetCategories(),
          ProductService.GetProductList(
            auth.user.uid,
            auth.user.auth.currentUser.accessToken
          )
        ]);

        setSelectedCategory(initialCategory);
        setCategories(["All", "Uploads", ...categoriesResponse.sort()]);
        setProducts(productsResponse);
        await fetchUserCredits();
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchInitialData();
  }, [auth.user, searchParams]);

  // Handle screen resolution
  useEffect(() => {
    const handleResize = () => {
      setIsMobileResolution(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch user credits
  const fetchUserCredits = async () => {
    if (!auth.user) return;
    
    try {
      const response = await UserService.GetUserCredits(
        auth.user.uid,
        await EnsureToken(auth.user.auth.currentUser)
      );
      setCredits(response.creditProfile);
    } catch (error) {
      console.error("Error fetching user credits:", error);
    }
  };

  // File handling functions
  const handleImageUpload = async (event) => {
    const file = event.target?.files?.[0] || event.dataTransfer?.files?.[0];
    if (!file) {
      console.error("No file selected");
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      alert("File is too large. Maximum size is 3MB");
      return;
    }

    try {
      setLoading(true);
      const previewUrl = URL.createObjectURL(file);
      setPreviewImage(previewUrl);
      setSelectedFile(file);

      // Get image dimensions
      const img = new Image();
      img.src = previewUrl;
      await new Promise((resolve) => {
        img.onload = () => {
          setImageDetails({
            width: img.width,
            height: img.height,
            resolution: `${img.width} x ${img.height}`
          });
          resolve();
        };
      });
    } catch (error) {
      console.error("Error processing file:", error);
      alert("Error processing file. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDescribeImage = async () => {
    if (!selectedFile || isDescribing) return;

    try {
      setIsDescribing(true);
      const base64String = await fileToBase64(selectedFile);
      
      const response = await ImageService.DescribeImage(
        auth.user.uid,
        base64String,
        base64String,
        auth.user.auth.currentUser.accessToken
      );

      if (!response || response.error || response.message?.includes("ERROR:") || !response.title) {
        throw new Error("Failed to describe image");
      }

      const cleanedResponse = cleanMetadata({
        ...response,
        width: imageDetails.width,
        height: imageDetails.height,
        resolution: imageDetails.resolution
      });

      setMeta(cleanedResponse);
    } catch (error) {
      console.error("Error describing image:", error);
      alert("Failed to describe image. Please try again or try different image");
    } finally {
      setIsDescribing(false);
    }
  };

  const submitImageUpload = async () => {
    if (!selectedFile || !meta || isSubmitting) return;

    try {
      setIsSubmitting(true);
      const base64String = await fileToBase64(selectedFile);
      
      const response = await ImageService.UploadImage(
        auth.user.uid,
        base64String,
        null,
        meta,
        auth.user.auth.currentUser.accessToken
      );

      if (response.success) {
        navigate("/profile");
      } else {
        throw new Error(response.message || "Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      alert(`Failed to upload image: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Utility functions
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const bytes = new Uint8Array(e.target.result);
          const base64String = btoa(
            Array.from(bytes).map(byte => String.fromCharCode(byte)).join('')
          );
          resolve(base64String);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  const cleanMetadata = (metadata) => {
    const cleanString = (str) => str?.replace(/'/g, "") || "";
    const cleanArray = (arr) => arr?.map(cleanString) || [];

    return {
      ...metadata,
      title: cleanString(metadata.title),
      description: cleanString(metadata.description),
      revisedPrompt: cleanString(metadata.revisedPrompt),
      category: cleanString(metadata.category),
      topics: cleanArray(metadata.topics),
      tags: cleanArray(metadata.tags),
      colors: cleanArray(metadata.colors),
      materials: cleanArray(metadata.materials),
      styles: cleanArray(metadata.styles)
    };
  };

  // UI Components
  const HeaderBanner = () => (
    <Box className="bg-hero px-4 py-5 text-center">
      <Typography
        variant="h3"
        color="lightyellow"
        sx={{
          fontFamily: "Raleway",
          fontWeight: "700"
        }}
      >
        Original AI-Generated stock images
      </Typography>
      {!isMobileResolution && (
        <Box>
          <Typography
            color="lightyellow"
            sx={{
              fontFamily: "Raleway",
              fontSize: 20,
              paddingTop: "12px",
              fontWeight: "400"
            }}
          >
            Beautifully prompted, expertly curated, royalty-free images for
            all your digital projects
          </Typography>
          <Typography
            color="lightyellow"
            sx={{
              fontFamily: "Raleway",
              fontStyle: "italic",
              fontSize: 16,
              fontWeight: "400"
            }}
          >
            smartai.studio - create. enhance. inspire.
          </Typography>
        </Box>
      )}
      <Searchbar />
    </Box>
  );

  const NotAuthenticatedBanner = () => (
    <Box
      className="bg-banner px-4 text-center"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "400px",
        width: "100%"
      }}
    >
      <div className="d-flex align-items-center justify-content-center mx-auto py-2">
        <p className="mb-0 me-3 register-text">
          Register to access AI Studio features:
        </p>
        <div className="button" onClick={() => navigate("/free-signup")}>
          Join For Free
        </div>
      </div>
    </Box>
  );

  const ImageMetadataCard = ({ metadata }) => (
    <Card
      sx={{
        boxShadow: "none",
        borderRadius: "10px",
        border: "1px solid #e0e0e0",
        width: "100%",
        maxWidth: "600px"
      }}
    >
      <CardHeader
        sx={{ borderRadius: "10px 10px 0 0" }}
        title={
          <Box
            sx={{
              padding: "1px",
              display: "flow-root",
              justifyContent: "space-between",
              flexFlow: "row wrap"
            }}
          >
            <Chip
              sx={{
                margin: 0.4,
                borderRadius: "5px",
                padding: "4px"
              }}
              color="secondary"
              label={<Typography>{metadata.category}</Typography>}
            />
            <LoadingButton
              sx={{ margin: 0.2, borderRadius: "5px" }}
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(metadata, null, 2));
              }}
            >
              <FontAwesomeIcon size="lg" color="#666" icon={faCopy} />
              &nbsp;Copy Metadata
            </LoadingButton>
            <LoadingButton
              loadingIndicator="Submitting..."
              loading={isSubmitting}
              variant="contained"
              color="primary"
              disabled={!selectedFile}
              sx={{ margin: 0.2, borderRadius: "5px" }}
              onClick={submitImageUpload}
            >
              <FontAwesomeIcon size="lg" color="#666" icon={faUpload} />
              &nbsp;Upload To Gallery
            </LoadingButton>
          </Box>
        }
      />
      <CardMedia
        className="img-responsive img-thumbnail"
        sx={{ padding: 0 }}
        component="img"
        image={previewImage}
        alt={metadata.description}
      />
      <CardContent sx={{ borderRadius: "0 0 10px 10px", padding: "10px", width: "100%" }}>
        <MetadataSection metadata={metadata} />
        <TagsSection metadata={metadata} />
      </CardContent>
    </Card>
  );

  const MetadataSection = ({ metadata }) => (
    <Box sx={{ padding: "10px" }}>
      <Typography
        variant="h6"
        sx={{
          fontFamily: "Raleway",
          fontWeight: "700",
          fontSize: "1.5rem",
          padding: "10px"
        }}
      >
        <strong>Title: </strong>
        {metadata.title}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontFamily: "Raleway",
          fontSize: "1rem",
          fontWeight: "400",
          marginBottom: "10px"
        }}
      >
        <strong>Description: </strong>
        {metadata.description}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontFamily: "Raleway",
          fontSize: "1rem",
          fontWeight: "400",
          marginBottom: "10px"
        }}
      >
        <strong>Prompt: </strong>
        {metadata.revisedPrompt}
      </Typography>
    </Box>
  );

  const TagsSection = ({ metadata }) => {
    const renderTagGroup = (tags, title) => (
      tags?.length > 0 && (
        <Box className="mt-3">
          <Typography variant="subtitle1" className="fw-bold mb-2">
            {title}:
          </Typography>
          {tags.map((tag, index) => (
            <Chip
              key={`${title}-${index}`}
              color="secondary"
              sx={{ margin: 0.2 }}
              label={
                <Typography
                  sx={{
                    margin: 1,
                    fontFamily: "Raleway",
                    fontSize: 12
                  }}
                >
                  {tag}
                </Typography>
              }
            />
          ))}
        </Box>
      )
    );

    return (
      <>
        {renderTagGroup(metadata.topics, "Topics")}
        {renderTagGroup(metadata.tags, "Tags")}
        {renderTagGroup(metadata.colors, "Colors")}
        {renderTagGroup(metadata.materials, "Materials")}
        {renderTagGroup(metadata.styles, "Styles")}
      </>
    );
  };

  const CreditsInfo = () => (
    <Box
      className="image__checkout"
      sx={{
        backgroundColor: "#f5f5f5",
        width: "100%",
        padding: "10px",
        borderRadius: "5px",
        marginTop: "10px"
      }}
    >
      <Typography variant="body1" className="fw-bold">
        <i className="fa-solid fa-coins" />
        <span className="ms-2">
          Monthly Credits Balance: {credits.numSubscriptionCreditsRemaining}
        </span>
      </Typography>
      <Typography variant="body1" className="fw-bold">
        <span className="ms-2">
          Wallet Credits: {credits.numWalletCreditsRemaining}
        </span>
      </Typography>
    </Box>
  );

  const UploadArea = () => {
    const enoughCredits = credits.numSubscriptionCreditsRemaining + credits.numWalletCreditsRemaining > 0;

    return (
      <Box
        id="image-upload-area"
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault();
          handleImageUpload(e);
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          minWidth: "400px",
          minHeight: "400px",
          width: "100%",
          border: "1px dashed #ccc",
          borderRadius: "10px",
          backgroundColor: "#f0f0f0"
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Raleway",
            fontWeight: "700",
            textAlign: "center",
            marginBottom: "10px"
          }}
        >
          Upload your image
        </Typography>
        
        <Typography
          variant="body1"
          sx={{
            fontFamily: "Raleway",
            textAlign: "center",
            marginBottom: "25px"
          }}
        >
          Drag & Drop an image (or Choose File) to get started.
        </Typography>

        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ margin: "10px" }}
        />

        {selectedFile && !meta && (
          <Box
            className={s.container__box}
            sx={{
              borderRadius: "6px",
              border: "1px solid #8d8d8d",
              backgroundColor: "#EAEAEA",
              margin: "auto",
              padding: "10px",
              width: "100%",
              maxWidth: "600px"
            }}
          >
            <div
              className="image-preview"
              style={{
                position: "relative",
                minHeight: "200px",
                border: "2px solid #8BA0A4",
                borderRadius: "4px",
                overflow: "hidden"
              }}
            >
              <img
                src={previewImage}
                alt="Preview"
                style={{
                  width: "100%",
                  height: "auto"
                }}
              />
            </div>

            <LoadingButton
              loadingIndicator="Describing..."
              loading={isDescribing}
              variant="contained"
              color="primary"
              disabled={!selectedFile || isDescribing || !enoughCredits}
              onClick={handleDescribeImage}
              sx={{
                borderRadius: "12px",
                padding: "5px",
                margin: "5px",
                width: "300px"
              }}
            >
              {enoughCredits 
                ? "✨Describe Image Agent (1)"
                : "✨Describe Image (Not Enough Credits)"}
            </LoadingButton>

            <Typography
              variant="body2"
              sx={{
                fontFamily: "Raleway",
                fontSize: 12,
                fontStyle: "italic",
                textAlign: "center",
                marginTop: "10px"
              }}
            >
              NOTE: Do not submit images larger than 3MB or with a resolution
              greater than 1280x1280 pixels resolution.
              <br />
              Please ensure you have the rights to submit the image, and that it does not contain any offensive or inappropriate content.
              Please review our{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://app.termly.io/document/terms-of-use-for-website/376fff7a-0bd7-4e6b-a466-732926a0cbce"
              >
                terms of service
              </a>{" "}
              for more information.
            </Typography>

            <CreditsInfo />
          </Box>
        )}

        {meta && <ImageMetadataCard metadata={meta} />}
      </Box>
    );
  };

  // Google One Tap Integration
  const handleGoogleOneTapResponse = async (response) => {
    setAuthLoading(true);
    try {
      const credential = GoogleAuthProvider.credential(response.credential);
      const result = await auth.signInWithCredentialFlow(credential);
      const userDetails = auth.getAdditionalUserInfoFlow(result);
      const currentUser = result.user;

      if (userDetails.isNewUser) {
        const newUser = {
          userId: currentUser.uid,
          email: currentUser.email,
          password: null,
          displayName: currentUser.displayName,
          photoURL: currentUser.photoURL,
        };
        const createNewUserRes = await UserService.CreateNewUserAccount(newUser);
        
        if (createNewUserRes?.userId) {
          navigate("/");
        } else {
          throw new Error("Failed to create new user");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Google One Tap Sign In Error:", error);
      alert("Failed to sign in with Google. Please try another method.");
    } finally {
      setAuthLoading(false);
    }
  };

  useGoogleOneTap({
    onDisplay: (isShowing) => setAuthLoading(isShowing),
    showPrompt: true,
    disabled: !!auth.user,
    disableCancelOnUnmount: false,
    googleConfig: {
      auto_select: !!auth.user,
      cancel_on_tap_outside: false,
      callback: handleGoogleOneTapResponse,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    },
  });

  return (
    <div className="home">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || isDescribing || isSubmitting || authLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      
      <HeaderBanner />
      {!auth.user && <NotAuthenticatedBanner />}
      
      <Box sx={{ padding: "20px" }}>
        <UploadArea />
      </Box>
    </div>
  );
}

export default UploadDetail;